/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AnnouncementDto { 
    created?: number;
    updated?: number;
    customerId?: string;
    distributorId?: string;
    createdBy?: string;
    modifiedBy?: string;
    id?: number;
    title: string;
    content: string;
    subtitle?: string;
    type: AnnouncementDto.TypeEnum;
    active: boolean;
    pushDesired: boolean;
    allDevices: boolean;
    validFrom: number;
    validUntil: number;
    announcementPortGroupsIds?: Array<number>;
    announcementDeviceGroupsIds?: Array<number>;
}
export namespace AnnouncementDto {
    export type TypeEnum = 'WARNING' | 'INFO';
    export const TypeEnum = {
        Warning: 'WARNING' as TypeEnum,
        Info: 'INFO' as TypeEnum
    };
}


