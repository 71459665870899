import { Action } from '@ngrx/store';
import { HeaderEvent } from '../models/header-event';

export enum HeaderActionTypes {
  EmitHeaderEvent = '[Header] Emit Header Event',
  ReceivedHeaderEvent = '[Header] Received Header Event',
}

export class EmitHeaderEvent implements Action {
  readonly type = HeaderActionTypes.EmitHeaderEvent;

  constructor(public payload: HeaderEvent) {}
}

export class ReceivedHeaderEvent implements Action {
  readonly type = HeaderActionTypes.ReceivedHeaderEvent;

  constructor() {}
}

export type HeaderActionsUnion = EmitHeaderEvent | ReceivedHeaderEvent;
