<div class="bew-color-line" #colorLine>
  <div class="row">
    <span class="col bewcol-5"></span>
  </div>
</div>
<section class="mat-typography">
  <div class="background-img-wrapper">
    <img *ngIf="background" [src]="background" />
  </div>
  <bwa-header-component #header>
    <h1 class="heading" heading>
      {{ "HEADER.HEADING" | translate }}
    </h1>
    <h1 class="title" *ngIf="system">
      {{ system }}
    </h1>
  </bwa-header-component>
  <bewa-main-navigation [top]="headerHeight"></bewa-main-navigation>
</section>
<bewa-notification></bewa-notification>
<tour-step-template></tour-step-template>
