import { Action } from '@ngrx/store';

export enum MenuActionTypes {
  UpdateMenu = '[ Menu ] Update background',
}

export class UpdateMenu implements Action {
  readonly type = MenuActionTypes.UpdateMenu;

  constructor(public payload?: Date) {}
}

export type MenuActionUnion = UpdateMenu;
