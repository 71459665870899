import { Action } from '@ngrx/store';

export enum RolesActionTypes {
  UpdateUserRoles = '[Auth] Update User Roles',
}

export class UpdateUserRoles implements Action {
  readonly type = RolesActionTypes.UpdateUserRoles;

  constructor(public payload: string[] | null) {}
}

export type RolesActionsUnion = UpdateUserRoles;
