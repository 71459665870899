import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Color, ColorHelper, ScaleType } from '@swimlane/ngx-charts';
import {
  IChartData,
  IPieChartCard,
} from '../../../interfaces/card-layout.interface';

@Component({
  selector: 'bewa-pie-chart-card',
  templateUrl: './pie-chart-card.component.html',
  styleUrls: ['./pie-chart-card.component.scss'],
})
@Subscriptions()
export class PieChartCardComponent implements OnInit, AfterViewInit {
  _originalPieData: { name: string; value: number }[] = [];
  _pieData: { name: string; value: number }[] = [];
  _deactivatedData: { name: string; value: number }[] = [];
  pieColor: { name: string; value: string }[] = [];

  public _labelNames: string[] = [];
  public colors: ColorHelper;
  public colorScheme: Color = {
    domain: [],
    group: ScaleType.Ordinal,
    name: '',
    selectable: false,
  }; // Custom color scheme in hex

  @Input()
  card: IPieChartCard;
  @Unsubscribe()
  private cardUpdateSubscription: any;

  constructor() {}

  containerWidth: number = 0;
  containerHeight: number = 0;

  @ViewChild('containerRef', { static: false }) containerRef: ElementRef;

  ngOnInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: IChartData) => {
        if (data.chartData) {
          this.card.data.chartData = data.chartData;
        }
        if (data.chartTitle) {
          this.card.data.chartTitle = data.chartTitle;
        }
        if (data.chartLabels) {
          this.card.data.chartLabels = data.chartLabels;
        }
        if (data.chartBackgroundColor) {
          this.card.data.chartBackgroundColor = data.chartBackgroundColor;
        }

        if (this.card.data.chartData && this.card.data.chartLabels) {
          const dataLen = this.card.data.chartData.length;
          const pieData = [];
          this.pieColor = [];
          this.colorScheme = {
            domain: [],
            group: ScaleType.Ordinal,
            name: '',
            selectable: false,
          };
          const newColors = this.getColors();
          for (let i = 0; i < dataLen; i++) {
            pieData.push({
              name: this.card.data.chartLabels[i],
              value: this.card.data.chartData[i],
            });
            this.pieColor.push({
              name: this.card.data.chartLabels[i],
              value: newColors[i],
            });
            this.colorScheme.domain.push(newColors[i]);
          }
          this._originalPieData = pieData;
          this.pieData = pieData;

          // Get chartNames
          this.chartLabels = this.pieData.map((d: any) => d.name);
          // Convert hex colors to ColorHelper for consumption by legend
          this.colors = new ColorHelper(
            this.colorScheme,
            ScaleType.Ordinal,
            this.chartLabels,
            this.colorScheme,
          );
        }
      },
    );
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.containerWidth = this.containerRef.nativeElement.offsetWidth / 2;
      this.containerHeight = this.containerRef.nativeElement.offsetHeight / 2;
    });
  }

  elementIsDeactivated(element: any): boolean {
    const foundElement = this.deactivatedData.find(
      (deactivatedEl) => deactivatedEl.name === element.name,
    );
    return !!foundElement;
  }

  onLabelClick(event: any) {
    if (this.deactivatedData.length > 0) {
      const elementToReactivate = this.deactivatedData.find((obj: any) => {
        return obj.name === event;
      });
      if (elementToReactivate) {
        this.deactivatedData.splice(
          this.deactivatedData.indexOf(elementToReactivate),
          1,
        );
      } else {
        this.deactivatedData.push(
          this._originalPieData.find((entry) => entry.name === event),
        );
      }
    } else {
      this.deactivatedData.push(
        this._originalPieData.find((entry) => entry.name === event),
      );
    }
    this.pieData = this._originalPieData.filter(
      (entry) => !this.elementIsDeactivated(entry),
    );
  }

  get chartLabels(): string[] {
    return this._labelNames;
  }

  set chartLabels(labels: string[]) {
    this._labelNames = labels;
  }

  get pieData(): any[] {
    return this._pieData;
  }

  set pieData(data: any[]) {
    this._pieData = data;
  }

  get deactivatedData(): any[] {
    return this._deactivatedData;
  }

  set deactivatedData(labels: any[]) {
    this._deactivatedData = labels;
  }

  getColors(): string[] {
    let newColors = [];
    if (
      this.card.data.calcMissingColors &&
      !(
        this.card.data.chartData.length <=
        this.card.data.chartBackgroundColor.length
      )
    ) {
      newColors = this.calcDivInColors(
        this.card.data.chartBackgroundColor[0],
        this.card.data.chartBackgroundColor[1],
        this.card.data.chartData.length,
      );
    } else {
      newColors = this.card.data.chartBackgroundColor;
    }
    return newColors;
  }

  calcDivInColors(
    startColor: string,
    endColor: string,
    dataLength: number,
  ): string[] {
    const divVec: number[] = [];
    const newColors: string[] = [];
    for (let i = 0; i < 3; i++) {
      const sub1 = startColor.substring(1 + 2 * i, 3 + 2 * i);
      const sub2 = endColor.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      const v2 = parseInt(sub2, 16);
      divVec[i] = (v2 - v1) / (dataLength - 1);
    }
    for (let k = 0; k < dataLength; k++) {
      if (k === 0) {
        newColors[k] = startColor;
      } else {
        newColors[k] = this.addDiffToColor(newColors[k - 1], divVec);
      }
    }
    return newColors;
  }

  addDiffToColor(color: string, divVev: number[]) {
    let c = '#';
    for (let i = 0; i < 3; i++) {
      const sub1 = color.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      let v = Math.floor(v1 + divVev[i]);
      if (v > 255) {
        v = v - 255;
      } else if (v < 0) {
        v = v + 255;
      }
      const sub = v.toString(16).toUpperCase();
      const padsub = ('0' + sub).slice(-2);
      c += padsub;
    }
    return c;
  }

  calcColor(c1: string, c2: string) {
    let c = '#';
    for (let i = 0; i < 3; i++) {
      const sub1 = c1.substring(1 + 2 * i, 3 + 2 * i);
      const sub2 = c2.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      const v2 = parseInt(sub2, 16);
      const v = Math.floor((v1 + v2) / 2);
      const sub = v.toString(16).toUpperCase();
      const padsub = ('0' + sub).slice(-2);
      c += padsub;
    }
    return c;
  }
}
