import { HelpActionsUnion, HelpActionTypes } from '../actions/help';

export interface State {
  time: Date;
}

export const initialState: State = {
  time: null!,
};

export function reducer(state = initialState, action: HelpActionsUnion): State {
  switch (action.type) {
    case HelpActionTypes.RequestManual: {
      return {
        ...state,
        time: action.payload,
      } as State;
    }
    case HelpActionTypes.ShowHelpCenter: {
      return {
        ...state,
        time: action.payload,
      } as State;
    }

    default: {
      return state;
    }
  }
}

export const getManualRequest = (state: State) => {
  return state.time;
};

export const getOpenHelpCenterRequest = (state: State) => {
  return state.time;
};
