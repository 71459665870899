import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ITabTableData } from '../../../interfaces/card-layout.interface';
import { ITabTableCard } from './../../../interfaces/card-layout.interface';

@Component({
  selector: 'bewa-table-card',
  templateUrl: './tab-table-card.component.html',
  styleUrls: ['./tab-table-card.component.scss'],
})
@Subscriptions()
export class TabTableCardComponent implements OnInit {
  @Input()
  set card(card: ITabTableCard) {
    this._card = card;
  }
  private _card: ITabTableCard;
  @Unsubscribe()
  private cardUpdateSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: { internalName: string; data: ITabTableData }) => {
        const tableIndex = this.card.data.findIndex(
          (el) => el.internalName === data.internalName,
        );
        for (const key in data.data) {
          if (data.data.hasOwnProperty(key)) {
            (this.card.data[tableIndex] as any)[key] = (data.data as any)[key];
          }
        }
      },
    );
  }

  get card() {
    return this._card;
  }
  onTableAction($event: any) {
    $event;
    // Implement if needed
  }
}
