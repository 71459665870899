import { UtilsService } from '@/app/external-modules/utils/utils.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UpdateMenu } from '../../actions/menu.action';
import { IMenuCategory } from '../../interfaces/menucategory.interface';
import {
  getLicensesSelect,
  getMenuUpdateSelect,
} from '../../reducer/root.reducer';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'bewa-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input()
  set menuCategory(menuCategory: IMenuCategory) {
    this._menuCategory = menuCategory;
  }

  @Output()
  highlightedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  navigationEndSubscription: Subscription;
  menuUpdateSubscription: Subscription;

  private _highlightItem: boolean;
  private _highlights: boolean[] = [];
  private _menuCategory: IMenuCategory;
  private _title: string;
  private _link: string;
  private _licenses: number[];

  constructor(
    private router: Router,
    private utils: UtilsService,
    private loginService: LoginService,
    private store: Store<any>,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.navigationEndSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (this.menuCategory && this.menuCategory.highlighted) {
          this._highlightItem = this.menuCategory.highlighted(
            this.menuCategory,
            event.url,
          );
        } else {
          if (event.url.includes(this.link)) {
            this._highlightItem = true;
          } else {
            this._highlightItem = false;
          }
        }

        this.highlightedChange.emit(this._highlightItem);
      });
    this.menuUpdateSubscription = this.store
      .select(getMenuUpdateSelect)
      .subscribe(() => {
        this.setNamePlaceholder();
        this.setLinkPlaceholder();
      });

    this.store.select(getLicensesSelect).subscribe((licenses) => {
      this._licenses = licenses;
    });
  }

  ngOnDestroy() {
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }
    if (this.menuUpdateSubscription) {
      this.menuUpdateSubscription.unsubscribe();
    }
  }

  setNamePlaceholder() {
    let matches: RegExpMatchArray;
    if (
      this._menuCategory.name &&
      (matches = this._menuCategory.name.match(/{{[A-Z_]*}}/)!)
    ) {
      this._title = this.replacePlaceholders(this._menuCategory.name, matches);
    }
  }

  setLinkPlaceholder() {
    let matches: RegExpMatchArray;
    if (
      this._menuCategory.link &&
      (matches = this._menuCategory.link.match(/{{[A-Z_]*}}/)!)
    ) {
      this._link = this.replacePlaceholders(this._menuCategory.link, matches);
    }
  }
  replacePlaceholders(value: string, matches: RegExpMatchArray): string {
    matches.forEach((match) => {
      const placeholderValue: string = this.evalPlaceholder(
        match.replace(/[{}]/g, ''),
      );

      value = value.replace(match, placeholderValue);
    });

    return value;
  }

  evalPlaceholder(placeholder: string): string {
    let rtn: string = placeholder;

    switch (placeholder) {
      case 'USER_NAME':
        rtn = this.loginService.name;
        break;
      case 'USER_ACCOUNT':
        rtn = this.loginService.accountLink;
        break;
      case 'ACTIVE_LANGUAGE':
        rtn = 'MENU.LANGUAGE.' + this.translateService.currentLang;
        break;

      default:
        break;
    }

    return rtn;
  }

  get isRouterLink() {
    if (this._menuCategory.link) {
      return this._menuCategory.link.startsWith('/');
    }
    return false;
  }

  get subItems() {
    return this._menuCategory.categoryItems;
  }

  get title() {
    return this._title || this._menuCategory.name;
  }

  get icon() {
    return this._menuCategory.iconName;
  }

  get link() {
    return this._link || this._menuCategory.link;
  }

  get action() {
    return this._menuCategory.action;
  }

  get neededRoles() {
    return this._menuCategory.neededRoles;
  }

  get highlighted() {
    return this._highlightItem;
  }

  set highlights(val: { highlighted: boolean; index: number }) {
    this._highlights[val.index] = val.highlighted;
  }

  get expanded() {
    let rtn = false;

    this._highlights.forEach((value) => {
      if (value) {
        rtn = value;
        return;
      }
    });

    return rtn;
  }

  get color() {
    return this._menuCategory.color;
  }

  get license() {
    return this._menuCategory.neededLicense;
  }

  evalBreakpoints(): boolean {
    let rtn: boolean = false;
    if (!this._menuCategory.breakpoints) {
      rtn = true;
    } else if (
      this._menuCategory.breakpoints.lower &&
      this._menuCategory.breakpoints.upper
    ) {
      rtn = this.utils.mediaBreakpointBetween(
        this._menuCategory.breakpoints.lower,
        this._menuCategory.breakpoints.upper,
        window.innerWidth,
      );
    } else if (this._menuCategory.breakpoints.lower) {
      rtn = this.utils.mediaBreakpointUp(
        this._menuCategory.breakpoints.lower,
        window.innerWidth,
      );
    } else if (this._menuCategory.breakpoints.upper) {
      rtn = this.utils.mediaBreakpointDown(
        this._menuCategory.breakpoints.upper,
        window.innerWidth,
      );
    }
    return rtn;
  }

  dispatchAction(action: Action) {
    this.store.dispatch(action);
    this.store.dispatch(new UpdateMenu(new Date()));
  }

  hasLicense(license: number) {
    return this._licenses ? this._licenses.includes(license) : false;
  }
}
