import { getActiveLanguageSelect } from '@/app/external-modules/header/reducers/module';
import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { Logger } from '@/app/external-modules/utils/logger/logger.service';
import { Component, Injector } from '@angular/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BewatecDatetimeAdapter } from './../../services/datetimeAdapter/datetimeAdapter.service';

@Component({
  template: '',
})
@Subscriptions()
export class TranslateLoadingBaseComponent {
  protected translate: TranslateService;
  protected store: Store<any>;
  protected datetimeAdapter: BewatecDatetimeAdapter;
  @Unsubscribe()
  protected activeLanguageSubscription: Subscription;
  protected logger: Logger;

  constructor(injector: Injector) {
    this.translate = injector.get(TranslateService);
    this.store = injector.get(Store);
    this.logger = injector.get(Logger);

    this.datetimeAdapter = <BewatecDatetimeAdapter>(
      injector.get(DatetimeAdapter, null)
    );

    this.translate.setDefaultLang('de-DE');

    this.activeLanguageSubscription = this.store
      .select(getActiveLanguageSelect)
      .subscribe((activeLanguage) => {
        if (activeLanguage) {
          this.translate.use(activeLanguage.code);
          if (this.datetimeAdapter) {
            this.datetimeAdapter.setLocale(activeLanguage.code);
          }
        }
      });
  }
}
