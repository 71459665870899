import { Component, HostBinding } from '@angular/core';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';

@Component({
  selector: 'bewa-number-column-type',
  templateUrl: './number-column-type.component.html',
  styleUrls: ['./number-column-type.component.scss'],
})
export class NumberColumnTypeComponent extends ColumnTypeAbstractComponent {
  @HostBinding('id') get id() {
    return this.layout.id;
  }
  constructor() {
    super();
  }
}
