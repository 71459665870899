import {
  TranslateActionTypes,
  TranslateActionUnion,
} from '../actions/translate.action';

export interface State {
  translateReady: boolean;
}

export const initialState: State = {
  translateReady: false,
};

export function reducer(
  state = initialState,
  action: TranslateActionUnion,
): State {
  switch (action.type) {
    case TranslateActionTypes.TranslateReady: {
      return {
        ...state,
        translateReady: !!action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
export const getTranslateReady = (state: State) => {
  return state.translateReady;
};
