import { Subscription } from 'rxjs';

export function Unsubscribe<T = Subscription>(
  mapper?: (value: T) => Subscription,
) {
  return (target: any, propertyKey: string | symbol) => {
    let value: T | T[];

    Object.defineProperty(target, propertyKey, {
      get() {
        return value;
      },
      set(newValue: T | T[]) {
        value = newValue;
        if (
          Object.prototype.hasOwnProperty.call(target, 'subscriptions') &&
          target.subscriptions instanceof Array
        ) {
          if (value instanceof Array) {
            value.forEach((subValue) => {
              if (subValue instanceof Subscription) {
                target.subscriptions.push(subValue);
              } else {
                target.subscriptions.push(mapper!(subValue));
              }
            });
          } else if (value instanceof Subscription) {
            target.subscriptions.push(value);
          } else {
            target.subscriptions.push(mapper!(value));
          }
        }
      },
    });
  };
}
