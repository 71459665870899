<mat-sidenav-container class="main-nav-container" [style.top]="top + 'px'">
  <mat-sidenav
    *ngIf="userLoggedIn"
    class="side-nav mat-elevation-z2"
    #sidenav
    [mode]="getMenuMode()"
    [(opened)]="isMenuOpen"
  >
    <mat-form-field class="menu-search">
      <input
        matInput="search"
        placeholder=" {{ 'MENU.SEARCH_BAR' | translate }}"
      />
    </mat-form-field>
    <div
      class="menu-category"
      *ngFor="let menuCategory of menuCategories; let index = index"
    >
      <bewa-menu-item [menuCategory]="menuCategory"></bewa-menu-item>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="side-nav-content">
    <div *ngIf="!userLoggedIn" [style.height.px]="height">
      {{ "LOGIN_TO_USE_DEVICE_MANAGEMENT" | translate }}
    </div>
    <div class="p-3" *ngIf="userLoggedIn" [style.height.px]="height">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
