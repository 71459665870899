/* eslint-disable @typescript-eslint/ban-ts-comment */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) enableProdMode();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if (environment.system === 'test' || environment.system === 'prod')
      new BrowserAgent({
        // @ts-ignore
        init: environment.newRelic.init,
        // @ts-ignore
        info: environment.newRelic.info,
        // @ts-ignore
        loader_config: environment.newRelic.loader_config,
      });
  })
  .catch((err) => console.error(err));
