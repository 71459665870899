<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-title">
    <mat-card-title class="card-title">
      {{ card.cardTitle | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <div class="row content-row" content>
      <div #containerRef class="dashboard-chart col-12">
        <ngx-charts-bar-vertical
          [view]="[containerWidth, containerHeight]"
          [results]="barData"
          [customColors]="barColor"
          [legend]="false"
          [animations]="true"
          [tooltipDisabled]="false"
          [trimYAxisTicks]="true"
          [showGridLines]="true"
          [roundEdges]="false"
          [xAxis]="true"
          [yAxis]="true"
          [yAxisLabel]="''"
          [yAxisTicks]="ticks"
          [yAxisTickFormatting]="getYAxisFormat()"
        >
          <ng-template #tooltipTemplate let-model="model" *ngIf="logarithmic">
            <span class="tooltip-label">{{ model.name }}</span>
            <span class="tooltip-val">{{
              getLogarithmicFormat(model.value)
            }}</span>
          </ng-template>
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </mat-card-content>
</mat-card>
