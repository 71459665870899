import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkAwarePreloadingStrategyService
  implements PreloadingStrategy
{
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    const connection = (navigator as any).connection;

    if (!connection?.saveData && route.data && route.data['preload']) {
      const speed = connection?.effectiveType;
      const fastConnections = ['4g'];
      if (fastConnections?.includes(speed)) {
        return fn();
      }
    }

    return of(null);
  }
}
