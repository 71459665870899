import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromBackground from './background.reducer';
import * as fromLicenses from './license.reducer';
import * as fromMenu from './menu.reducer';
import * as fromNavigation from './navigation.reducer';
import * as fromTranslate from './translate.reducer';

export interface RootState {
  background: fromBackground.State;
  translate: fromTranslate.State;
  menu: fromMenu.State;
  navigation: fromNavigation.State;
  licenses: fromLicenses.State;
}

export const reducers: ActionReducerMap<RootState> = {
  background: fromBackground.reducer as any,
  translate: fromTranslate.reducer as any,
  menu: fromMenu.reducer as any,
  navigation: fromNavigation.reducer as any,
  licenses: fromLicenses.reducer as any,
};

export const getRootState = (state: RootState) => state;

// Adjusted selectors to correctly type the RootState and return types.
export const getBackgroundState = createSelector(
  getRootState,
  (state: RootState) => state.background,
);

export const getBackgroundSelect = createSelector(
  getBackgroundState,
  fromBackground.getBackground,
);

export const getTranslateState = createSelector(
  getRootState,
  (state: RootState) => state.translate,
);

export const getTranslateReady = createSelector(
  getTranslateState,
  fromTranslate.getTranslateReady,
);

export const getMenuState = createSelector(
  getRootState,
  (state: RootState) => state.menu,
);

export const getMenuUpdateSelect = createSelector(
  getMenuState,
  fromMenu.getUpdate,
);

export const getNavigationState = createSelector(
  getRootState,
  (state: RootState) => state.navigation,
);

export const getNavigationDataSelect = createSelector(
  getNavigationState,
  fromNavigation.getNavigationData,
);

export const getLicensesState = createSelector(
  getRootState,
  (state: RootState) => state.licenses,
);

export const getLicensesSelect = createSelector(
  getLicensesState,
  fromLicenses.getLicenses,
);
