import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { environment } from '../../../environments/environment';
import { BewatecLibraryModule } from '../library/bewatec-library.module';
import { ProfileAddComponent } from './components/profile-add/profile-add.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { ProfilesOverviewComponent } from './components/profiles-overview/profiles-overview.component';
import { ProfileService } from './services/profile.service';
import { BewatecDialogModule } from '../../external-modules/dialog/bewatec-dialog.module';
import { TourMatMenuModule } from '../../external-modules/tour-md-menu/md-menu.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.langEndpoint, '.json');
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    NgxTrimDirectiveModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BewatecDialogModule,
    BewatecLibraryModule,
    TourMatMenuModule,
    MatCheckboxModule,
  ],
  exports: [
    ProfilesOverviewComponent,
    ProfileEditComponent,
    ProfileAddComponent,
  ],
  declarations: [
    ProfilesOverviewComponent,
    ProfileEditComponent,
    ProfileAddComponent,
  ],
  providers: [ProfileService, TranslateService],
})
export class ProfilesModule {}
