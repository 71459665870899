import { Customer } from '@/app/implementation/customer';
import { environment } from '@/environments/environment';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subscription } from 'rxjs';
import { ReceivedHeaderEvent } from '../../external-modules/header/actions/header';
import {
  EventType,
  HeaderEvent,
} from '../../external-modules/header/models/header-event';
import {
  getHeaderEventSelect,
  getSelectedCustomerSelect,
} from '../../external-modules/header/reducers/module';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  headerEvent$: Observable<HeaderEvent>;
  selectCustomer$: Observable<Customer>;
  selectedCustomerId?: string;
  selectedDistributorId?: string;

  private readonly selectedCustomerSubscription: Subscription;
  private readonly headerEventSubscription: Subscription;

  constructor(
    private store: Store<unknown>,
    private oauthService: OAuthService,
  ) {
    this.selectCustomer$ = this.store.select(getSelectedCustomerSelect);
    this.selectedCustomerSubscription = this.selectCustomer$.subscribe(
      (customer) => {
        this.selectedCustomerId = customer?.id;
        this.selectedDistributorId = customer?.distributorId;
      },
    );

    this.headerEvent$ = this.store.select(getHeaderEventSelect);
    this.headerEventSubscription = this.headerEvent$.subscribe(
      (headerEvent) => {
        if (headerEvent?.eventType === EventType.DeselectCustomer) {
          this.selectedDistributorId = undefined;
          this.selectedCustomerId = undefined;
          this.store.dispatch(new ReceivedHeaderEvent());
        }
      },
    );
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let alteredRequest = request;

    if (this.shouldInterceptForAuth(request)) {
      const tokenHeader = this.oauthService.authorizationHeader();
      alteredRequest = request.clone({
        headers: request.headers.set('Authorization', tokenHeader),
      });
    }

    if (this.shouldInterceptForCustomerAndDistributor(request)) {
      alteredRequest = alteredRequest.clone({
        params: alteredRequest.params
          .set('customerId', this.selectedCustomerId!)
          .set('distributorId', this.selectedDistributorId!),
      });
    }

    return next.handle(alteredRequest);
  }

  private shouldInterceptForAuth(request: HttpRequest<unknown>): boolean {
    return (
      request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.authUrl)
    );
  }

  private shouldInterceptForCustomerAndDistributor(
    request: HttpRequest<unknown>,
  ): boolean {
    // Do not intercept customer requests because of #BOS-3204
    if (request.url.includes('/api/v1/customers') && request.method === 'GET') {
      return false;
    }

    return (
      this.shouldInterceptForAuth(request) &&
      (request as any)['intercept'] !== false &&
      !!this.selectedCustomerId &&
      !!this.selectedDistributorId
    );
  }
}
