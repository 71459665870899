<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let button of buttons">
    <ng-container
      *ngIf="
        !button.displayed || (button?.displayed && button.displayed(tableItem))
      "
    >
      <bewa-menu-item
        [button]="button"
        [tableItem]="tableItem"
        (action)="buttonPress($event)"
      ></bewa-menu-item>
    </ng-container>
  </ng-container>
</mat-menu>
