import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AbstractService } from '../../../services/abstract/abstract.service';

@Injectable()
export class ProfileService extends AbstractService {
  getAvailableProfiles(isPatientProfile: boolean): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');
    options.params = {};
    options.params.is_patient_profile = isPatientProfile;
    options.params.pageable = false;

    let url;
    if (environment.apiVersion === 'v2') {
      url =
        environment.configuration_management.rootV2 +
        environment.configuration_management.profiles;
    } else {
      url =
        environment.configuration_management.root +
        environment.configuration_management.configs;
    }

    return this.httpClient.get(url, options).pipe(catchError(this.handleError));
  }
}
