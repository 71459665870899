/* eslint-disable @angular-eslint/component-selector */
import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { UtilsService } from '../../../utils/utils.service';
import { EmitHeaderEvent } from '../../actions/header';
import { HeaderModuleConfig } from '../../header.module.config';
import { EventType } from '../../models/header-event';
import * as fromModule from '../../reducers/module';

@Component({
  selector: 'bwa-header-component',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private innerWidth: number;
  private _loggedIn: boolean;
  private loggedIn$: Observable<boolean>;
  private userRoles$: Observable<string[]>;
  private _userRoles: string[] = [];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private store: Store<any>,
    public config: HeaderModuleConfig,
    private utils: UtilsService,
  ) {
    this.loggedIn$ = this.store.select(fromModule.getLoggedInSelect);
    this.loggedIn$.subscribe((state) => (this._loggedIn = state));
    this.userRoles$ = this.store.select(fromModule.getUserRolesSelect);
    this.userRoles$.subscribe((roles) => {
      this._userRoles = [];
      if (roles) {
        roles.forEach((role) => {
          this._userRoles.push(role.toLowerCase());
        });
      }
    });
  }

  onClickMenuButton() {
    this.store.dispatch(new EmitHeaderEvent({ eventType: EventType.Menu }));
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  get mediaBreakpointDownMd() {
    return this.utils.mediaBreakpointDown('md', this.innerWidth);
  }

  get mediaBreakpointDownSm() {
    return this.utils.mediaBreakpointDown('sm', this.innerWidth);
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  showSelection() {
    if (
      this.loggedIn &&
      (this.hasRole('Bewatec_Admin') ||
        this.hasRole('Distributor') ||
        this.hasRole('Super_Admin'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasRole(role: string) {
    if (this._userRoles) {
      if (this._userRoles.indexOf(role.toLowerCase()) !== -1) {
        return true;
      }
    }
    return false;
  }

  get userRoles(): string[] {
    return this._userRoles;
  }
}
