<bewa-bewatec-dialog
  [dialogTitle]="'CHOOSE_CUSTOMER_DIALOG.CHOOSE_CUSTOMER'"
  [dialogIcon]="'perm_identity'"
  [showCancelButton]="true"
  (action)="onClickOk()"
  [actionButtonDisabled]="!customerSelected"
  [actionButtonName]="'BUTTONS.OK'"
>
  <p>{{ "CHOOSE_CUSTOMER_DIALOG.MESSAGE" | translate }}</p>
  <p></p>
  <mat-form-field class="customer-select">
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      [spellcheck]="false"
      #autosize="cdkTextareaAutosize"
      [placeholder]="'CHOOSE_CUSTOMER_DIALOG.CHOOSE_CUSTOMER' | translate"
      [formControl]="customerControl"
      [matAutocomplete]="autoGroup"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      (keydown.enter)="onClickOk()"
      devid="customerSelectInput"
    ></textarea>
    <mat-autocomplete
      autoActiveFirstOption
      #autoGroup="matAutocomplete"
      disabled
    >
      <mat-option
        *ngFor="let customer of filteredCustomers | async | sort: 'displayName'"
        [value]="customer.displayName"
        (onSelectionChange)="onSelectionChange($event, customer)"
      >
        {{ customer.displayName }}
      </mat-option>
    </mat-autocomplete>
    <button
      class="remove-button"
      *ngIf="customerSelected"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="onRemoveCustomerSelection()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</bewa-bewatec-dialog>
