export interface HeaderEvent {
  eventType: EventType;
  payload?: string;
}

export enum EventType {
  Login = '[EventType] Login',
  Logout = '[EventType] Logout',
  Home = '[EventType] Home',
  Menu = '[EventType] Menu',
  SelectCustomer = '[EventType] Select Customer',
  DeselectCustomer = '[EventType] Deselect Customer',
  SetRoleView = '[EventType] Set Role View',
  ClearRoleView = '[EventType] Clear Role View',
}
