import { createEnvironment, createNewRelicConfig } from './environment.base';

export const environment = createEnvironment({
  apiURL: 'https://dm-api.staging.connectedcare.io',
  authURL: 'https://dm-auth.staging.connectedcare.io',
  production: true,
  system: 'test',
  newRelic: createNewRelicConfig({
    applicationID: '538507427',
    agentID: '538507427',
  }),
  clientId: 'device-management-fe',
  authRealmPath: '/auth/realms/device-management-test',
});
