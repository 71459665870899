import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMasonryModule } from 'ngx-masonry';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { environment } from '../../../../../environments/environment';
import { ShareModule } from '../../share.module';
import { GetAttributePipe } from '../table/utils/getattribute.pipe.component';
import { ButtonContentComponent } from './components/button-content/button-content.component';
import { ColumnSelectorComponent } from './components/column-selector/column-selector.component';
import { ArrayColumnTypeComponent } from './components/column-types/array-column-type/array-column-type.component';
import { DateColumnTypeComponent } from './components/column-types/date-column-type/date-column-type.component';
import { IconColumnTypeComponent } from './components/column-types/icon-column-type/icon-column-type.component';
import { LinkColumnTypeComponent } from './components/column-types/link-column-type/link-column-type.component';
import { NumberColumnTypeComponent } from './components/column-types/number-column-type/number-column-type.component';
import { ObjectColumnTypeComponent } from './components/column-types/object-column-type/object-column-type.component';
import { StringColumnTypeComponent } from './components/column-types/string-column-type/string-column-type.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuComponent } from './components/menu/menu.component';
import { MatPaginatorIntlService } from './services/MatPaginatorIntlService.service';
import { TableV2Component } from './table-v2.component';
import { TourMatMenuModule } from '../../../../external-modules/tour-md-menu/md-menu.module';

export function createMultiTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: environment.langEndpoint + 'bewatec-library/', suffix: '.json' },
    { prefix: environment.langEndpoint, suffix: '.json' },
  ]);
}

@NgModule({
  imports: [
    TourMatMenuModule,
    CommonModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatInputModule,
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    RouterModule,
    AngularSvgIconModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createMultiTranslateLoader,
        deps: [HttpBackend],
      },
      isolate: true,
    }),
    MatGridListModule,
    LayoutModule,
    NgxMasonryModule,
    NgxTrimDirectiveModule,
    ShareModule,
  ],
  declarations: [
    TableV2Component,
    ColumnSelectorComponent,
    StringColumnTypeComponent,
    DateColumnTypeComponent,
    GetAttributePipe,
    LinkColumnTypeComponent,
    ArrayColumnTypeComponent,
    NumberColumnTypeComponent,
    IconColumnTypeComponent,
    ObjectColumnTypeComponent,
    ButtonContentComponent,
    MenuItemComponent,
    MenuComponent,
  ],
  exports: [GetAttributePipe, TableV2Component],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new MatPaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
  ],
})
export class BewatecTableV2Module {}
