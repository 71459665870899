/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FwProbingResultOverviewItemDto } from './fwProbingResultOverviewItemDto';
import { FwProbingResultOverviewDeviceDto } from './fwProbingResultOverviewDeviceDto';


export interface FwProbingResultOverviewDto { 
    id?: number;
    timestamp?: number;
    created?: number;
    updated?: number;
    probingStartTimestamp?: number;
    probingTrigger?: FwProbingResultOverviewDto.ProbingTriggerEnum;
    customerId?: string;
    customerName?: string;
    distributorId?: string;
    distributorName?: string;
    bridgeName?: string;
    hasBridge?: boolean;
    overallStatus?: FwProbingResultOverviewDto.OverallStatusEnum;
    tabletDmStatus?: FwProbingResultOverviewDto.TabletDmStatusEnum;
    tabletDmAuthStatus?: FwProbingResultOverviewDto.TabletDmAuthStatusEnum;
    tabletCmsStatus?: FwProbingResultOverviewDto.TabletCmsStatusEnum;
    tabletPaymentProxyStatus?: FwProbingResultOverviewDto.TabletPaymentProxyStatusEnum;
    csDmStatus?: FwProbingResultOverviewDto.CsDmStatusEnum;
    csDmAuthStatus?: FwProbingResultOverviewDto.CsDmAuthStatusEnum;
    csCmsStatus?: FwProbingResultOverviewDto.CsCmsStatusEnum;
    csPaymentProxyStatus?: FwProbingResultOverviewDto.CsPaymentProxyStatusEnum;
    csUbuntuRepositoryStatus?: FwProbingResultOverviewDto.CsUbuntuRepositoryStatusEnum;
    csMqttStatus?: FwProbingResultOverviewDto.CsMqttStatusEnum;
    csS3Status?: FwProbingResultOverviewDto.CsS3StatusEnum;
    probes?: Array<FwProbingResultOverviewItemDto>;
    devices?: Array<FwProbingResultOverviewDeviceDto>;
}
export namespace FwProbingResultOverviewDto {
    export type ProbingTriggerEnum = 'CRON' | 'MANUAL';
    export const ProbingTriggerEnum = {
        Cron: 'CRON' as ProbingTriggerEnum,
        Manual: 'MANUAL' as ProbingTriggerEnum
    };
    export type OverallStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const OverallStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as OverallStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as OverallStatusEnum,
        AllFailed: 'ALL_FAILED' as OverallStatusEnum,
        NoResults: 'NO_RESULTS' as OverallStatusEnum,
        Outdated: 'OUTDATED' as OverallStatusEnum
    };
    export type TabletDmStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const TabletDmStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as TabletDmStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as TabletDmStatusEnum,
        AllFailed: 'ALL_FAILED' as TabletDmStatusEnum,
        NoResults: 'NO_RESULTS' as TabletDmStatusEnum,
        Outdated: 'OUTDATED' as TabletDmStatusEnum
    };
    export type TabletDmAuthStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const TabletDmAuthStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as TabletDmAuthStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as TabletDmAuthStatusEnum,
        AllFailed: 'ALL_FAILED' as TabletDmAuthStatusEnum,
        NoResults: 'NO_RESULTS' as TabletDmAuthStatusEnum,
        Outdated: 'OUTDATED' as TabletDmAuthStatusEnum
    };
    export type TabletCmsStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const TabletCmsStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as TabletCmsStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as TabletCmsStatusEnum,
        AllFailed: 'ALL_FAILED' as TabletCmsStatusEnum,
        NoResults: 'NO_RESULTS' as TabletCmsStatusEnum,
        Outdated: 'OUTDATED' as TabletCmsStatusEnum
    };
    export type TabletPaymentProxyStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const TabletPaymentProxyStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as TabletPaymentProxyStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as TabletPaymentProxyStatusEnum,
        AllFailed: 'ALL_FAILED' as TabletPaymentProxyStatusEnum,
        NoResults: 'NO_RESULTS' as TabletPaymentProxyStatusEnum,
        Outdated: 'OUTDATED' as TabletPaymentProxyStatusEnum
    };
    export type CsDmStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsDmStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsDmStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsDmStatusEnum,
        AllFailed: 'ALL_FAILED' as CsDmStatusEnum,
        NoResults: 'NO_RESULTS' as CsDmStatusEnum,
        Outdated: 'OUTDATED' as CsDmStatusEnum
    };
    export type CsDmAuthStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsDmAuthStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsDmAuthStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsDmAuthStatusEnum,
        AllFailed: 'ALL_FAILED' as CsDmAuthStatusEnum,
        NoResults: 'NO_RESULTS' as CsDmAuthStatusEnum,
        Outdated: 'OUTDATED' as CsDmAuthStatusEnum
    };
    export type CsCmsStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsCmsStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsCmsStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsCmsStatusEnum,
        AllFailed: 'ALL_FAILED' as CsCmsStatusEnum,
        NoResults: 'NO_RESULTS' as CsCmsStatusEnum,
        Outdated: 'OUTDATED' as CsCmsStatusEnum
    };
    export type CsPaymentProxyStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsPaymentProxyStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsPaymentProxyStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsPaymentProxyStatusEnum,
        AllFailed: 'ALL_FAILED' as CsPaymentProxyStatusEnum,
        NoResults: 'NO_RESULTS' as CsPaymentProxyStatusEnum,
        Outdated: 'OUTDATED' as CsPaymentProxyStatusEnum
    };
    export type CsUbuntuRepositoryStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsUbuntuRepositoryStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsUbuntuRepositoryStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsUbuntuRepositoryStatusEnum,
        AllFailed: 'ALL_FAILED' as CsUbuntuRepositoryStatusEnum,
        NoResults: 'NO_RESULTS' as CsUbuntuRepositoryStatusEnum,
        Outdated: 'OUTDATED' as CsUbuntuRepositoryStatusEnum
    };
    export type CsMqttStatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsMqttStatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsMqttStatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsMqttStatusEnum,
        AllFailed: 'ALL_FAILED' as CsMqttStatusEnum,
        NoResults: 'NO_RESULTS' as CsMqttStatusEnum,
        Outdated: 'OUTDATED' as CsMqttStatusEnum
    };
    export type CsS3StatusEnum = 'ALL_POSITIVE' | 'PARTIAL_FAILURE' | 'ALL_FAILED' | 'NO_RESULTS' | 'OUTDATED';
    export const CsS3StatusEnum = {
        AllPositive: 'ALL_POSITIVE' as CsS3StatusEnum,
        PartialFailure: 'PARTIAL_FAILURE' as CsS3StatusEnum,
        AllFailed: 'ALL_FAILED' as CsS3StatusEnum,
        NoResults: 'NO_RESULTS' as CsS3StatusEnum,
        Outdated: 'OUTDATED' as CsS3StatusEnum
    };
}


