import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SetNavigationData } from '../../actions/navigation.action';

@Injectable()
export class NavigationService {
  constructor(
    private router: Router,
    private store: Store<any>,
  ) {}

  navigate(commands: any[], data?: any, extras?: NavigationExtras) {
    if (data) {
      this.store.dispatch(new SetNavigationData(data));
    }
    this.router.navigate(commands, extras);
  }
}
