import { EmitHeaderEvent } from '../external-modules/header/actions/header';
import { UpdateActiveLanguage } from '../external-modules/header/actions/language';
import { EventType } from '../external-modules/header/models/header-event';
import { ICountryCode } from '../interfaces/country-code.interface';
import { IMenuCategory } from '../interfaces/menucategory.interface';

export const MENU: IMenuCategory[] = [
  {
    name: 'MENU.DEVICES.NAME',
    iconName: 'tablet',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.DEVICES.DEVICES_OVERVIEW.NAME',
        link: '/devices',
        description: 'MENU.DEVICES.DEVICES_OVERVIEW.NAME.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
      {
        name: 'MENU.DEVICES.DEVICE_GROUPS.NAME',
        link: '/device-groups',
        description: 'MENU.DEVICES.DEVICE_GROUPS.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
  },
  {
    name: 'MENU.LOCATIONS.NAME',
    iconName: 'location_on',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.LOCATIONS.LOCATIONS_OVERVIEW.NAME',
        link: '/locations',
        description: 'MENU.LOCATIONS.LOCATIONS_OVERVIEW.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
      {
        name: 'MENU.LOCATIONS.LOCATION_GROUPS.NAME',
        link: '/location-groups',
        description: 'MENU.LOCATIONS.LOCATION_GROUPS.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
  },
  {
    name: 'MENU.CONFIGURATION.NAME',
    iconName: 'computer',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.CONFIGURATION.DEVICE_PROFILE.NAME',
        link: '/deviceprofiles',
        description: 'MENU.CONFIGURATION.DEVICE_PROFILE.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
      {
        name: 'MENU.CONFIGURATION.PATIENT_PROFILE.NAME',
        link: '/patientprofiles',
        description: 'MENU.CONFIGURATION.PATIENT_PROFILE.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
  },
  {
    name: 'MENU.CONFIGURATION.CERTIFICATE_MANAGEMENT.NAME',
    description: 'MENU.CONFIGURATION.CERTIFICATE_MANAGEMENT.DESCRIPTION',
    iconName: 'card_membership',
    neededRoles: ['Super_Admin', 'Bewatec_Admin', 'Distributor', 'Customer'],
    link: '/certificate-management',
  },
  {
    name: 'MENU.LICENSES.NAME',
    iconName: 'monetization_on',
    neededRoles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
    categoryItems: [
      {
        name: 'MENU.LICENSES.LICENSE_OVERVIEW.NAME',
        description: 'MENU.LICENSE_MANAGEMENT.DESCRIPTION',
        neededRoles: ['Bewatec_Admin', 'Super_Admin', 'Distributor'],
        link: '/licensemanagement',
      },
    ],
  },
  // {
  //   name: 'MENU.CONTENT.NAME',
  //   link: '/content',
  //   description: 'MENU.CONTENT.DESCRIPTION',
  //   iconName: 'content_paste',
  //   neededRole: 'Bewatec_Admin'
  // },
  {
    name: 'MENU.SOFTWARE.NAME',
    iconName: 'system_update_alt',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.SOFTWARE.DEVICE_IMAGE.NAME',
        link: '/update',
        description: 'MENU.SOFTWARE.DEVICE_IMAGE.DESCRIPTION',
        // iconName: 'system_update_alt',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
      {
        name: 'MENU.SOFTWARE.RELEASE.NAME',
        link: '/releasemanagement',
        description: 'MENU.SOFTWARE.RELEASE.DESCRIPTION',
        // iconName: 'new_releases',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
  },
  {
    name: 'MENU.USERS.NAME',
    iconName: 'account_circle',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.USERS.USER_OVERVIEW.NAME',
        link: '/usermanagement',
        description: 'MENU.USERS.USER_OVERVIEW.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
      {
        name: 'MENU.USERS.USER_HISTORY.NAME',
        link: '/userhistory',
        description: 'MENU.USERS.USER_HISTORY.DESCRIPTION',
        neededRoles: ['Bewatec_Admin', 'Super_Admin'],
      },
    ],
  },
  {
    name: 'MENU.CUSTOMERS_DISTRIBUTORS.NAME',
    iconName: 'group',
    neededRoles: ['Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.CUSTOMERS_DISTRIBUTORS.CUSTOMER_OVERVIEW.NAME',
        link: '/customers',
        description:
          'MENU.CUSTOMERS_DISTRIBUTORS.CUSTOMER_OVERVIEW.DESCRIPTION',
        neededRoles: ['Distributor', 'Bewatec_Admin', 'Super_Admin'],
      },
      {
        name: 'MENU.CUSTOMERS_DISTRIBUTORS.DISTRIBUTOR_OVERVIEW.NAME',
        link: '/distributors',
        description:
          'MENU.CUSTOMERS_DISTRIBUTORS.DISTRIBUTOR_OVERVIEW.DESCRIPTION',
        neededRoles: ['Bewatec_Admin', 'Super_Admin'],
      },
    ],
  },
  {
    name: 'MENU.CUSTOMERS_DISTRIBUTORS.CUSTOMER_OVERVIEW.NAME',
    iconName: 'group',
    neededRoles: ['Distributor'],
    categoryItems: [
      {
        name: 'MENU.CUSTOMERS_DISTRIBUTORS.CUSTOMER_OVERVIEW.NAME',
        link: '/customers',
        description:
          'MENU.CUSTOMERS_DISTRIBUTORS.CUSTOMER_OVERVIEW.DESCRIPTION',
        neededRoles: ['Distributor'],
      },
    ],
  },
  {
    name: 'MENU.APPLICATIONS.NAME',
    description: 'MENU.APPLICATIONS.DESCRIPTION',
    iconName: 'apps',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.APPLICATIONS.WEBAPP.NAME',
        link: '/apps/webapp',
        description: 'MENU.APPLICATIONS.WEBAPP.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
  },
  {
    name: '{{USER_NAME}}',
    description: 'MENU.ACCOUNT.DESCRIPTION',
    iconName: 'person',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'MENU.ACCOUNT.USER_ACCOUNT.NAME',
        link: '{{USER_ACCOUNT}}',
        description: 'MENU.ACCOUNT.USER_ACCOUNT.DESCRIPTION',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
      },
    ],
    type: 'MENU_BUTTON',
    breakpoints: { upper: 'md' },
    position: 'END',
  },
  {
    name: '{{ACTIVE_LANGUAGE}}',
    description: 'MENU.LANGUAGE.DESCRIPTION',
    iconName: 'language',
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    categoryItems: [
      {
        name: 'Deutsch',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
        // Alternative for loading from json file
        // action: { payload: { code: 'de-DE', name: 'Deutsch' }, type: '[Language] Update Active Language' }
        action: new UpdateActiveLanguage({ code: 'de-DE', name: 'Deutsch' }),
      },
      {
        name: 'English',
        neededRoles: [
          'Customer',
          'Distributor',
          'Bewatec_Admin',
          'Super_Admin',
        ],
        action: new UpdateActiveLanguage({ code: 'en-UK', name: 'English' }),
      },
    ],
    type: 'MENU_BUTTON',
    breakpoints: { upper: 'md' },
    position: 'END',
  },
  {
    name: 'MENU.LOGOUT.NAME',
    description: 'MENU.LOGOUT.DESCRIPTION',
    action: new EmitHeaderEvent({ eventType: EventType.Logout }),
    neededRoles: ['Customer', 'Distributor', 'Bewatec_Admin', 'Super_Admin'],
    type: 'MENU_BUTTON',
    breakpoints: { upper: 'md' },
    position: 'END',
    color: 'primary',
  },
  {
    name: 'MENU.ADMINISTRATION.NAME',
    description: 'MENU.ADMINISTRATION.DESCRIPTION',
    iconName: 'rowing',
    neededRoles: ['Super_Admin', 'Bewatec_Admin', 'Distributor'],
    categoryItems: [
      {
        name: 'MENU.ADMINISTRATION.ADMINISTRATION.NAME',
        link: '/administration',
        description: 'MENU.ADMINISTRATION.ADMINISTRATION.DESCRIPTION',
        neededRoles: ['Super_Admin'],
      },
      {
        name: 'MENU.ADMINISTRATION.LICENSE.NAME',
        link: '/licensemanagement/administration',
        description: 'MENU.ADMINISTRATION.LICENSE.DESCRIPTION',
        neededRoles: ['Super_Admin'],
      },
      {
        name: 'MENU.ADMINISTRATION.CONFIG.NAME',
        link: '/administration/config',
        description: 'MENU.ADMINISTRATION.CONFIG.DESCRIPTION',
        neededRoles: ['Super_Admin'],
      },
      {
        name: 'MENU.ADMINISTRATION.BRIDGES.NAME',
        link: '/bridges',
        description: 'MENU.ADMINISTRATION.BRIDGES.DESCRIPTION',
        neededRoles: ['Super_Admin', 'Bewatec_Admin', 'Distributor'],
      },
    ],
  },
  {
    name: 'MENU.ADMINISTRATION.MANAGEMENT.NAME',
    description: 'MENU.ADMINISTRATION.MANAGEMENT.NAME',
    iconName: 'analytics',
    neededRoles: ['Super_Admin', 'Bewatec_Admin'],
    categoryItems: [
      {
        name: 'MENU.ADMINISTRATION.OVERVIEW.NAME',
        link: '/management/overview',
        description: 'MENU.ADMINISTRATION.OVERVIEW.DESCRIPTION',
        neededRoles: ['Super_Admin', 'Bewatec_Admin'],
      },
      {
        name: 'MENU.ADMINISTRATION.MONITOR.NAME',
        link: '/management/firewall-monitor',
        description: 'MENU.ADMINISTRATION.MONITOR.DESCRIPTION',
        neededRoles: ['Super_Admin', 'Bewatec_Admin'],
      },
    ],
  },
  {
    name: 'MENU.ANNOUNCEMENT.NAME',
    description: 'MENU.ANNOUNCEMENT.DESCRIPTION',
    iconName: 'outgoing_mail',
    neededRoles: ['Super_Admin', 'Bewatec_Admin', 'Distributor', 'Customer'],
    neededLicense: 227,
    link: '/announcement',
  },
];

export const COUNTRIES: ICountryCode[] = [
  {
    countryCode: 'deu',
    countryName: 'FORM_LABELS.COUNTRY.DEU',
    orderNumber: 1,
  },
  {
    countryCode: 'che',
    countryName: 'FORM_LABELS.COUNTRY.CHE',
    orderNumber: 2,
  },
  {
    countryCode: 'swe',
    countryName: 'FORM_LABELS.COUNTRY.SWE',
    orderNumber: 3,
  },
  {
    countryCode: 'aut',
    countryName: 'FORM_LABELS.COUNTRY.AUT',
    orderNumber: 4,
  },
  {
    countryCode: 'aus',
    countryName: 'FORM_LABELS.COUNTRY.AUS',
    orderNumber: 5,
  },
  {
    countryCode: 'sau',
    countryName: 'FORM_LABELS.COUNTRY.SAU',
    orderNumber: 6,
  },
  {
    countryCode: 'nor',
    countryName: 'FORM_LABELS.COUNTRY.NOR',
    orderNumber: 7,
  },
  {
    countryCode: 'ita',
    countryName: 'FORM_LABELS.COUNTRY.ITA',
  },
  {
    countryCode: 'fra',
    countryName: 'FORM_LABELS.COUNTRY.FRA',
  },
  {
    countryCode: 'nld',
    countryName: 'FORM_LABELS.COUNTRY.NLD',
  },
  {
    countryCode: 'grc',
    countryName: 'FORM_LABELS.COUNTRY.GRC',
  },
  {
    countryCode: 'esp',
    countryName: 'FORM_LABELS.COUNTRY.ESP',
  },
  {
    countryCode: 'pol',
    countryName: 'FORM_LABELS.COUNTRY.POL',
  },
  {
    countryCode: 'hrv',
    countryName: 'FORM_LABELS.COUNTRY.HRV',
  },
  {
    countryCode: 'fin',
    countryName: 'FORM_LABELS.COUNTRY.FIN',
  },
  {
    countryCode: 'cze',
    countryName: 'FORM_LABELS.COUNTRY.CZE',
  },
  {
    countryCode: 'bel',
    countryName: 'FORM_LABELS.COUNTRY.BEL',
  },
  {
    countryCode: 'cyp',
    countryName: 'FORM_LABELS.COUNTRY.CYP',
  },
  {
    countryCode: 'dnk',
    countryName: 'FORM_LABELS.COUNTRY.DNK',
  },
  {
    countryCode: 'hun',
    countryName: 'FORM_LABELS.COUNTRY.HUN',
  },
  {
    countryCode: 'rou',
    countryName: 'FORM_LABELS.COUNTRY.ROU',
  },
  {
    countryCode: 'lux',
    countryName: 'FORM_LABELS.COUNTRY.LUX',
  },
  {
    countryCode: 'bgr',
    countryName: 'FORM_LABELS.COUNTRY.BGR',
  },
  {
    countryCode: 'irl',
    countryName: 'FORM_LABELS.COUNTRY.IRL',
  },
  {
    countryCode: 'svn',
    countryName: 'FORM_LABELS.COUNTRY.SVN',
  },
  {
    countryCode: 'ltu',
    countryName: 'FORM_LABELS.COUNTRY.LTU',
  },
  {
    countryCode: 'est',
    countryName: 'FORM_LABELS.COUNTRY.EST',
  },
  {
    countryCode: 'lva',
    countryName: 'FORM_LABELS.COUNTRY.LVA',
  },
  {
    countryCode: 'skv',
    countryName: 'FORM_LABELS.COUNTRY.SVK',
  },
  {
    countryCode: 'gbr',
    countryName: 'FORM_LABELS.COUNTRY.GBR',
  },
  {
    countryCode: 'rus',
    countryName: 'FORM_LABELS.COUNTRY.RUS',
  },
];

export const enum RELEASE_STATES {
  LOCKED = 'LOCKED',
  PROTOTPYE = 'INTERNAL_ONLY',
  TEST = 'TESTING',
  ENABLED = 'GLOBAL',
}

export const HELP_TOUR: any[] = [
  {
    name: 'TOUR.TABLE.INTRODUCTION.TITLE',
    locations: ['devices', 'locations'],
    icon: 'navigation',
    steps: [
      {
        anchorId: 'help.anchor.contentcard.buttonbar',
        content: 'TOUR.TABLE.INTRODUCTION.BUTTON_BAR.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.BUTTON_BAR.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.row',
        hidden: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.table.detailrow',
        content: 'TOUR.TABLE.INTRODUCTION.DETAIL_ROW.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.DETAIL_ROW.TITLE',
        enableBackdrop: true,
        action: 'click',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.table.row',
        hidden: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.table.rowactions',
        content: 'TOUR.TABLE.INTRODUCTION.ROW_ACTIONS.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.ROW_ACTIONS.TITLE',
      },
      {
        anchorId: 'help.anchor.table.rowselection',
        content: 'TOUR.TABLE.INTRODUCTION.ROW_SELECTION.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.ROW_SELECTION.TITLE',
      },
      {
        anchorId: 'help.anchor.table.columnselectbutton',
        content: 'TOUR.TABLE.INTRODUCTION.COLUMN_SELECT_BUTTON.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.COLUMN_SELECT_BUTTON.TITLE',
        action: 'click',
      },
      {
        anchorId: 'help.anchor.table.columnselect',
        content: 'TOUR.TABLE.INTRODUCTION.COLUMN_SELECT.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.COLUMN_SELECT.TITLE',
        enableBackdrop: true,
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.table.columnselectbutton',
        hidden: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.table.columnreset',
        content: 'TOUR.TABLE.INTRODUCTION.COLUMN_RESET.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.COLUMN_RESET.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.columnhead',
        content: 'TOUR.TABLE.INTRODUCTION.COLUMN_HEAD.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.COLUMN_HEAD.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.searchbar',
        content: 'TOUR.TABLE.INTRODUCTION.SEARCHBAR.CONTENT',
        title: 'TOUR.TABLE.INTRODUCTION.SEARCHBAR.TITLE',
      },
    ],
  },
  {
    name: 'TOUR.TABLE.SEARCH_AND_SORT.TITLE',
    locations: ['devices'],
    icon: 'search',
    steps: [
      {
        anchorId: 'help.anchor.table.searchbar',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_1.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_1.TITLE',
      },
      {
        anchorId: 'help.anchor.table.searchbar',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_2.CONTENT_DEVICES',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_2.TITLE',
      },
      {
        anchorId: 'help.anchor.table.columnhead',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.COLUMN_HEAD.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.COLUMN_HEAD.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.column.sortbutton',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SORT_BUTTON.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SORT_BUTTON.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.column.searchbutton',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCH_BUTTON.CONTENT_DEVICES',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCH_BUTTON.TITLE',
        enableBackdrop: true,
      },
    ],
  },
  {
    name: 'TOUR.TABLE.SEARCH_AND_SORT.TITLE',
    locations: ['locations'],
    icon: 'search',
    steps: [
      {
        anchorId: 'help.anchor.table.searchbar',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_1.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_1.TITLE',
      },
      {
        anchorId: 'help.anchor.table.searchbar',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_2.CONTENT_LOCATIONS',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCHBAR_2.TITLE',
      },
      {
        anchorId: 'help.anchor.table.columnhead',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.COLUMN_HEAD.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.COLUMN_HEAD.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.column.sortbutton',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SORT_BUTTON.CONTENT',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SORT_BUTTON.TITLE',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.table.column.searchbutton',
        content: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCH_BUTTON.CONTENT_LOCATIONS',
        title: 'TOUR.TABLE.SEARCH_AND_SORT.SEARCH_BUTTON.TITLE',
        enableBackdrop: true,
      },
    ],
  },
  {
    name: 'TOUR.DEVICE_GROUPS.ADD_GROUP.TITLE',
    locations: ['device-groups'],
    icon: 'add',
    steps: [
      {
        anchorId: 'help.anchor.devicegroup.add',
        title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.ADD_BUTTON.TITLE',
        content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.ADD_BUTTON.CONTENT',
        enableBackdrop: true,
        location: '/device-groups',
        action: 'click',
        subSteps: [
          {
            anchorId: 'help.anchor.devicegroup.add.name',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.NAME.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.NAME.CONTENT',
          },
          {
            anchorId: 'help.anchor.table.searchbar',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.SEARCH.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.SEARCH.CONTENT',
          },
          {
            anchorId: 'help.anchor.devicegroup.add.add_device',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.ADD_DEVICE.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.ADD_DEVICE.CONTENT',
            action: 'click',
          },
          {
            anchorId: 'help.anchor.devicegroup.add.changes',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.CHANGES.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.CHANGES.CONTENT',
            isSubStep: true,
            enableBackdrop: true,
          },
          {
            anchorId: 'help.anchor.devicegroup.add.revert_device',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.REVERT_DEVICE.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.REVERT_DEVICE.CONTENT',
            action: 'click',
          },
          {
            anchorId: 'help.anchor.devicegroup.add.save',
            title: 'TOUR.DEVICE_GROUPS.ADD_GROUP.SAVE.TITLE',
            content: 'TOUR.DEVICE_GROUPS.ADD_GROUP.SAVE.CONTENT',
          },
        ],
      },
    ],
  },
  {
    name: 'TOUR.LOCATIONS.ADD_LOCATION.TITLE',
    locations: ['locations'],
    icon: 'add',
    steps: [
      {
        anchorId: 'help.anchor.location.add',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.ADD_BUTTON.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.ADD_BUTTON.CONTENT',
        enableBackdrop: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.location.logical',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.LOGICAL_LOCATION.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.LOGICAL_LOCATION.CONTENT',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.location.physical',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.PHYSICAL_LOCATION.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.PHYSICAL_LOCATION.CONTENT',
      },
      {
        anchorId: 'help.anchor.location.tab2',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.ADDITIONAL_DATA.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.ADDITIONAL_DATA.CONTENT',
        action: 'click',
      },
      {
        anchorId: 'help.anchor.location.moxa',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.MOXA_DATA.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.MOXA_DATA.CONTENT',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.location.wlan.togglesettings',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.ENABLE_WIFI_SETTINGS.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.ENABLE_WIFI_SETTINGS.CONTENT',
        action: 'click',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.location.wlan.toggleactive',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.ENABLE_WIFI.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.ENABLE_WIFI.CONTENT',
        action: 'click',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.location.wlan.channel',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_CHANNEL.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_CHANNEL.CONTENT',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.location.wlan.transmitpower',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_TX_POWER.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_TX_POWER.CONTENT',
      },
      {
        anchorId: 'help.anchor.location.wlan.transmitpower',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_INFO.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.WIFI_INFO.CONTENT',
      },
      {
        anchorId: 'dialog.anchor.action',
        title: 'TOUR.LOCATIONS.ADD_LOCATION.SAVE_LOCATION.TITLE',
        content: 'TOUR.LOCATIONS.ADD_LOCATION.SAVE_LOCATION.CONTENT',
      },
    ],
  },
  {
    name: 'TOUR.LOCATION_GROUPS.ADD_GROUP.TITLE',
    locations: ['location-groups'],
    icon: 'add',
    steps: [
      {
        anchorId: 'help.anchor.locationgroup.add',
        title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.ADD_BUTTON.TITLE',
        content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.ADD_BUTTON.CONTENT',
        enableBackdrop: true,
        location: '/location-groups',
        action: 'click',
        subSteps: [
          {
            anchorId: 'help.anchor.locationgroup.add.name',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.NAME.CONTENT',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.NAME.TITLE',
            subStep: true,
          },
          {
            anchorId: 'help.anchor.table.searchbar',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.SEARCH.CONTENT',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.SEARCH.TITLE',
          },
          {
            anchorId: 'help.anchor.locationgroup.add.add_location',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.ADD_LOCATION.TITLE',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.ADD_LOCATION.CONTENT',
            action: 'click',
          },
          {
            anchorId: 'help.anchor.locationgroup.add.changes',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.CHANGES.TITLE',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.CHANGES.CONTENT',
            isSubStep: true,
            enableBackdrop: true,
          },
          {
            anchorId: 'help.anchor.locationgroup.add.revert_location',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.REVERT_LOCATION.TITLE',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.REVERT_LOCATION.CONTENT',
            action: 'click',
          },
          {
            anchorId: 'help.anchor.locationgroup.add.save',
            content: 'TOUR.LOCATION_GROUPS.ADD_GROUP.SAVE.CONTENT',
            title: 'TOUR.LOCATION_GROUPS.ADD_GROUP.SAVE.TITLE',
          },
        ],
      },
    ],
  },
  {
    name: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.TITLE',
    locations: ['device-profiles'],
    icon: 'add',
    steps: [
      {
        anchorId: 'help.anchor.deviceprofile.add',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.ADD_BUTTON.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.ADD_BUTTON.CONTENT',
        enableBackdrop: true,
        location: 'deviceprofiles',
        action: 'click',
      },
      {
        anchorId: 'help.anchor.profile.name',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.NAME.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.NAME.CONTENT',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.profile.description',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.DESCRIPTION.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.DESCRIPTION.CONTENT',
      },
      {
        anchorId: 'help.anchor.profile.copyfrom',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.COPY_FROM.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.COPY_FROM.CONTENT',
      },
      {
        anchorId: 'help.anchor.deviceprofile.grouptype',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUP_TYPE.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUP_TYPE.CONTENT',
        location: 'deviceprofiles/add',
      },
      {
        anchorId: 'help.anchor.deviceprofile.groups',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUPS.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUPS.CONTENT',
        enableBackdrop: true,
      },
      {
        anchorId: 'help.anchor.deviceprofile.groups',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUPS_BEHAVIOUR.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.GROUPS_BEHAVIOUR.CONTENT',
      },
      {
        anchorId: 'help.anchor.profile.saveandconfigure',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.SAVE_AND_CONFIG.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.SAVE_AND_CONFIG.CONTENT',
      },
      {
        anchorId: 'help.anchor.profile.save',
        title: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.SAVE.TITLE',
        content: 'TOUR.DEVICE_PROFILES.ADD_PROFILE.SAVE.CONTENT',
      },
    ],
  },
  {
    name: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.TITLE',
    locations: ['device-profiles'],
    icon: 'settings',
    steps: [
      {
        anchorId: 'help.anchor.table.rowactions',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.ROW_ACTIONS.TITLE',
        content: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.ROW_ACTIONS.CONTENT',
        enableBackdrop: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.table.action.deviceprofile.configure',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.CONFIGURE_ACTION.TITLE',
        content:
          'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.CONFIGURE_ACTION.CONTENT',
        action: 'click',
      },
      {
        anchorId: 'help.anchor.config.name',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.NAME.TITLE',
        content: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.NAME.CONTENT',
        action: 'click',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.config.parts',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.PARTS_MENU.TITLE',
        content: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.PARTS_MENU.CONTENT',
        enableBackdrop: true,
        preventScrolling: true,
      },
      {
        anchorId: 'help.anchor.config.part',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.PART.TITLE',
        content: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.PART.CONTENT',
        enableBackdrop: true,
        preventScrolling: true,
      },
      {
        anchorId: 'help.anchor.config.description',
        title: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.DESCRIPTION.TITLE',
        content: 'TOUR.DEVICE_PROFILES.CONFIGURE_PROFILE.DESCRIPTION.CONTENT',
        enableBackdrop: true,
      },
    ],
  },
  {
    name: 'TOUR.UPDATE.ADD_UPDATE.TITLE',
    locations: ['update'],
    icon: 'add',
    steps: [
      {
        anchorId: 'help.anchor.update.add',
        title: 'TOUR.UPDATE.ADD_UPDATE.ADD_BUTTON.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.ADD_BUTTON.CONTENT',
        enableBackdrop: true,
        action: 'click',
      },
      {
        anchorId: 'help.anchor.update.add.group',
        title: 'TOUR.UPDATE.ADD_UPDATE.GROUP.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.GROUP.CONTENT',
        isSubStep: true,
      },
      {
        anchorId: 'help.anchor.update.add.imageversion',
        title: 'TOUR.UPDATE.ADD_UPDATE.IMAGE_VERSION.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.IMAGE_VERSION.CONTENT',
      },
      {
        anchorId: 'help.anchor.update.add.downloadtime',
        title: 'TOUR.UPDATE.ADD_UPDATE.DOWNLOAD_TIME.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.DOWNLOAD_TIME.CONTENT',
      },
      {
        anchorId: 'help.anchor.update.add.installtime',
        title: 'TOUR.UPDATE.ADD_UPDATE.INSTALL_TIME.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.INSTALL_TIME.CONTENT',
      },
      {
        anchorId: 'help.anchor.update.add.maxdownloads',
        title: 'TOUR.UPDATE.ADD_UPDATE.MAX_DOWNLOADS.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.MAX_DOWNLOADS.CONTENT',
      },
      {
        anchorId: 'dialog.anchor.action',
        title: 'TOUR.UPDATE.ADD_UPDATE.SAVE.TITLE',
        content: 'TOUR.UPDATE.ADD_UPDATE.SAVE.CONTENT',
      },
    ],
  },
];
