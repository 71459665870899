import { MenuActionTypes, MenuActionUnion } from '../actions/menu.action';

export interface State {
  update?: Date;
}

export const initialState: State = {
  update: undefined,
};

export function reducer(state = initialState, action: MenuActionUnion): State {
  switch (action.type) {
    case MenuActionTypes.UpdateMenu: {
      return {
        ...state,
        update: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
export const getUpdate = (state: State) => {
  return state?.update;
};
