<ol class="breadcrumb">
  <li
    class="flex items-center"
    *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last"
  >
    <a class="!no-underline" [routerLink]="[breadcrumb.url]">
      <span *ngIf="!breadcrumb.icon" class="">{{
        breadcrumb.label | translate
      }}</span>
      <span>
        <mat-icon class="mt-2" *ngIf="breadcrumb.icon">{{
          breadcrumb.icon
        }}</mat-icon>
      </span>
    </a>
    <mat-icon *ngIf="!last">keyboard_arrow_right</mat-icon>
  </li>
</ol>
