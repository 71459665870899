<ng-container *ngIf="displayText; else noText">
  <ng-container [ngSwitch]="button.iconType">
    <ng-container *ngSwitchCase="'svg'">
      <ng-container
        *ngIf="
          (type === 'header' && button.headerIconEval) ||
            (type !== 'header' && button.iconEval);
          then arrayIcon;
          else simpleIcon
        "
      ></ng-container>
      <ng-template #simpleIcon>
        <svg-icon
          class="mat-icon material-icons mat-icon-no-color"
          [src]="getIconString()"
        ></svg-icon>
      </ng-template>

      <ng-template #arrayIcon>
        <svg-icon
          class="mat-icon material-icons mat-icon-no-color"
          [src]="button.icon[evalIcon()]"
        ></svg-icon>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container
        *ngIf="
          (type === 'header' && button.headerIconEval) ||
            (type !== 'header' && button.iconEval);
          then arrayIcon;
          else simpleIcon
        "
      ></ng-container>
      <ng-template #simpleIcon>
        <mat-icon> {{ button.icon }} </mat-icon>
      </ng-template>

      <ng-template #arrayIcon>
        <mat-icon>{{ button.icon[evalIcon()] }}</mat-icon>
      </ng-template>
    </ng-container>
    <span *ngIf="type !== 'header' && button.toolTipEval">
      {{ button.toolTipEval(evalItem) | translate }}</span
    >
    <span *ngIf="type === 'header' && button.headerToolTipEval">
      {{ button.headerToolTipEval(evalItem) | translate }}</span
    >
    <span
      *ngIf="type === 'header' && button.headerToolTip && !button.toolTipEval"
    >
      {{ button.headerToolTip | translate }}
    </span>
    <span
      *ngIf="
        button.buttonText &&
        (!button.headerToolTip || type !== 'header') &&
        !button.toolTipEval
      "
    >
      {{ button.buttonText | translate }}
    </span>
    <span
      *ngIf="
        button.toolTip &&
        !button.buttonText &&
        (!button.headerToolTip || type !== 'header') &&
        !button.toolTipEval
      "
    >
      {{ button.toolTip | translate }}
    </span>
  </ng-container>
</ng-container>

<ng-template #noText>
  <span class="iconButton">
    <ng-container [ngSwitch]="button.iconType">
      <ng-container *ngSwitchCase="'svg'">
        <ng-container
          *ngIf="
            (type === 'header' && button.headerIconEval) ||
              (type !== 'header' && button.iconEval);
            then arrayIcon;
            else simpleIcon
          "
        ></ng-container>
        <ng-template #simpleIcon>
          <svg-icon
            class="mat-icon material-icons mat-icon-no-color"
            [src]="getIconString()"
          ></svg-icon>
        </ng-template>

        <ng-template #arrayIcon>
          <svg-icon
            class="mat-icon material-icons mat-icon-no-color"
            [src]="button.icon[evalIcon()]"
          ></svg-icon>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container
          *ngIf="
            (type === 'header' && button.headerIconEval) ||
              (type !== 'header' && button.iconEval);
            then arrayIcon;
            else simpleIcon
          "
        ></ng-container>
        <ng-template #simpleIcon>
          <mat-icon> {{ button.icon }} </mat-icon>
        </ng-template>

        <ng-template #arrayIcon>
          <mat-icon>{{ button.icon[evalIcon()] }}</mat-icon>
        </ng-template>
      </ng-container>
    </ng-container>
  </span>
</ng-template>
