import { NgIf } from '@angular/common';
import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthGuardService } from '../../../services/authGuard/authGuard.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'hasRole, [hasRole]',
})
export class HasRoleDirective implements OnDestroy {
  private _roles: string[];
  @Input()
  public set hasRole(roles: string[] | undefined) {
    if (!roles) {
      roles = ['none'];
    }
    this._roles = roles;
    this.setNgIf();
  }

  private rolesChangedSubscriptions: Subscription;
  private ngIfDirective: NgIf;

  constructor(
    private el: ElementRef,
    private authGuard: AuthGuardService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    if (!this.ngIfDirective) {
      this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    }
    this.rolesChangedSubscriptions = this.authGuard
      .rolesChanged()
      .subscribe((newRoles: string[]) => {
        this.setNgIf();
      });
  }

  ngOnDestroy() {
    if (
      this.rolesChangedSubscriptions &&
      !this.rolesChangedSubscriptions.closed
    ) {
      this.rolesChangedSubscriptions.unsubscribe();
    }
  }

  setNgIf() {
    this.ngIfDirective.ngIf = this.authGuard.hasRoles(this._roles);
  }
}
