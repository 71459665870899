export type EnvironmentType = 'local' | 'develop' | 'test' | 'prod';

export interface EnvironmentConfig {
  apiURL: string;
  authURL: string;
  production: boolean;
  system: EnvironmentType;
  newRelic?: ReturnType<typeof createNewRelicConfig>;
  clientId?: string;
  authRealmPath?: string;
}

export interface NewRelicConfig {
  applicationID: string;
  agentID: string;
  licenseKey: string;
  accountID: string;
  trustKey: string;
}

export function createNewRelicConfig(props: Partial<NewRelicConfig>) {
  const defaultProps = {
    licenseKey: 'NRJS-7012fd8734e159526d3',
    accountID: '4015233',
    trustKey: '4015233',
  };
  const mergedProps = { ...defaultProps, ...props };

  return {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.eu01.nr-data.net'] },
    },
    info: {
      beacon: 'bam.eu01.nr-data.net',
      errorBeacon: 'bam.eu01.nr-data.net',
      licenseKey: mergedProps.licenseKey,
      applicationID: mergedProps.applicationID,
      sa: 1,
    },
    loader_config: { ...mergedProps },
  };
}

export function createEnvironment(config: EnvironmentConfig) {
  const apiURL = new URL(config.apiURL);
  const authURL = new URL(config.authURL);

  const rootURL = `${apiURL.origin}/api/v1`;
  const rootURLv2 = `${apiURL.origin}/api/v2`;
  const websocket_broker = `${apiURL.origin}/ws/broker`;

  return {
    production: config.production,
    system: config.system,
    newRelic: config.newRelic,
    apiUrl: apiURL.origin,
    authUrl: authURL.origin,
    clientId: config.clientId || 'device-management-fe',
    authEndpoint: `${authURL.origin}${config.authRealmPath || '/auth/realms/device-management'}`,
    realmEndpoint: `${authURL.origin}${config.authRealmPath || '/auth/admin/realms/device-management'}`,
    langEndpoint: './assets/i18n/',
    versionFile: `${apiURL.origin}/assets/version.json`,
    apiVersion: 'v2',
    asset_management: {
      root: rootURL,
      devices: '/devices',
      device_types: '/device-types',
      device_families: '/device-types/families',
      device_groups: '/device-groups',
      customers: '/customers',
      distributors: '/distributors',
      meta_data: '/meta-data/asset',
      logcat: '/logcat',
      daily_journal: '/dailyjournal',
      monitoring: '/monitoring',
      download: '/download',
      restart: '/restart',
      websocket_broker,
    },
    customer_data_service: {
      root: rootURL,
      customers: '/customers',
      distributors: '/distributors',
      users: '/users',
      bridges: '/bridges',
    },
    location_management: {
      root: rootURL,
      locations: '/ports',
      locations_groups: '/port-groups',
      customers: '/customers',
      meta_data: '/meta-data/location',
    },
    configuration_management: {
      root: rootURL,
      configs: '/configs',
      configs_clone: '/configs/clone',
      configs_schema: '/configs/schema',
      config_parts: '/configParts',
      customer: '/customer',
      schemas: '/schemas',
      config_part_types: '/configPartTypes',
      schema: '/schema',
      sessions: '/sessions',
      sessions_config: '/sessions/config',
      operators: '/operators',
      criteria: '/criterias',
      config_rules: '/rules/config',
      rules: '/rules',
      devices: '/devices',
      reinstall: '/reinstall',
      websocket_broker,
      rootV2: rootURLv2,
      patient_profiles: '/patientprofiles',
      profile_templates: '/profiletemplates',
      profiles: '/profiles',
      properties: '/properties',
      import: '/import',
      clone: '/clone',
      fixParentNodes: '/fixParentNodes',
      batch: '/batch',
    },
    update_management: {
      root: rootURL,
      updates: '/update-sessions',
      os_versions: '/osversions',
      release: '/release',
      releases: '/releases',
      device_images: '/device-images',
      websocket_broker,
      customer_releases: '/releases/customer',
      devices: '/devices',
      reinstall: '/reinstall',
    },
    license_management: {
      root: rootURL,
      models: '/models',
      customer_licenses: '/models/customer',
      customer_features: '/features/active',
      features: '/features',
      licenses: '/licenses',
      categories: '/categories',
    },
    audit: {
      root: rootURL,
      history: '/history',
    },
    webapp: {
      root: rootURL,
      webapps: '/webapps',
    },
    file_schema: {
      root: rootURL,
      file_schema: '/file-schema',
    },
    statistics: {
      root: rootURL,
      device_stats: '/statistics/device',
    },
    notification: {
      root: rootURL,
      notification: '/notification',
      send: '/send',
      device_group: '/device-group',
      location_group: '/location-group',
    },
    infrastructure: {
      root: rootURL,
      bridges: '/monitoring/infrastructure/bridges',
    },
    admin: {
      root: rootURL,
      admin: '/admin',
      restart: '/restart',
      health: '/health',
      frontend: '/frontend',
      notification: '/notifications',
      read: '/read',
      pin: '/pin',
    },
    announcement: {
      root: rootURL,
      announcement: '/announcement',
    },
    management_overview: {
      root: rootURL,
      management_overview: '/admin/management-overview',
    },
    certificate_management: {
      root: rootURL,
      certificate_management: '/certificate-management',
      import: '/import',
    },
  };
}
