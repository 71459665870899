import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BewatecDialogModule } from '../dialog/bewatec-dialog.module';
import { NewsBarComponent } from './components/bewatec-news-bar/news-bar/news-bar.component';
import { NewsBarService } from './components/bewatec-news-bar/service/news-bar.service';
import { BewatecSnackbarComponent } from './components/bewatec-snackbar/bewatec-snackbar.component';
import { NotificationDialogComponent } from './components/dialog/notification.dialog';
import { NotificationComponent } from './components/notification.component';
import { NotificationsMenuComponent } from './components/notifications-menu/notifications-menu.component';
import { NotificationsConfig } from './notifications.config';
import {
  NotificationModuleState,
  reducers as NotificationReducers,
  featureName,
} from './reducers/module';
import { NotificationsLibService } from './services/notifications.service';

export const NOTIFICATION_REDUCER_TOKEN: InjectionToken<
  ActionReducerMap<NotificationModuleState>
> = new InjectionToken<ActionReducerMap<NotificationModuleState>>(
  'Notification Reducers',
);

export function getReducers(): ActionReducerMap<NotificationModuleState> {
  return NotificationReducers;
}

export function createTranslateLoader(
  http: HttpClient,
  notificationsConfig: NotificationsConfig,
) {
  return new TranslateHttpLoader(
    http,
    notificationsConfig.langEndpoint,
    '.json',
  );
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    BewatecDialogModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forFeature(featureName, NOTIFICATION_REDUCER_TOKEN),
  ],
  declarations: [
    NotificationDialogComponent,
    NotificationComponent,
    BewatecSnackbarComponent,
    NewsBarComponent,
    NotificationsMenuComponent,
  ],
  exports: [NotificationComponent],
  providers: [],
})
export class NotificationsModule {
  public static forRoot(
    config: NotificationsConfig,
  ): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [
        {
          provide: NotificationsConfig,
          useValue: config,
        },
        {
          provide: NOTIFICATION_REDUCER_TOKEN,
          useFactory: getReducers,
        },
        NewsBarService,
        NotificationsLibService,
      ],
    };
  }
}
