import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Injector,
  Input,
  ViewContainerRef,
} from '@angular/core';
import { TourState } from '../tour-core/tour.service';
import { NgxmTourService } from './ngx-md-menu-tour.service';
import {
  INgxmStepOption,
  INgxmStepOption as IStepOption,
} from './step-option.interface';
import { TourAnchorMatMenuDirective } from './tour-anchor.directive';
import { TourBackdropService } from './tour-backdrop.service';
import { TourStepTemplateService } from './tour-step-template.service';

@Directive({
  selector: '[bewaTourAnchor]',
})
export class BewaAnchorDirective extends TourAnchorMatMenuDirective {
  @Input()
  public set bewaTourAnchor(anchor: string) {
    this.tourAnchor = anchor;
  }
  private _currentStep: INgxmStepOption;
  private _element: ElementRef;
  private _componentFactoryResolver: ComponentFactoryResolver;
  private _injector: Injector;
  private _viewContainer: ViewContainerRef;
  private _tourService: NgxmTourService;
  private _tourStepTemplate: TourStepTemplateService;
  private _tourBackdrop: TourBackdropService;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector,
    viewContainer: ViewContainerRef,
    element: ElementRef,
    tourService: NgxmTourService,
    tourStepTemplate: TourStepTemplateService,
    tourBackdrop: TourBackdropService,
  ) {
    super(
      componentFactoryResolver,
      injector,
      viewContainer,
      element,
      tourService,
      tourStepTemplate,
      tourBackdrop,
    );

    this._componentFactoryResolver = componentFactoryResolver;
    this._injector = injector;
    this._viewContainer = viewContainer;
    this._tourService = tourService;
    this._tourStepTemplate = tourStepTemplate;
    this._tourBackdrop = tourBackdrop;
    this._element = element;
  }

  public override showTourStep(step: IStepOption): void {
    this._currentStep = step;
    super.showTourStep(step);
  }

  public override hideTourStep(): void {
    super.hideTourStep();
    if (
      (this._tourService.getStatus() !== TourState.OFF ||
        this._currentStep.hidden === true) &&
      this._currentStep &&
      this._currentStep.action
    ) {
      if (this._currentStep.action === 'click') {
        const inputEl = this._element.nativeElement.querySelector('input');
        if (inputEl && inputEl.click) {
          inputEl.click();
        } else {
          this._element.nativeElement.click();
        }
      }
    }
  }
}
