import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '../../../utils/utils.service';

@Component({
  selector: 'bewa-bewatec-dialog',
  templateUrl: './bewatec-dialog.component.html',
  styleUrls: ['./bewatec-dialog.component.scss'],
})
export class BewatecDialogComponent implements OnInit {
  @Input()
  dialogIcon: string;

  @Input()
  showButtons = true;

  @Input()
  dialogIconColor: string;

  @Input()
  dialogTitle: string;

  @Input()
  dialogTitleExtra: string;

  @Input()
  cancelButtonName: string;

  @Input()
  actionButtonName: string;

  @Input()
  actionButtonDisabled: boolean;

  @Input()
  showCancelButton = true;

  @Input()
  processingAction = false;

  @Input()
  showRequiredHint = false;

  @Input()
  customCancel = false;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  _iconColor = false;

  constructor(
    private dialogRef: MatDialogRef<BewatecDialogComponent>,
    private utils: UtilsService,
  ) {}

  ngOnInit() {
    if (this.utils.mediaBreakpointDown('md', window.innerWidth)) {
      this.dialogRef.updateSize('100%', '80%');
      this.dialogRef.addPanelClass('full-width');
    }
  }

  getCancelButtonName(): string {
    if (this.cancelButtonName === undefined) {
      return 'BUTTONS.CANCEL';
    } else {
      return this.cancelButtonName;
    }
  }

  getActionButtonName(): string {
    if (this.actionButtonName === undefined) {
      return 'BUTTONS.ADD';
    } else {
      return this.actionButtonName;
    }
  }

  isIconColor(): boolean {
    if (this.dialogIconColor !== undefined) {
      return (this._iconColor = true);
    } else {
      return this._iconColor;
    }
  }

  isActionButtonDisabled(): boolean {
    if (this.actionButtonDisabled === undefined) {
      return false;
    } else {
      return true;
    }
  }

  onActionButton(event: any) {
    this.processingAction = true;
    this.action.emit({ action: 'ok', event });
  }

  dialogCancel(event: any) {
    if (this.customCancel) {
      this.action.emit({ action: 'close', event });
    } else {
      this.dialogRef.close();
    }
  }

  isSmallResolution() {
    return this.utils.mediaBreakpointDown('md', window.innerWidth);
  }
}
