<ng-container *ngIf="evalBreakpoints()">
  <div *hasRole="neededRoles" class="menu-item-wrapper">
    <ng-container *ngIf="!license || hasLicense(license)">
      <ng-container
        *ngIf="!link && !action; then nonInteractiveItem; else interactiveItem"
      ></ng-container>
    </ng-container>

    <ng-template #nonInteractiveItem>
      <div>
        <mat-expansion-panel [expanded]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="menu-item-content-wrapper">
                <div class="icon-wrapper">
                  <mat-icon>{{ icon }}</mat-icon>
                </div>
                {{ title | translate }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="menu-sub-item"
            *ngFor="let subItem of subItems; let last = last; let index = index"
            [class.last-item]="last"
          >
            <bewa-menu-item
              [menuCategory]="subItem"
              (highlightedChange)="
                highlights = { highlighted: $event, index: index }
              "
            ></bewa-menu-item>
          </div>
        </mat-expansion-panel>
      </div>
    </ng-template>

    <ng-template #interactiveItem>
      <ng-container *ngIf="link; then linkItem; else actionItem"></ng-container>
    </ng-template>

    <ng-template #actionItem>
      <button
        (click)="dispatchAction(action)"
        [color]="color"
        mat-flat-button
        class="menu-item-button"
        [ngClass]="{ highlighted: highlighted }"
      >
        <div class="menu-item-content-wrapper">
          <div class="icon-wrapper">
            <mat-icon>{{ icon }}</mat-icon>
          </div>
          {{ title | translate }}
        </div>
      </button>
    </ng-template>

    <ng-template #linkItem>
      <ng-container
        *ngIf="isRouterLink; then routerLink; else href"
      ></ng-container>
    </ng-template>

    <ng-template #routerLink>
      <a
        [routerLink]="link"
        mat-flat-button
        [color]="color"
        class="menu-item-button"
        [ngClass]="{ highlighted: highlighted }"
      >
        <div
          class="menu-item-content-wrapper d-flex justify-content-between align-items-center"
        >
          <div class="icon-wrapper">
            <mat-icon>{{ icon }}</mat-icon>
          </div>
          {{ title | translate }}
        </div>
      </a>
    </ng-template>

    <ng-template #href>
      <a
        [href]="link"
        mat-flat-button
        target="_blank"
        [color]="color"
        class="menu-item-button"
      >
        <div class="menu-item-content-wrapper">
          <div class="icon-wrapper">
            <mat-icon>{{ icon }}</mat-icon>
          </div>
          {{ title | translate }}
        </div>
      </a>
    </ng-template>
  </div>
</ng-container>
