import {
  NotificationActionTypes,
  NotificationActionUnion,
} from '../actions/notification.action';
import {
  INotification,
  IPopupNotification,
} from '../interfaces/notification.interface';

export interface State {
  popupNotification: IPopupNotification[];
  notificationList: INotification[];
  newNotifications: boolean;
  readNotification: INotification;
  pinnedNotification: INotification;
}

export const initialState: State = {
  popupNotification: [],
  notificationList: [],
  newNotifications: false,
  readNotification: null!,
  pinnedNotification: null!,
};

export function reducer(
  state = initialState,
  action: NotificationActionUnion,
): State {
  switch (action.type) {
    case NotificationActionTypes.Notification: {
      return {
        ...state,
        popupNotification: state.popupNotification.concat(action.notification),
      };
    }
    case NotificationActionTypes.PopupNotificationRead: {
      return {
        ...state,
        popupNotification: state.popupNotification.filter(
          (el) => el.timestamp !== action.notification.timestamp,
        ),
      };
    }
    case NotificationActionTypes.NotificationList: {
      return {
        ...state,
        notificationList: action.notifications,
        newNotifications: action.newNotifications,
      };
    }
    case NotificationActionTypes.NotificationRead: {
      return {
        ...state,
        readNotification: action.notification,
      };
    }
    case NotificationActionTypes.NotificationPinned: {
      return {
        ...state,
        pinnedNotification: action.notification,
      };
    }
    default: {
      return state;
    }
  }
}

export const getPopupNotifications = (state: State) => {
  return state?.popupNotification;
};

export const getNotificationList = (state: State) => {
  return {
    notifications: state?.notificationList || initialState.notificationList,
    newNotifications: state?.newNotifications || initialState.newNotifications,
  };
};

export const getReadNotification = (state: State) => {
  return state?.readNotification;
};

export const getPinnedNotification = (state: State) => {
  return state?.pinnedNotification;
};
