<div class="grid-container">
  <mat-grid-list style="position: static" cols="12" rowHeight="50px">
    <ng-container *ngFor="let card of dashboardCards">
      <mat-grid-tile [colspan]="getCols(card)" [rowspan]="getRows(card)">
        <bewa-card-selector
          [id]="card.cardTitle"
          [card]="card"
        ></bewa-card-selector>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
