import { Action } from '@ngrx/store';

export enum SelectionActionTypes {
  UpdateCustomer = '[Selection] UpdateCustomer',
}

export class UpdateSelectedCustomer implements Action {
  readonly type = SelectionActionTypes.UpdateCustomer;

  constructor(public newCustomer: object) {}
}

export type SelectionActionsUnion = UpdateSelectedCustomer;
