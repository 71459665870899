<!-- Handle branch node menu items -->
<span *ngIf="button.buttons && button.buttons.length > 0; else menuButton">
  <button
    mat-menu-item
    bewaTourAnchor="{{ button.helpAnchor }}"
    *hasRole="button.roles"
    (click)="buttonPress(button)"
    [devId]="button.internalName"
    [matMenuTriggerFor]="menu.childMenu"
  >
    <bewa-button-content
      [button]="button"
      [evalItem]="tableItem"
    ></bewa-button-content>
  </button>
  <bewa-menu
    #menu
    [buttons]="button.buttons"
    [tableItem]="tableItem"
    (action)="buttonPress($event)"
  ></bewa-menu>
</span>
<!-- Handle leaf node menu items -->
<ng-template #menuButton>
  <button
    mat-menu-item
    bewaTourAnchor="{{ button.helpAnchor }}"
    *hasRole="button.roles"
    (click)="buttonPress(button)"
    [devId]="button.internalName"
  >
    <bewa-button-content
      [button]="button"
      [evalItem]="tableItem"
    ></bewa-button-content>
  </button>
</ng-template>
