import { Injectable } from '@angular/core';
enum Breakpoint {
  xs = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  mediaBreakpointBetween(lowerSize: string, upperSize: string, width: number) {
    let rtn = false;

    if (
      width > Breakpoint[lowerSize as keyof typeof Breakpoint] &&
      width < Breakpoint[upperSize as keyof typeof Breakpoint]
    ) {
      rtn = true;
    }

    return rtn;
  }

  mediaBreakpointUp(size: string, width: number) {
    let rtn = false;
    switch (size) {
      case 'xs':
        if (width > Breakpoint.xs) {
          rtn = true;
        }
        break;
      case 'sm':
        if (width > Breakpoint.sm) {
          rtn = true;
        }
        break;
      case 'md':
        if (width > Breakpoint.md) {
          rtn = true;
        }
        break;
      case 'lg':
        if (width > Breakpoint.lg) {
          rtn = true;
        }
        break;
      case 'xl':
        if (width > Breakpoint.xl) {
          rtn = true;
        }
        break;

      default:
        rtn = false;
        break;
    }
    return rtn;
  }

  mediaBreakpointDown(size: string, width: number) {
    let rtn = false;
    switch (size) {
      case 'xs':
        if (width < Breakpoint.sm) {
          rtn = true;
        }
        break;
      case 'sm':
        if (width < Breakpoint.md) {
          rtn = true;
        }
        break;
      case 'md':
        if (width < Breakpoint.lg) {
          rtn = true;
        }
        break;
      case 'lg':
        if (width < Breakpoint.xl) {
          rtn = true;
        }
        break;
      case 'xl':
        if (width >= Breakpoint.xl) {
          rtn = true;
        }
        break;

      default:
        rtn = false;
        break;
    }
    return rtn;
  }
}
