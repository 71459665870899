<ng-container *hasRole="layout.roles">
  <ng-container *ngIf="value">
    <ng-container [ngSwitch]="value.type">
      <!-- SVG Icon  -->
      <svg-icon
        *ngSwitchCase="'svg'"
        [src]="value.icon"
        [matTooltip]="value.tooltip | translate"
      ></svg-icon>

      <!-- Spinner  -->
      <mat-spinner
        *ngSwitchCase="'spinner'"
        class="spinner-padding"
        [diameter]="24"
        [color]="value.color"
        [ngStyle]="{ color: value.color }"
        [matTooltip]="value.tooltip | translate"
      ></mat-spinner>

      <!-- Normal Mat Icon  -->
      <mat-icon
        *ngSwitchDefault
        [color]="value.color"
        [ngStyle]="{
          color: value.color,
          cursor: layout.onClick ? 'pointer' : 'default',
        }"
        [matTooltip]="value.tooltip | translate"
      >
        {{ value.icon }}
      </mat-icon>
    </ng-container>
  </ng-container>
</ng-container>
