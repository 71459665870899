import { NgModule } from '@angular/core';
import { DevIdDirective } from './directives/devId.directive';
import { HasRoleDirective } from './directives/hasRole.directive';
import { BewaSortPipe } from './directives/sort.directive';
import { BewaAnchorDirective } from '../../external-modules/tour-md-menu/bewa-anchor.directive';
import { TourMatMenuModule } from '../../external-modules/tour-md-menu/md-menu.module';

@NgModule({
  imports: [TourMatMenuModule],
  declarations: [DevIdDirective, HasRoleDirective, BewaSortPipe],
  exports: [
    DevIdDirective,
    HasRoleDirective,
    BewaAnchorDirective,
    BewaSortPipe,
  ],
  providers: [],
})
export class ShareModule {}
