<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-card-header">
    <mat-card-title class="card-title">
      {{ card.cardTitle | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <p *ngIf="card?.data?.infoText">
      {{ card.data.infoText! | translate }}
    </p>
    <bewa-bewatec-table-v2
      *ngIf="card.data.tableData && card.data.tableData.length > 0"
      [columns]="card.data.availableColumns"
      [config]="card.data.tableConfig"
      [data]="card.data.tableData"
      (action)="onTableAction($event)"
    >
    </bewa-bewatec-table-v2>
  </mat-card-content>
</mat-card>
