<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-card-header">
    <mat-card-title class="card-title">
      {{ card.cardTitle | translate }}
    </mat-card-title>
  </mat-card-header>
  <!--<mat-card-content class="dashboard-card-content">
    <bewa-bewatec-table-v2
      [columns]="card.data.availableColumns"
      [config]="card.data.tableConfig"
      [data]="card.data.tableData"
      (action)="onTableAction($event)">
    </bewa-bewatec-table-v2>
  </mat-card-content> -->
  <mat-card-content class="dashboard-card-content">
    <mat-tab-group *ngIf="card" class="tab-table">
      <mat-tab
        [label]="table.tabTitle | translate"
        *ngFor="let table of card.data"
      >
        <bewa-bewatec-table-v2
          [columns]="table.availableColumns"
          [config]="table.tableConfig"
          [data]="table.tableData"
          (action)="onTableAction($event)"
        >
        </bewa-bewatec-table-v2>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
