import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  NotificationType,
  PopupNotificationReadAction,
} from '../actions/notification.action';
import { IPopupNotification } from '../interfaces/notification.interface';
import { getPopupNotificationsSelect } from '../reducers/module';
import { NewsBarRef } from './bewatec-news-bar/news-bar.ref';
import { NewsBarService } from './bewatec-news-bar/service/news-bar.service';
import { BewatecSnackbarComponent } from './bewatec-snackbar/bewatec-snackbar.component';
import { NotificationDialogComponent } from './dialog/notification.dialog';

@Component({
  selector: 'bewa-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  private notification$: Observable<IPopupNotification[]>;

  private newsBarRef: NewsBarRef;

  constructor(
    private store: Store<any>,
    public errorSnackbar: MatSnackBar,
    public dialog: MatDialog,
    private newsBar: NewsBarService,
  ) {}

  ngOnInit(): void {
    this.notification$ = this.store.select(getPopupNotificationsSelect);
    this.notification$.subscribe((popupNotifications) => {
      if (popupNotifications && popupNotifications.length > 0) {
        this.processNotification(popupNotifications[0]);
        if (
          popupNotifications[0].notificationType === NotificationType.NewsBar
        ) {
          if (
            popupNotifications.some(
              (el) => el.notificationType !== NotificationType.NewsBar,
            )
          ) {
            const notification = popupNotifications.find(
              (el) => el.notificationType !== NotificationType.NewsBar,
            );
            this.processNotification(notification!);
          }

          if (this.newsBarRef) {
            this.newsBar.updateNewsBar(
              'otherNotifications',
              popupNotifications.filter(
                (el) =>
                  el.notificationType ===
                    popupNotifications[0].notificationType &&
                  el.timestamp !== popupNotifications[0].timestamp,
              ).length,
            );
          }
        }
      }
    });
  }

  private processNotification(notification: IPopupNotification) {
    switch (notification.notificationType) {
      case NotificationType.SnackbarDone: {
        this.showSnackBar(notification);
        break;
      }
      case NotificationType.SnackbarInfo: {
        this.showSnackBar(notification);
        break;
      }
      case NotificationType.SnackbarError: {
        this.showSnackBar(notification);
        break;
      }
      case NotificationType.PopUp: {
        this.showPopUp(notification);
        break;
      }
      case NotificationType.SimpleNote: {
        this.showSimpleNote(notification);
        break;
      }
      case NotificationType.NewsBar: {
        this.showNewsBar(notification);
        break;
      }
      default: {
        // log('Unknown notification type');
      }
    }
  }

  showSimpleNote(notification: IPopupNotification) {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      disableClose: true,
      width: '20%',
      data: {
        notificationMessage: notification.message,
        confirmable: false,
        simple: true,
      },
      closeOnNavigation: false,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(new PopupNotificationReadAction(notification));
    });
  }

  showPopUp(notification: IPopupNotification) {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      disableClose: true,
      width: '20%',
      data: {
        notificationMessage: notification.message,
        confirmable: notification.confirmable,
        simple: false,
      },
      closeOnNavigation: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        notification.callback?.();
      }

      this.store.dispatch(new PopupNotificationReadAction(notification));
    });
  }

  showSnackBar(notification: IPopupNotification) {
    if (!this.errorSnackbar._openedSnackBarRef) {
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.horizontalPosition = 'center';

      let msg;
      if (notification.message instanceof Array) {
        msg = notification.message[0];
      } else {
        msg = notification.message;
      }

      if (notification.notificationType === NotificationType.SnackbarDone) {
        config.duration = 4000;
      }
      if (notification.notificationType === NotificationType.SnackbarInfo) {
        config.duration = 5000;
      }
      config.data = {
        type: notification.notificationType,
        message: msg,
        snackbar: this.errorSnackbar,
        timestamp: notification.timestamp,
      };

      const snackbarRef = this.errorSnackbar.openFromComponent(
        BewatecSnackbarComponent,
        config,
      );
      snackbarRef.afterDismissed().subscribe(() => {
        this.store.dispatch(new PopupNotificationReadAction(notification));
      });
    }
  }

  showNewsBar(notification: IPopupNotification) {
    if (!this.newsBarRef) {
      const newsBarConfig = Object.assign({}, notification.newsBarConfig);
      if (!notification.newsBarConfig?.data.infoText && notification.message) {
        newsBarConfig.data = {
          ...newsBarConfig.data,
          infoText: notification.message,
        };
      }
      this.newsBarRef = this.newsBar.open(newsBarConfig);

      const sub = this.newsBarRef.afterClosed.subscribe((result) => {
        notification.callback?.();
        sub.unsubscribe();
        this.newsBarRef = undefined!;

        this.store.dispatch(new PopupNotificationReadAction(notification));
      });
    }
  }
}
