import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getAttribute' })
export class GetAttributePipe implements PipeTransform {
  transform(item: object, attribute: string): any {
    if (
      item === null ||
      attribute === null ||
      item === undefined ||
      attribute === undefined
    ) {
      return;
    } else {
      const splitted: string[] = attribute.split('.');
      let finalAttribute = item;
      splitted.forEach((part) => {
        if (finalAttribute !== undefined && finalAttribute !== null) {
          finalAttribute = (finalAttribute as any)[part];
        }
      });
      return finalAttribute;
    }
  }
}
