import { ReceivedHeaderEvent } from '@/app/external-modules/header/actions/header';
import { EventType } from '@/app/external-modules/header/models/header-event';
import {
  getHeaderEventSelect,
  getLoggedInSelect,
} from '@/app/external-modules/header/reducers/module';
import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { UtilsService } from '@/app/external-modules/utils/utils.service';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, of } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';
import { IMenuCategory } from '../../interfaces/menucategory.interface';
import { LoginService } from '../../services/login/login.service';
import { MenuService } from '../../services/menu/menu.service';

@Component({
  selector: 'bewa-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
@Subscriptions()
export class MainNavigationComponent implements OnInit {
  @Input()
  set top(top: number) {
    this._top = top;
  }

  @ViewChild('mainNavButton') mainNavButton: ElementRef;
  @ViewChild(MatSidenavContainer, { static: true })
  sidenavContainer: MatSidenavContainer;

  private menuOpen: boolean;
  menuCategories: IMenuCategory[];
  userLoggedIn = false;
  private _top: number;
  private _innerHeight: number;
  @Unsubscribe()
  private headerEventSubscription: Subscription;
  @Unsubscribe()
  private loggedInSubscription: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._innerHeight = window.innerHeight;
  }

  constructor(
    private router: Router,
    private store: Store<any>,
    private menuService: MenuService,
    private loginService: LoginService,
    private utils: UtilsService,
  ) {
    this.isMenuOpen = false;
    this.top = 0;
    this.headerEventSubscription = this.store
      .select(getHeaderEventSelect)
      .subscribe((headerEvent) => {
        if (headerEvent) {
          if (headerEvent.eventType === EventType.Menu) {
            this.onClickMenuButton();
            this.store.dispatch(new ReceivedHeaderEvent());
          }
        }
      });

    this.loggedInSubscription = this.store
      .select(getLoggedInSelect)
      .pipe(
        filter((loggedIn) => loggedIn !== null || loggedIn !== undefined),
        concatMap((loggedIn) => {
          if (loggedIn) {
            this.userLoggedIn = true;
            return this.menuService.getMenu(this.loginService.roles);
          } else {
            this.userLoggedIn = false;
            return of(undefined);
          }
        }),
      )
      .subscribe((menu) => {
        this.menuCategories = menu;
      });
  }

  ngOnInit(): void {
    this._innerHeight = window.innerHeight;
  }

  onClickMenuItem(index: number) {
    this.sidenavContainer.close();
    const selectedItem: IMenuCategory = this.menuCategories[index];
    this.router.navigate([selectedItem.link]);
  }

  onClickMenuButton() {
    if (this.menuOpen) {
      this.sidenavContainer.close();
    } else {
      this.sidenavContainer.open();
    }
  }

  getMenuMode() {
    if (this.utils.mediaBreakpointDown('md', window.innerWidth)) {
      return 'over';
    } else {
      return 'side';
    }
  }

  set isMenuOpen(event) {
    this.menuOpen = event;
  }
  get isMenuOpen() {
    return this.menuOpen;
  }

  get height() {
    if (this.utils.mediaBreakpointDown('md', window.innerWidth)) {
      return this._innerHeight;
    } else {
      return this._innerHeight - this._top;
    }
  }

  get top() {
    return 0;
  }
}
