import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AbstractService } from '../abstract/abstract.service';
import { IFileSchema } from './../../interfaces/file-schema.interface';

@Injectable({
  providedIn: 'root',
})
export class FileSchemaService extends AbstractService {
  getFileSchema(id: string) {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.file_schema.root +
          environment.file_schema.file_schema +
          '/' +
          id,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  updateFileSchema(fileSchema: IFileSchema) {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .put(
        environment.file_schema.root +
          environment.file_schema.file_schema +
          '/' +
          fileSchema.id,
        fileSchema,
        options,
      )
      .pipe(catchError(this.handleError));
  }
}
