import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NotificationAction,
  NotificationType,
} from '../../external-modules/notifications/actions/notification.action';

@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    private http: HttpClient,
    private store: Store<any>,
  ) {}

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes (1800000ms)
   */
  public initVersionCheck(url: string, frequency: number = 1800000) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime()).subscribe(
      (response: any) => {
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);

        // If new version, do something
        if (hashChanged) {
          this.store.dispatch(
            new NotificationAction(
              'NOTIFICATIONS.ERROR_SAVING_PROFILE',
              NotificationType.NewsBar,
              true,
              () => {
                location.reload();
              },
              {
                data: {
                  infoText: 'NOTIFICATIONS.NEW_CLIENT_VERSION',
                  buttonText: 'BUTTONS.REFRESH',
                  buttonIcon: 'refresh',
                },
              },
            ),
          );
        }
      },
      (err) => {
        // this.store.dispatch(new NotificationAction('NOTIFICATIONS.LOADING_VERSION_ERROR', NotificationType.SnackbarError));
      },
    );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
