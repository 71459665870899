import { Action } from '@ngrx/store';

export enum BackgroundActionTypes {
  UpdateBackground = '[ Background ] Update background',
}

export class UpdateBackground implements Action {
  readonly type = BackgroundActionTypes.UpdateBackground;

  constructor(public payload?: string) {}
}

export type BackgroundActionUnion = UpdateBackground;
