import { Action } from '@ngrx/store';
import { ILanguage } from '../models/language';

export enum LanguageActionTypes {
  UpdateAvailableLanguages = '[Language] Update Available Languages',
  UpdateActiveLanguage = '[Language] Update Active Language',
}

export class UpdateAvailableLanguages implements Action {
  readonly type = LanguageActionTypes.UpdateAvailableLanguages;

  constructor(public payload: ILanguage[]) {}
}

export class UpdateActiveLanguage implements Action {
  readonly type = LanguageActionTypes.UpdateActiveLanguage;

  constructor(public payload: ILanguage) {}
}

export type LanguageActionsUnion =
  | UpdateAvailableLanguages
  | UpdateActiveLanguage;
