<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-title">
    <mat-card-title class="card-title">
      {{ card.cardTitle | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <div class="row content-row" content>
      <div #containerRef class="dashboard-chart chart-col col-7">
        <ngx-charts-pie-chart
          [view]="[containerWidth, containerWidth]"
          [results]="pieData"
          [legend]="false"
          [customColors]="pieColor"
          [animations]="true"
        >
        </ngx-charts-pie-chart>
      </div>
      <ngx-charts-legend
        class="legend-col col-5 chart-legend"
        [data]="chartLabels"
        [colors]="colors"
        (labelClick)="onLabelClick($event)"
        [activeEntries]="pieData"
      >
      </ngx-charts-legend>
    </div>
  </mat-card-content>
</mat-card>
