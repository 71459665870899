import { IButton } from '@/app/interfaces/button.interface';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'bewa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() buttons: IButton[];
  @Input() tableItem: any;
  @ViewChild('childMenu', { static: true }) public childMenu: any;

  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() {}

  buttonPress(button: any) {
    this.action.emit(button);
  }
}
