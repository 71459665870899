import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import {
  INotification,
  IPopupNotification,
} from '../interfaces/notification.interface';
import * as fromNotification from './notification.reducer';

export const featureName = 'notificationsModule';
export interface NotificationModuleState {
  notification: fromNotification.State;
}

export const reducers: ActionReducerMap<NotificationModuleState> = {
  notification: fromNotification?.reducer as any,
};

export const getNotificationManagementState: MemoizedSelector<
  object,
  NotificationModuleState
> = createFeatureSelector<NotificationModuleState>(featureName);

export const getNotificationState: MemoizedSelector<
  object,
  fromNotification.State
> = createSelector(
  getNotificationManagementState,
  (state: NotificationModuleState) => state?.notification,
);

export const getPopupNotificationsSelect: MemoizedSelector<
  object,
  IPopupNotification[]
> = createSelector(
  getNotificationState,
  fromNotification.getPopupNotifications,
);

export const getNotificationListSelect: MemoizedSelector<
  object,
  { notifications: INotification[]; newNotifications: boolean }
> = createSelector(getNotificationState, fromNotification.getNotificationList);

export const getReadNotificationSelect: MemoizedSelector<
  object,
  INotification
> = createSelector(getNotificationState, fromNotification.getReadNotification);

export const getPinnedNotificationSelect: MemoizedSelector<
  object,
  INotification
> = createSelector(
  getNotificationState,
  fromNotification.getPinnedNotification,
);
