import { Component, HostBinding } from '@angular/core';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';
import { IStringLayout } from './../../../../table/interfaces/layout.interface';

@Component({
  selector: 'bewa-string-column-type',
  templateUrl: './string-column-type.component.html',
  styleUrls: ['./string-column-type.component.scss'],
})
export class StringColumnTypeComponent extends ColumnTypeAbstractComponent<IStringLayout> {
  @HostBinding('id') get id() {
    return this.layout.id;
  }
  constructor() {
    super();
  }

  get style() {
    return this.layout.style || 'normal';
  }
}
