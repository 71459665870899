import {
  LicenseActionTypes,
  LicenseActionUnion,
} from '../actions/license.action';

export interface State {
  licenses: number[];
}

export const initialState: State = {
  licenses: [],
};

export function reducer(
  state = initialState,
  action: LicenseActionUnion,
): State {
  switch (action.type) {
    case LicenseActionTypes.UpdateLicense: {
      return {
        ...state,
        licenses: action.licenses,
      };
    }

    default: {
      return state;
    }
  }
}
export const getLicenses = (state: State) => {
  return state?.licenses;
};
