import { Injectable, OnDestroy } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Observable, Subscription, interval } from 'rxjs';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import { environment } from '@/environments/environment';
import { Logger } from '../../external-modules/utils/logger/logger.service';

@Injectable()
export class WebSocketAPIService implements OnDestroy {
  webSocketEndPoint = environment.update_management.websocket_broker;

  stompClient?: Stomp.Client;
  token: string;
  renewSubscription: Subscription;

  constructor(
    private oauthService: OAuthService,
    private logger: Logger,
  ) {
    this.token = this.oauthService.getAccessToken();
    this.renewSubscription = this.oauthService.events.subscribe(
      (event: OAuthEvent) => {
        if (event.type === 'token_received') {
          this.token = this.oauthService.getAccessToken();
          if (this.webSocketEndPoint && this.webSocketEndPoint !== '') {
            this._connect(this.token);
          }
        }
      },
    );
    if (this.webSocketEndPoint && this.webSocketEndPoint !== '') {
      this._connect(this.token);
    }
  }

  ngOnDestroy(): void {
    this.renewSubscription.unsubscribe();
    this._disconnect();
  }

  _connect(token: string) {
    const ws = new SockJS(this.webSocketEndPoint + '?access_token=' + token);
    this.stompClient = Stomp.over(ws);
    this.stompClient.connect(
      {},
      (frame) => {
        this.logger.log('WebSocketAPIService', 'WS Connceted');
      },
      (error) => {
        // On error schedule reconnect
        setTimeout(() => {
          this._connect(this.token);
        }, 5000);
      },
    );
    this.stompClient.debug = function (event) {
      // this.logger.log('WebSocketAPIService', event);
    };
    // this.stompClient.reconnect_delay = 5000;
  }

  _disconnect() {
    if (this.renewSubscription) {
      this.renewSubscription.unsubscribe();
    }

    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.disconnect(() => {
        this.logger.log('WebSocketAPIService', 'WS Disconnected');
      });
    }
  }

  /**
   * Send message to sever via web socket
   * @param {*} message
   */
  send(message: any[], topic: string) {
    if (this.stompClient?.connected) {
      this.stompClient.send(topic, {}, JSON.stringify(message));
    } else {
      this.logger.log(
        'WebSocketAPIService',
        'Ws not fully connected. Schedule message send.',
      );
      const reTrySub = interval(1000).subscribe(() => {
        this.logger.log('WebSocketAPIService', 'Retry ws send');
        if (this.stompClient?.connected) {
          reTrySub.unsubscribe();
          this.stompClient.send(topic, {}, JSON.stringify(message));
        }
      });
    }
  }

  subscribe(topic: string): Observable<any> {
    return new Observable((observer) => {
      if (this.stompClient?.connected) {
        this.stompClient.subscribe(topic, (result) => {
          observer.next(result);
        });
      } else {
        this.logger.log(
          'WebSocketAPIService',
          'Ws not fully connected. Schedule subscription.',
        );
        const reTrySub = interval(1000).subscribe(() => {
          this.logger.log('WebSocketAPIService', 'Retry ws subscription');
          if (this.stompClient?.connected) {
            reTrySub.unsubscribe();
            this.stompClient.subscribe(topic, (result) => {
              observer.next(result);
            });
          }
        });
      }
    });
  }

  makeSubscriptionId(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
