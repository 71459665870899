import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
@Subscriptions()
export class MatPaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;
  override getRangeLabel = this.paginatorRangeLable;

  private langChangeSubscription: Subscription;

  setPaginatorSettings() {
    this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST');
    this.lastPageLabel = this.translate.instant('PAGINATOR.LAST');
    this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT');
    this.previousPageLabel = this.translate.instant('PAGINATOR.PREV');
    this.changes.next();
  }

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.setPaginatorSettings();
    });
    this.setPaginatorSettings();
  }

  paginatorRangeLable(page: number, pageSize: number, length: number) {
    const rangeLabelWord = this.translate.instant('PAGINATOR.RANGE_LABEL');
    if (length === 0 || pageSize === 0) {
      return '' + 0 + ' ' + rangeLabelWord + ' ' + length;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return (
      '' +
      (startIndex + 1) +
      ' - ' +
      endIndex +
      ' ' +
      rangeLabelWord +
      ' ' +
      length
    );
  }
}
