import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationType } from '../../actions/notification.action';

@Component({
  selector: 'bewa-bewatec-snackbar',
  templateUrl: './bewatec-snackbar.component.html',
  styleUrls: ['./bewatec-snackbar.component.scss'],
})
export class BewatecSnackbarComponent implements OnInit {
  snackbarMessage: string;
  snackbarRef: MatSnackBar;
  typeError = false;
  typeDone = false;
  typeInfo = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {
    this.snackbarMessage = this.data.message;
    this.snackbarRef = this.data.snackbar;

    switch (this.data.type) {
      case NotificationType.SnackbarError:
        this.typeError = true;
        break;
      case NotificationType.SnackbarDone:
        this.typeDone = true;
        break;
      case NotificationType.SnackbarInfo:
        this.typeInfo = true;
        break;
    }
  }

  close() {
    this.snackbarRef.dismiss();
  }
}
