/* eslint-disable @angular-eslint/directive-selector */
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { withinViewport } from 'withinviewport';
import { TourAnchorDirective } from '../tour-core/tour-anchor.directive';
import { TourState } from '../tour-core/tour.service';
import { NgxmTourService } from './ngx-md-menu-tour.service';
import { INgxmStepOption as IStepOption } from './step-option.interface';
import { TourAnchorOpenerComponent } from './tour-anchor-opener.component';
import { TourBackdropService } from './tour-backdrop.service';
import { TourStepTemplateService } from './tour-step-template.service';

@Directive({
  selector: '[tourAnchor]',
})
export class TourAnchorMatMenuDirective
  implements OnInit, OnDestroy, TourAnchorDirective
{
  @Input() public tourAnchor: string;
  public opener: TourAnchorOpenerComponent;
  public menuCloseSubscription: Subscription;

  @HostBinding('class.touranchor--is-active') public isActive: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private viewContainer: ViewContainerRef,
    private element: ElementRef,
    private tourService: NgxmTourService,
    private tourStepTemplate: TourStepTemplateService,
    private tourBackdrop: TourBackdropService,
  ) {
    this.opener = this.viewContainer.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(
        TourAnchorOpenerComponent,
      ),
    ).instance;
  }

  public ngOnInit(): void {
    this.tourService.register(this.tourAnchor, this);
  }

  public ngOnDestroy(): void {
    this.tourService.unregister(this.tourAnchor);
  }

  public showTourStep(step: IStepOption): void {
    this.isActive = true;
    this.tourStepTemplate.templateComponent.step = step;
    // Ignore step.placement
    if (!step.preventScrolling) {
      if (!withinViewport(this.element.nativeElement, 'bottom')) {
        (this.element.nativeElement as HTMLElement).scrollIntoView(false);
      } else if (
        !withinViewport(this.element.nativeElement, 'left top right')
      ) {
        (this.element.nativeElement as HTMLElement).scrollIntoView(true);
      }
    }

    if (step.hidden === true) {
      if (this.tourService.hasNext(step)) {
        this.tourService.next();
      } else {
        this.tourService.end();
      }
    } else {
      (this.opener.trigger as any)._element = this.element;
      this.opener.trigger.menu =
        this.tourStepTemplate.templateComponent.tourStep;
      this.opener.trigger.ngAfterContentInit();
      this.opener.trigger.openMenu();

      if (step.enableBackdrop) {
        this.tourBackdrop.show(this.element);
      } else {
        this.tourBackdrop.close();
      }

      step.prevBtnTitle = step.prevBtnTitle || 'TOUR.BUTTON.PREV';
      step.nextBtnTitle = step.nextBtnTitle || 'TOUR.BUTTON.NEXT';
      step.endBtnTitle = step.endBtnTitle || 'TOUR.BUTTON.END';

      if (this.menuCloseSubscription) {
        this.menuCloseSubscription.unsubscribe();
      }
      this.menuCloseSubscription = (
        this.opener.trigger.menuClosed as Subject<void>
      )
        .pipe(first())
        .subscribe(() => {
          if (this.tourService.getStatus() !== TourState.OFF) {
            this.tourService.end();
          }
          this.tourBackdrop.close();
        });
    }
  }

  public hideTourStep(): void {
    this.isActive = false;
    if (this.menuCloseSubscription) {
      this.menuCloseSubscription.unsubscribe();
    }
    this.opener.trigger.closeMenu();
    if (this.tourService.getStatus() === TourState.OFF) {
      this.tourBackdrop.close();
    }
  }
}
