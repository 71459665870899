import { ModuleWithProviders, NgModule } from '@angular/core';
import { Logger } from './logger/logger.service';
import { UtilsConfig } from './utils.config';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class UtilsModule {
  public static forRoot(config: UtilsConfig): ModuleWithProviders<UtilsModule> {
    return {
      ngModule: UtilsModule,
      providers: [
        {
          provide: UtilsConfig,
          useValue: config,
        },
        Logger,
      ],
    };
  }
}
