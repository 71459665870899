import { Action } from '@ngrx/store';
import { NewsBarConfig } from '../components/bewatec-news-bar/news-bar.config';
import {
  INotification,
  IPopupNotification,
} from '../interfaces/notification.interface';

export enum NotificationType {
  SnackbarDone = 'SNACKBARDONE',
  SnackbarInfo = 'SNACKBARINFO',
  SnackbarError = 'SNACKBARERROR',
  PopUp = 'POPUP',
  SimpleNote = 'Note',
  NewsBar = 'NEWSBAR',
}

export enum NotificationActionTypes {
  Notification = '[ Notification ] Show Notification',
  NotificationList = '[ Notification ] Set Notification List',
  NotificationRead = '[ Notification ] Set Notification Read',
  NotificationPinned = '[ Notification ] Set Notification Pinned',
  PopupNotificationRead = '[ Notification ] Set Popup Notification Read',
}

export class NotificationAction implements Action {
  readonly type = NotificationActionTypes.Notification;
  notification: IPopupNotification;
  constructor(
    message: string | string[] | IPopupNotification,
    notificationType?: string,
    confirmable?: boolean,
    callback?: () => any,
    newsBarConfig?: NewsBarConfig,
  ) {
    if (typeof message === 'string' || message instanceof Array) {
      this.notification = {
        message,
        notificationType: notificationType!,
        confirmable,
        callback,
        newsBarConfig,
      };
    } else {
      this.notification = message;
    }

    this.notification.timestamp = Date.now();
  }
}

export class PopupNotificationReadAction implements Action {
  readonly type = NotificationActionTypes.PopupNotificationRead;
  constructor(public notification: IPopupNotification) {}
}

export class NotificationListAction implements Action {
  readonly type = NotificationActionTypes.NotificationList;
  constructor(
    public notifications: INotification[],
    public newNotifications: boolean = false,
  ) {}
}
export class NotificationReadAction implements Action {
  readonly type = NotificationActionTypes.NotificationRead;
  constructor(public notification: INotification) {}
}

export class NotificationPinnedAction implements Action {
  readonly type = NotificationActionTypes.NotificationPinned;
  constructor(public notification: INotification) {}
}

export type NotificationActionUnion =
  | NotificationAction
  | NotificationListAction
  | NotificationReadAction
  | NotificationPinnedAction
  | PopupNotificationReadAction;
