import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedCustomerSelect } from './external-modules/header/reducers/module';
import { ICustomer } from './interfaces/customer.interface';
import { LoginService } from './services/login/login.service';

@Injectable()
export class Utils {
  private _selectedCustomer: ICustomer | undefined;

  constructor(
    private loginService: LoginService,
    private store: Store<any>,
  ) {
    this.store.select(getSelectedCustomerSelect).subscribe((newSelection) => {
      if (newSelection) {
        this._selectedCustomer = newSelection as ICustomer;
      }
    });
  }

  isCustomer(): boolean {
    return (
      this.loginService.roles?.includes('Customer') ||
      this.loginService.fakeRoles.includes('Customer')
    );
  }

  isDistributor(): boolean {
    return (
      this.loginService.roles?.includes('Distributor') ||
      this.loginService.fakeRoles.includes('Distributor')
    );
  }

  isBewatecAdmin(): boolean {
    return !!this.loginService.roles?.includes('Bewatec_Admin');
  }

  isSuperAdmin(): boolean {
    return !!this.loginService.roles?.includes('Super_Admin');
  }

  getDistributorId() {
    return this.loginService.identity.distributorId;
  }

  getCustomerId() {
    return this.loginService.identity.customerId;
  }

  private isFakeCustomer() {
    return this.loginService.fakeRoles.includes('Customer');
  }

  getUserName(): string {
    return (
      this.loginService.identity['given_name'] +
      ' ' +
      this.loginService.identity['family_name']
    );
  }

  getSelfasCustomer(): ICustomer {
    let customer: ICustomer = {
      id: this.getCustomerId(),
      name: '',
      testCustomer: false,
      enabled: false,
      distributorId: this.getDistributorId(),
      created: '',
      updated: '',
      deleted: false,
      licenseModelId: 0,
      displayName: '',
    };

    if (this.isFakeCustomer()) {
      customer = this._selectedCustomer!;
    }

    return customer;
  }
}
