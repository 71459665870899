<mat-card class="dashboard-card">
  <mat-card-header class="dashboard-title">
    <mat-card-title class="card-title">
      {{ card.cardTitle | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content">
    <!-- Customer Template -->
    <div id="organisation">{{ card.data.organisationName }}</div>
    <div>{{ card.data.streetAddress }}</div>
    <div>{{ card.data.postalCode }} {{ card.data.city }}</div>
    <!--<div>{{addressCard.countryCode}}</div>-->
    <div class="divider"></div>
    <div *ngIf="card.data?.deNumber">
      {{ "DASHBOARD.ADDRESS_CARD_CUSTOMER.DE_NUMBER" | translate }}:
      {{ card.data.deNumber }}
    </div>
    <div *ngIf="card.data?.distributorName">
      {{ "DASHBOARD.ADDRESS_CARD_CUSTOMER.DISTRIBUTOR_NAME" | translate }}:
      {{ card.data.distributorName }}
    </div>
  </mat-card-content>
</mat-card>
