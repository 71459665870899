<ng-container *hasRole="layout.roles">
  <span
    [ngClass]="{ space: index! >= 1, 'no-margin': index === 0 || !index }"
    [ngStyle]="{ 'font-weight': style }"
    *ngIf="layout.translate; else noTranslate"
    innerHTML="{{ value | translate }}"
  >
  </span>
</ng-container>

<ng-template #noTranslate>
  <span
    [ngClass]="{ space: index! >= 1, 'no-margin': index === 0 || !index }"
    [ngStyle]="{ 'font-weight': style }"
    [innerHTML]="value"
  >
  </span>
</ng-template>
