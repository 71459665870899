/* eslint-disable @typescript-eslint/no-empty-object-type */
import { Observable } from 'rxjs';
import { IColumn } from '../../../../../interfaces/column.interface';
import { TableConfiguration } from '../../table-v2/util/table-configuration/table-configuration';
import { ILayoutV2 } from '../../table/interfaces/layout.interface';

export enum CardType {
  ADDRESS,
  INFO,
  PIE_CHART,
  TABLE,
  BAR_CHART,
  TAB_TABLE,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICardData {}

type numberFunction = () => number;
type rowsFunction = () => number;

export interface IDashboardCard {
  cardId: string;
  cardType: CardType;
  cardTitle: string;
  cols: number | numberFunction;
  rows: number | numberFunction;
  data: ICardData;
  order: number | numberFunction;
  updates?: Observable<any>;
}

export interface IAddressCard extends IDashboardCard {
  cardType: CardType.ADDRESS;
  data: IAddressData;
}

export interface IAddressData extends ICardData {
  organisationName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  countryCode: string;

  /*Only for customer*/
  deNumber?: string;
  distributorName?: string;
}

export interface IPieChartCard extends IDashboardCard {
  cardType: CardType.PIE_CHART;
  data: IChartData;
}

export interface IBarChartCard extends IDashboardCard {
  cardType: CardType.BAR_CHART;
  data: IChartData;
  maxNameLength: number;
  logarithmic: boolean;
}

export interface IChartData extends ICardData {
  chartLabels: string[];
  chartBackgroundColor: string[];
  chartTitle: string;
  chartName: string;
  chartData: number[];
  calcMissingColors: boolean;
}

export interface IInfoCard extends IDashboardCard {
  cardType: CardType.INFO;
  data: IInfoData;
  contentCols?: number;
}

export interface IInfoData extends ICardData {
  infoTitle: string;
  infoIcon: string | string[];
  infoText: string | string[];
}

export interface ITableCard extends IDashboardCard {
  cardType: CardType.TABLE;
  data: ITableData;
}

export interface ITableData extends ICardData {
  availableColumns: IColumn<ILayoutV2>[];
  tableConfig: TableConfiguration;
  tableData: any[];
  infoText?: string;
}

export interface ITabTableCard extends IDashboardCard {
  cardType: CardType.TAB_TABLE;
  data: ITabTableData[];
}

export interface ITabTableData extends ICardData {
  availableColumns: IColumn<ILayoutV2>[];
  tableConfig: TableConfiguration;
  tableData: any[];
  tabTitle: string;
  internalName: string;
}
