export interface ILayout {
  /**
   * SIMPLE, TABLE, STATE, LINK, DATE
   */
  layout_TYPE: string;
  labelName?: string;
  label2?: string;
  valuePath?: string;
  value2?: string;
  toolTip?: string | ((item: any) => string);
  translate?: boolean;
  translatePrefix?: string;
  icon?: string | ((item: any) => string);
  /**
   * Link formatted like
   * /path/to/{variable}/point
   * or
   * /path/to/{var1|var2}/point
   */
  link?: string;
  /**
   * Object of variables
   */
  linkVariable?: any;
  dataFormat?: string;
  listElementLayout?: ILayout;
}

export enum LayoutType {
  STRING,
  DATE,
  LINK,
  ARRAY,
  NUMBER,
  OBJECT,
  ICON,
}

export interface ILayoutV2 {
  type: LayoutType;
  valuePath: string | string[];
  label?: string;
  translate?: boolean;
  id?: string;
  formatter?(value: any): any;
  roles?: string[];
}

export interface IStringLayout extends ILayoutV2 {
  type: LayoutType.STRING;
  style?: string;
  formatter?(value: any): any;
}

export interface ILinkLayout extends ILayoutV2 {
  type: LayoutType.LINK;
  formatter(value: any): {
    link?: string;
    name: string;
    callback?(value: any): void;
  };
}

export interface IIconLayout extends ILayoutV2 {
  type: LayoutType.ICON;
  formatter(value: any): {
    icon: string;
    color?: string;
    tooltip?: string;
    type: string;
    additionalInfo?: string;
    additionalData?: string | string[];
  };
  onClick?(value: any): any;
}

export interface IArrayLayout extends ILayoutV2 {
  type: LayoutType.ARRAY;
  layout: ILayoutV2 | ILayoutV2[];
  formatter(value: any): any;
}

export interface IObjectLayout extends ILayoutV2 {
  type: LayoutType.OBJECT;
  layout: ILayoutV2 | ILayoutV2[];
  formatter(value: any): any;
}
