import { Directive, Input } from '@angular/core';
import { ILayoutV2 } from '../../../table/interfaces/layout.interface';

@Directive()
export abstract class ColumnTypeAbstractComponent<
  L extends ILayoutV2 = ILayoutV2,
> {
  @Input()
  layout: L;

  @Input()
  set value(value) {
    this._value = value;
  }

  @Input()
  index?: number;

  protected _value: any;

  get value() {
    if (this.layout.formatter) {
      return this.layout.formatter(this._value);
    } else {
      return this._value;
    }
  }
}
