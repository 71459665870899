/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Input, isDevMode } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[devId]',
})
export class DevIdDirective {
  @Input()
  public set devId(devId: string) {
    this.idString = devId;
    this.setElementId();
  }

  private idString: string;

  constructor(private el: ElementRef) {}

  setElementId() {
    if (isDevMode()) {
      this.el.nativeElement.id = this.idString;
    }
  }
}
