import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract/abstract.service';

@Injectable()
export class LicenseService extends AbstractService {
  getAllLicenses(): Observable<{ id: number; name: string }[]> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.headers.set('Content-Type', 'application/json');

    return this.httpClient
      .get(
        environment.license_management.root +
          environment.license_management.customer_features,
        options,
      )
      .pipe(catchError(this.handleError));
  }
}
