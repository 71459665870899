/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { IBreadCrumb } from '../../models/breadcrumb.interface';
import { ILanguage } from '../../models/language';
import { getActiveLanguageSelect } from '../../reducers/module';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  activeLanguage$: Observable<ILanguage>;
  source = new Subject<Array<IBreadCrumb>>();
  breadcrumbs$ = this.source.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(distinctUntilChanged())
      .pipe(
        map((event) => {
          return this.buildBreadCrumb(this.activatedRoute.root);
        }),
      )
      .subscribe((result) => {
        this.source.next(result);
      });

    this.activeLanguage$ = this.store.select(getActiveLanguageSelect);
    this.activeLanguage$.subscribe((activeLanguage) => {
      this.source.next(this.buildBreadCrumb(this.activatedRoute));
    });
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<IBreadCrumb> = [],
  ): Array<IBreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    let nextUrl = '';
    let newBreadcrumbs = breadcrumbs;
    if (route.routeConfig) {
      if (route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
        const label = route.routeConfig.data['breadcrumb']['name'];
        const icon = route.routeConfig.data['breadcrumb']['icon'];
        let path = route.routeConfig.path;
        let params: any;
        route.params.subscribe((newParams) => {
          params = newParams;
        });
        if (params === undefined) {
          params = '';
        }

        const splitPath: string[] = path?.split('/') || [];
        splitPath.forEach((pathPart, index) => {
          if (pathPart.charAt(0) === ':') {
            const paramKey = pathPart.substring(1, pathPart.length);

            splitPath[index] = params[paramKey];
          }
        });
        path = splitPath.join('/');
        // In the routeConfig the complete path is not available,
        // so we rebuild it each time
        nextUrl = `${url}/${path}`;
        const breadcrumb = {
          label: label,
          icon: icon,
          url: nextUrl,
        };
        newBreadcrumbs = newBreadcrumbs.concat(breadcrumb);
      } else {
        nextUrl = `${url}/${route.routeConfig.path}`;
      }
    }
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
