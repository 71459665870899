import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ILayoutV2 } from '../../../table/interfaces/layout.interface';
import { ArrayColumnTypeComponent } from '../column-types/array-column-type/array-column-type.component';
import { DateColumnTypeComponent } from '../column-types/date-column-type/date-column-type.component';
import { LinkColumnTypeComponent } from '../column-types/link-column-type/link-column-type.component';
import { StringColumnTypeComponent } from '../column-types/string-column-type/string-column-type.component';
import { LayoutType } from './../../../table/interfaces/layout.interface';
import { GetAttributePipe } from './../../../table/utils/getattribute.pipe.component';
import { ColumnTypeAbstractComponent } from './../../abstract/column-type-abstract/column-type.abstract.component';
import { IconColumnTypeComponent } from './../column-types/icon-column-type/icon-column-type.component';
import { NumberColumnTypeComponent } from './../column-types/number-column-type/number-column-type.component';
import { ObjectColumnTypeComponent } from './../column-types/object-column-type/object-column-type.component';

@Component({
  selector: 'bewa-column-selector',
  styleUrls: ['./column-selector.component.scss'],
  template: '<ng-container #container></ng-container>',
})
export class ColumnSelectorComponent implements OnInit {
  @Input()
  layout: ILayoutV2 | ILayoutV2[];
  @Input()
  table_item: any;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container: ViewContainerRef;

  private _getAttributePipe: GetAttributePipe = new GetAttributePipe();

  constructor() {}

  ngOnInit(): void {
    if (this.layout) {
      this.container.clear();
      if (!(this.layout instanceof Array)) {
        const layout: ILayoutV2 = this.layout;
        if (this.getColumnLayoutClass(layout.type) !== undefined) {
          const viewContainerRef = this.container;
          viewContainerRef.clear();
          const componentRef = this.container.createComponent(
            this.getColumnLayoutClass(layout.type),
          );
          this.setDataOnComponent(componentRef, layout);
        }
      } else {
        this.layout.forEach((layout, index) => {
          if (this.getColumnLayoutClass(layout.type) !== undefined) {
            const componentRef = this.container.createComponent(
              this.getColumnLayoutClass(layout.type),
            );
            this.setDataOnComponent(componentRef, layout);
            componentRef.instance.index = index;
          }
        });
      }
    }
  }

  private setDataOnComponent(
    componentRef: ComponentRef<ColumnTypeAbstractComponent<ILayoutV2>>,
    layout: ILayoutV2,
  ) {
    const valuePath = layout.valuePath;
    if (typeof valuePath === 'string') {
      componentRef.instance.value = this._getAttributePipe.transform(
        this.table_item,
        valuePath,
      );
    } else if (valuePath instanceof Array) {
      let value = {};
      valuePath.forEach((path) => {
        value = this._setValueRecursive(
          path.split('.'),
          value,
          this._getAttributePipe.transform(this.table_item, path),
        );
      });
      componentRef.instance.value = value;
    }
    componentRef.instance.layout = layout;
  }

  getColumnLayoutClass(type: LayoutType): Type<ColumnTypeAbstractComponent> {
    switch (type) {
      case LayoutType.STRING:
        return StringColumnTypeComponent;
      case LayoutType.DATE:
        return DateColumnTypeComponent;
      case LayoutType.LINK:
        return LinkColumnTypeComponent;
      case LayoutType.ARRAY:
        return ArrayColumnTypeComponent;
      case LayoutType.NUMBER:
        return NumberColumnTypeComponent;
      case LayoutType.ICON:
        return IconColumnTypeComponent;
      case LayoutType.OBJECT:
        return ObjectColumnTypeComponent;
      default:
        return StringColumnTypeComponent;
    }
  }

  private _setValueRecursive(keyParts: string[], parent: any, value: any): any {
    if (keyParts.length === 0) {
      parent = value;
      return parent;
    }

    const key = keyParts.shift()!;
    if (typeof parent[key] === 'undefined') {
      parent[key] = {};
    }
    parent[key] = this._setValueRecursive(keyParts, parent[key], value);

    return parent;
  }
}
