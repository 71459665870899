import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { NewsBarConfig } from '../news-bar.config';
import { NewsBarInjector } from '../news-bar.injector';
import { NewsBarRef } from '../news-bar.ref';
import { NewsBarComponent } from '../news-bar/news-bar.component';

@Injectable()
export class NewsBarService {
  newsBar: ComponentRef<NewsBarComponent>;
  private _top: string;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) {}

  set top(top: string) {
    this._top = top;
    if (this.newsBar) {
      const domElem = (this.newsBar.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      domElem.style.setProperty('top', this._top);
    }
  }

  open(config: NewsBarConfig) {
    return this.appendNewsBarToBody(config);
  }

  appendNewsBarToBody(config: NewsBarConfig) {
    // create a map with the config
    const map = new WeakMap();
    map.set(NewsBarConfig, config);

    const ref = new NewsBarRef();
    map.set(NewsBarRef, ref);

    const sub = ref.afterClosed.subscribe((result) => {
      this.close();
      sub.unsubscribe();
    });

    const factory =
      this.factoryResolver.resolveComponentFactory(NewsBarComponent);

    // use our new injector
    const component = factory.create(new NewsBarInjector(this.injector, map));

    component.instance.color = config.data.color;

    this.appRef.attachView(component.hostView);
    const domElem = (component.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    domElem.style.setProperty('position', 'absolute');
    domElem.style.setProperty('display', 'block');
    // domElem.style.setProperty('z-index', '1');
    domElem.style.setProperty('width', '100%');
    domElem.style.setProperty('top', this._top);
    domElem.style.setProperty('box-shadow', '-1px 1px 2px rgba(0, 0, 0, 0.5)');

    document.body.appendChild(domElem);
    this.newsBar = component;
    return ref;
  }

  updateNewsBar(key: string, value: any) {
    if (this.newsBar) {
      (this.newsBar.instance as any)[key] = value;
    }
  }

  close() {
    this.appRef.detachView(this.newsBar.hostView);
    this.newsBar.destroy();
  }
}
