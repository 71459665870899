import { Component, HostBinding, HostListener } from '@angular/core';
import { IIconLayout } from '../../../../table/interfaces/layout.interface';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';

@Component({
  selector: 'bewa-icon-column-type',
  templateUrl: './icon-column-type.component.html',
  styleUrls: ['./icon-column-type.component.scss'],
})
export class IconColumnTypeComponent extends ColumnTypeAbstractComponent<IIconLayout> {
  @HostBinding('id') get id() {
    return this.layout.id;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.layout.onClick) {
      event.stopPropagation();
      this.layout.onClick(this._value);
    }
  }

  constructor() {
    super();
  }
}
