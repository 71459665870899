import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { INotification } from '../../external-modules/notifications/interfaces/notification.interface';
import { AbstractService } from '../abstract/abstract.service';

@Injectable()
export class NotificationsService extends AbstractService {
  loadNotifications(locale: string): Observable<INotification[]> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);
    options.params = new HttpParams().set('locale', locale);

    return this.httpClient
      .get(
        environment.admin.root +
          environment.admin.frontend +
          environment.admin.notification,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  readNotification(notification: INotification): Observable<void> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .put(
        environment.admin.root +
          environment.admin.frontend +
          environment.admin.notification +
          '/' +
          notification.id +
          environment.admin.read,
        notification,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  pinNotification(notification: INotification): Observable<void> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .put(
        environment.admin.root +
          environment.admin.frontend +
          environment.admin.notification +
          '/' +
          notification.id +
          environment.admin.pin,
        notification,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  unpinNotification(notification: INotification): Observable<void> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .delete(
        environment.admin.root +
          environment.admin.frontend +
          environment.admin.notification +
          '/' +
          notification.id +
          environment.admin.pin,
        options,
      )
      .pipe(catchError(this.handleError));
  }
}
