import { LayoutModule } from '@angular/cdk/layout';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../../../../../environments/environment';
import { BewatecLibraryModule } from '../../bewatec-library.module';
import { ShareModule } from '../../share.module';
import { BewatecTableV2Module } from '../table-v2/table-v2.module';
import { BewatecDashboardComponent } from './bewatec-dashboard.component';
import { CardSelectorComponent } from './components/card-selector/card-selector.component';
import { AddressCardComponent } from './components/card-types/address-card/address-card.component';
import { BarChartCardComponent } from './components/card-types/bar-chart-card/bar-chart-card.component';
import { InfoCardComponent } from './components/card-types/info-card/info-card.component';
import { PieChartCardComponent } from './components/card-types/pie-chart-card/pie-chart-card.component';
import { TabTableCardComponent } from './components/card-types/tab-table-card/tab-table-card.component';
import { TableCardComponent } from './components/card-types/table-card/table-card.component';

export function createMultiTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: environment.langEndpoint + 'bewatec-library/', suffix: '.json' },
    { prefix: environment.langEndpoint, suffix: '.json' },
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    CdkTreeModule,
    MatTooltipModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatIconModule,
    RouterModule,
    AngularSvgIconModule,
    NgxChartsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createMultiTranslateLoader,
        deps: [HttpBackend],
      },
      isolate: true,
    }),
    MatGridListModule,
    LayoutModule,
    BewatecTableV2Module,
    BewatecLibraryModule,
    ShareModule,
  ],
  declarations: [
    BewatecDashboardComponent,
    AddressCardComponent,
    CardSelectorComponent,
    InfoCardComponent,
    PieChartCardComponent,
    TableCardComponent,
    BarChartCardComponent,
    TabTableCardComponent,
  ],
  exports: [BewatecDashboardComponent],
})
export class BewatecDashboardModule {}
