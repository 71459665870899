import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { TourModule, TourService } from '../tour-core/tour.module';
import { BewaAnchorDirective } from './bewa-anchor.directive';
import { NgxmTourService } from './ngx-md-menu-tour.service';
import { TourAnchorOpenerComponent } from './tour-anchor-opener.component';
import { TourAnchorMatMenuDirective } from './tour-anchor.directive';
import { TourBackdropService } from './tour-backdrop.service';
import { TourStepTemplateComponent } from './tour-step-template.component';
import { TourStepTemplateService } from './tour-step-template.service';

@NgModule({
  declarations: [
    TourAnchorMatMenuDirective,
    TourStepTemplateComponent,
    TourAnchorOpenerComponent,
    BewaAnchorDirective,
  ],
  exports: [
    TourAnchorMatMenuDirective,
    TourStepTemplateComponent,
    TourModule,
    BewaAnchorDirective,
  ],
  imports: [
    CommonModule,
    TourModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class TourMatMenuModule {
  public static forRoot(): ModuleWithProviders<TourMatMenuModule> {
    return {
      ngModule: TourMatMenuModule,
      providers: [
        TourStepTemplateService,
        TourBackdropService,
        TourService,
        NgxmTourService,
      ],
    };
  }
}
