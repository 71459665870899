import { Component, HostBinding } from '@angular/core';
import { IArrayLayout } from '../../../../table/interfaces/layout.interface';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';

@Component({
  selector: 'bewa-array-column-type',
  templateUrl: './array-column-type.component.html',
  styleUrls: ['./array-column-type.component.scss'],
})
export class ArrayColumnTypeComponent extends ColumnTypeAbstractComponent<IArrayLayout> {
  @HostBinding('id') get id() {
    return this.layout.id;
  }
  constructor() {
    super();
  }
}
