import { SelectionModel } from '@angular/cdk/collections';
import {
  CdkDragDrop,
  CdkDragEnter,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatChipEvent } from '@angular/material/chips';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { NgxMasonryComponent } from 'ngx-masonry';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TranslateLoadingBaseComponent } from '../../../../components/translate-loading-base/translate-loading-base.component';
import {
  NotificationAction,
  NotificationType,
} from '../../../../external-modules/notifications/actions/notification.action';
import { Subscriptions } from '../../../../external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '../../../../external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { UtilsService } from '../../../../external-modules/utils/utils.service';
import { IColumn } from '../../../../interfaces/column.interface';
import {
  IFilterConfig,
  IFilterValue,
  IMultiFilterConfig,
  IMultiFilterValue,
} from '../../../../interfaces/filter-config.interface';
import { ITableFilter } from '../../../../interfaces/table-filter.interface';
import { AuthGuardService } from '../../../../services/authGuard/authGuard.service';
import { ILayoutV2 } from '../table/interfaces/layout.interface';
import { GetAttributePipe } from '../table/utils/getattribute.pipe.component';
import { IButton } from './../../../../interfaces/button.interface';
import { IColumnGroup } from './../../../../interfaces/column-group.interface';
import { TableConfiguration } from './util/table-configuration/table-configuration';

function isColumnGroup(
  object: IColumn<ILayoutV2>[] | IColumnGroup<ILayoutV2>[],
): object is IColumnGroup<ILayoutV2>[] {
  if (object && object.length !== 0) {
    return (
      object && (object[0] as IColumnGroup).discriminator === 'IColumnGroup'
    );
  }
  return false;
}

export enum TableActionType {
  PAGE,
  BUTTON,
  SEARCH,
  FIELD_CLICK,
  RELOAD,
  FILTER,

  SELECTION,
  SORT,
  LOADED,
  HEADER_BUTTON,
  FILTER_BUTTON,
}

export const SortingDirections = {
  NONE: 'none',
  ASC: 'asc',
  DESC: 'desc',
};

@Component({
  selector: 'bewa-bewatec-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@Subscriptions()
export class TableV2Component
  extends TranslateLoadingBaseComponent
  implements OnInit
{
  @ViewChild('chipListInput') chipListInput: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(NgxMasonryComponent) masonryGrid: NgxMasonryComponent;
  @ViewChild('tableColumns') columnMenu: ComponentRef<MatMenu>;
  @ViewChild(MatMenuTrigger) columnMenuTrigger: MatMenuTrigger;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input()
  set data(data: any[]) {
    this.checkAndResetSelection(data);

    if (data && data.length !== 0) {
      this._data = JSON.parse(JSON.stringify(data));
      if (!this._dataSource) {
        this._dataSource = new MatTableDataSource<any[]>(data);
      } else {
        this._dataSource.data = data;
        this._dataSource._updateChangeSubscription();
        this.addDetailRow();
      }
    } else {
      this._data = [];
      // this._data.push({ noData: true });
      this._dataSource = new MatTableDataSource<any[]>(this._data);
      this._dataSource._updateChangeSubscription();
    }

    this.addProgressBarRow();
  }

  @Input()
  set columns(columns: IColumn<ILayoutV2>[] | IColumnGroup<ILayoutV2>[]) {
    if (isColumnGroup(columns)) {
      this._columnGroups = columns;
      this._selectableColumnGroups = columns.filter((group) => !group.hidden);
    } else {
      this._columnGroups = [
        {
          columns: columns,
          displayName: '',
          discriminator: 'IColumnGroup',
        },
      ];
      this._selectableColumnGroups = this._columnGroups;
    }
  }

  @Input()
  set filter(filter: string[]) {
    this.addMultipleFilters(filter);
  }

  @Input()
  set config(config: TableConfiguration) {
    this._config = config;
  }

  @Input()
  @Output()
  action: BehaviorSubject<{ type: TableActionType; data?: any }> =
    new BehaviorSubject<{ type: TableActionType; data?: any }>({} as any);
  @ViewChild(MatMenuTrigger, { static: true })
  private _contextMenu: MatMenuTrigger;
  @Unsubscribe()
  private rolesChangeSubscription: Subscription;
  @Unsubscribe()
  private _columnSelectionControl$: Subscription;
  @Unsubscribe()
  private removeFilterActionSubscription: Subscription;
  @Unsubscribe()
  private addFilterActionSubscription: Subscription;
  @Unsubscribe()
  private addMultipleFiltersActionSubscription: Subscription;

  private _dataSource: MatTableDataSource<any>;
  private _data: any[];
  private _columnGroups: IColumnGroup<ILayoutV2>[];
  private _selectableColumnGroups: IColumnGroup<ILayoutV2>[];
  private _columns: IColumn<ILayoutV2>[];
  private _config: TableConfiguration;
  private _tableFilters: ITableFilter[] = [];
  private _separatorKeysCodes = [ENTER, COMMA, TAB];
  private _page = 0;
  private _pageSize: number;
  private _displayedColumns: string[] = [];
  private _dragDropEnteredContainer?: string;
  private _detailElement: any;
  private _nonDisplayedColumns: string[];
  private _getAttributePipe: GetAttributePipe = new GetAttributePipe();
  private _headerButtons: IButton[];
  private _buttons: IButton[];
  private _columnMenuOpen: boolean;
  private _loading: boolean;
  private _columnSelectionChanged = false;
  private _newSelectedColumns: string[] = [];

  chipListControl: UntypedFormControl = new UntypedFormControl();
  columnSelectionControl: UntypedFormControl = new UntypedFormControl();
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  contextMenuPosition = { x: '0px', y: '0px' };
  selectColumnGroup: UntypedFormGroup = new UntypedFormGroup({});

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.columnMenu && this.columnMenu.destroy && !this._columnMenuOpen) {
      this.columnMenu.destroy();
    }
  }

  constructor(
    private cookieService: CookieService,
    injector: Injector,
    private authGuard: AuthGuardService,
    private utils: UtilsService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this._tableFilters = this._config.defaultTableFilters;
    this._columnGroups.forEach((group) => {
      group.columns.forEach((column) => {
        this.selectColumnGroup.addControl(
          column.internalName,
          new UntypedFormControl(null),
        );
      });
    });
    this.initPage();
    this.rolesChangeSubscription = this.authGuard
      .rolesChanged()
      .subscribe((newRoles) => {
        this.initDisplayedColumns();
        this.initButtons();
      });
    this.action.next({ type: TableActionType.LOADED });
  }

  initButtons() {
    if (this._config.buttons) {
      this._buttons = this.filterButtons(this._config.buttons);

      this._headerButtons = this._config.buttons.filter((button) => {
        if (button.roles) {
          return button.isHeaderButton && this.authGuard.hasRoles(button.roles);
        } else {
          return button.isHeaderButton;
        }
      });
    }
  }

  filterButtons(buttons: any[]): IButton[] {
    const filteredButtons = buttons.filter(
      (button: { roles: string[]; type: string }) => {
        if (button.roles) {
          if (!button.type || button.type === 'BUTTON') {
            return this.authGuard.hasRoles(button.roles);
          }
          // else {
          // }
        } else {
          return true;
        }
        return false;
      },
    );

    const menus = filteredButtons.filter((button) => {
      return button.type === 'SUB_MENU';
    });

    menus.forEach((menu) => {
      menu.buttons = this.filterButtons(menu.buttons);
    });

    return filteredButtons;
  }

  initDisplayedColumns(ignoreCookie: boolean = false) {
    this._columnGroups = this._columnGroups.map((group) => {
      group.columns = group.columns.filter((column) => {
        if (column.roles) {
          return this.authGuard.hasRoles(column.roles);
        } else {
          return true;
        }
      });
      return group;
    });

    this._columns = this._columnGroups
      .map((group) => {
        return group.columns;
      })
      .reduce((prev, current) => prev.concat(...current));

    if (
      ignoreCookie ||
      !this.cookieColumns ||
      this.cookieColumns.length === 0 ||
      this.cookieColumnsAreInvalid ||
      !this._config.persistColumns
    ) {
      const defaultColumns = Object.assign([], this._config.defaultColumns);
      this.displayedColumns = defaultColumns.filter((defCol) =>
        this._columns.some((col) => col.internalName === defCol),
      );
    } else {
      this.displayedColumns = JSON.parse(JSON.stringify(this.cookieColumns));
    }

    if (this._config.persistColumns) {
      this.cookieColumns = this._displayedColumns;
    }
    this.initColumnSelection();

    if (!this._displayedColumns.includes('extraButtons')) {
      this._displayedColumns.push('extraButtons');
    }

    if (this.selectable && !this._displayedColumns.includes('select')) {
      this._displayedColumns.unshift('select');
    }
  }

  initColumnSelection() {
    this._columnGroups.forEach((group) => {
      group.columns.forEach((column) => {
        if (this.selectColumnGroup.contains(column.internalName)) {
          this.selectColumnGroup.controls[column.internalName].reset(
            this.displayedColumns.includes(column.internalName),
          );
        } else {
          this.selectColumnGroup.addControl(
            column.internalName,
            new UntypedFormControl(
              this.displayedColumns.includes(column.internalName),
            ),
          );
        }
      });
    });
    if (
      !this._columnSelectionControl$ ||
      this._columnSelectionControl$.closed
    ) {
      this._columnSelectionControl$ =
        this.selectColumnGroup.valueChanges.subscribe(
          (values: { [key: string]: boolean }) => {
            const selectedColumns = [];
            for (const key in values) {
              if (values[key]) {
                selectedColumns.push(key);
                this._columnSelectionChanged = true;
              }
            }
            this._newSelectedColumns = selectedColumns;
          },
        );
    }
  }

  initPage() {
    if (this.cookiePageSize) {
      this._pageSize = this.cookiePageSize;
    } else {
      this._pageSize = this._config.defaultPageSize;
    }

    this.pageChange({
      previousPageIndex: undefined,
      pageIndex: this._page,
      pageSize: this._pageSize,
      length: this._config.totalElements,
    });
  }

  private set cookieColumns(columns: string[]) {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    this.cookieService.set(
      this._config.componentId + '.columns',
      JSON.stringify(columns),
      expirationDate,
      '/',
      undefined,
      false,
      'Strict',
    );
  }

  private set cookiePageSize(size: number) {
    if (size) {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      this.cookieService.set(
        this._config.componentId + '.pageSize',
        size.toString(),
        expirationDate,
        '/',
        undefined,
        false,
        'Strict',
      );
    }
  }

  public refresh(newElement: any, compareField: string) {
    const oldIndex = this._dataSource.data.findIndex((oldElement) => {
      return oldElement[compareField] === newElement[compareField];
    });
    this._dataSource.data[oldIndex] = newElement;
    this.checkAndResetSelection([newElement]);
    this.table.renderRows();
  }

  private checkAndResetSelection(data: any[]) {
    if (this._config && this._config.rowCompare) {
      this.selection.selected.forEach((selectedRow) => {
        if (data.some((el) => this._config.rowCompare(el, selectedRow))) {
          const newRow = data.find((el) =>
            this._config.rowCompare(el, selectedRow),
          );
          this.selection.deselect(selectedRow);
          if (newRow) {
            this.selection.select(newRow);
          }
        } else {
          this.selection.deselect(selectedRow);
        }
      });
    } else {
      this.selection.clear();
    }
  }

  public refreshMultipleElements(newElements: any[], compareField: string) {
    newElements.forEach((newElement) => {
      const oldIndex = this._dataSource.data.findIndex((oldElement) => {
        return oldElement[compareField] === newElement[compareField];
      });
      this._dataSource.data[oldIndex] = newElement;
    });
    this.checkAndResetSelection(this._dataSource.data);
    this.table.renderRows();
  }

  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
    this._nonDisplayedColumns = this._columns
      .filter((col) => !this._displayedColumns.includes(col.internalName))
      .map((col) => col.internalName);
  }

  get columnSelectionChanged() {
    return this._columnSelectionChanged;
  }

  get columnGroups() {
    return this._columnGroups || [];
  }

  get selectableColumnGroups() {
    return this._selectableColumnGroups || [];
  }

  get data() {
    return this._data;
  }

  get dataSource() {
    return this._dataSource;
  }

  get chips() {
    const chips: string[] = [];
    this._tableFilters.forEach((filter) => {
      chips.push(filter.displayName + filter.operator + filter.filterValue);
    });
    return chips;
  }

  get searchBar() {
    return this._config.showSearchBar;
  }

  get disableSearchBar() {
    return this._config.disableSearchBar;
  }

  get separatorKeysCodes() {
    return this._separatorKeysCodes;
  }

  get selectableColumns() {
    return this._config.selectableColumns;
  }

  get selectable() {
    return this._config.selectable;
  }

  get buttons() {
    return this._buttons;
  }

  get contextMenuButtons() {
    if (
      !this.isMultipleSelected ||
      !this.itemSelected(this._contextMenu.menuData) ||
      !this.anyHeaderButtons
    ) {
      return this.buttons;
    } else if (this.anyHeaderButtons) {
      return this.headerButtons;
    }
    return [];
  }

  get buttonsWidth() {
    return this.buttons ? 40 : 0;
  }

  get displayedColumns() {
    return this._displayedColumns;
  }

  get nonDisplayedColumns() {
    return this._nonDisplayedColumns;
  }

  get reducedColumns(): IColumn<ILayoutV2>[] {
    return this._columns;
  }

  columnFilters(column: string | IFilterConfig | IMultiFilterConfig[]) {
    return column as IMultiFilterConfig[];
  }

  get reloadButton() {
    return this._config.reloadButton;
  }

  get pagination() {
    return this._config.pagination;
  }

  get totalElements() {
    return this._config.totalElements;
  }

  get currentPage() {
    return this._page;
  }

  get pageSize() {
    return this._pageSize;
  }

  get pageSizeOptions() {
    return this._config.pageSizeOptions;
  }

  get isAllSelected() {
    return (
      this.selection.selected.length ===
      this.dataSource.data.filter((row) => !row.hasOwnProperty('detailRow'))
        .length
    );
  }

  get dragDropEnteredContainer() {
    return this._dragDropEnteredContainer;
  }

  private get cookieColumns(): string[] {
    return JSON.parse(
      this.cookieService.get(this._config.componentId + '.columns') || '[]',
    );
  }

  private get cookiePageSize(): number {
    return Number(
      this.cookieService.get(this._config.componentId + '.pageSize'),
    );
  }

  private get cookieColumnsAreInvalid(): boolean {
    let invalid = false;
    this.cookieColumns.forEach((cookieColumn) => {
      if (!this._columns.some((e) => e.internalName === cookieColumn)) {
        invalid = true;
      }
    });
    return invalid;
  }

  get isDefaultColumns() {
    return this.compare(this._config.defaultColumns, this.cookieColumns);
  }

  get isMultipleSelected() {
    return this.selection.selected.length > 1;
  }

  get headerButtons() {
    return this._headerButtons || [];
  }

  get anyHeaderButtons() {
    return this._headerButtons && this._headerButtons.length > 0;
  }

  get columnMenuOpen() {
    return this._columnMenuOpen;
  }

  get columnSortDisabled() {
    return this._config.columnSortDisabled;
  }

  set loading(loading: boolean) {
    this._loading = loading;
    if (this._dataSource) {
      this._dataSource.data = [];
      this._dataSource._updateChangeSubscription();
    }
  }

  get loading() {
    return this._loading;
  }

  get tableFilters() {
    return this._tableFilters;
  }

  columnMenuOpened() {
    this._columnMenuOpen = true;
  }

  columnMenuClosed() {
    this._columnMenuOpen = false;
    this._newSelectedColumns = [];
    this._columnSelectionChanged = false;
    this._columnGroups.forEach((group) => {
      group.columns.forEach((column) => {
        if (this.selectColumnGroup.contains(column.internalName)) {
          this.selectColumnGroup.controls[column.internalName].reset(
            this.displayedColumns.includes(column.internalName),
            {
              emitEvent: false,
            },
          );
        }
      });
    });
  }

  dataEqual(data: any[]) {
    if (this._data === data) {
      return true;
    }
    if (this._data && data) {
      if (this._data.length !== data.length) {
        return false;
      }
      let rtn = true;
      this._data.forEach((el1, index) => {
        const el2 = this._data[index];

        if (!this._config.rowCompare(el1, el2)) {
          rtn = false;
        }
      });
      return rtn;
    }
    return false;
  }

  hasDataToDisplay(element: any, table_item: object) {
    const column: IColumn<ILayoutV2> = this.getNonDisplayedColumn(element);
    let value: any;
    if (typeof column.layout.valuePath === 'string') {
      value = this._getAttributePipe.transform(
        table_item,
        column.layout.valuePath,
      );
      return !!value;
    } else if (column.layout.valuePath instanceof Array) {
      value = {};
      let allValuesNotEmpty = false;
      column.layout.valuePath.forEach((path) => {
        value[path] = this._getAttributePipe.transform(table_item, path);
        allValuesNotEmpty = allValuesNotEmpty || !!value[path];
      });
      return allValuesNotEmpty;
    }
    return false;
  }

  dataToDisplay(row: any): boolean {
    const unusedColumns = this._columns.filter((col) => {
      let rtn = !this.displayedColumns.includes(col.internalName);
      if (rtn) {
        if (typeof col.layout.valuePath === 'string') {
          const value = this._getAttributePipe.transform(
            row,
            col.layout.valuePath,
          );
          rtn = rtn && value;
          if (rtn && value instanceof Array) {
            rtn = rtn && value.length !== 0;
          }
        } else if (col.layout.valuePath instanceof Array) {
          col.layout.valuePath.forEach((path) => {
            const value = this._getAttributePipe.transform(row, path);
            rtn = rtn && value;
            if (rtn && value instanceof Array) {
              rtn = rtn && value.length !== 0;
            }
          });
        }
      }
      return rtn;
    });

    return unusedColumns.length !== 0;
  }

  compare(array1: string[], array2: string[]) {
    if (array1 === array2) {
      return true;
    }
    if (array1 && array2) {
      if (array1.length !== array2.length) {
        return false;
      }
      let rtn = true;
      array1.forEach((el1, index) => {
        const el2 = array2[index];

        if (el1 !== el2) {
          rtn = false;
        }
      });
      return rtn;
    }
    return false;
  }

  getValuePath(internalName: string) {
    const col = this._columns.find((el) => el.internalName === internalName);
    if (col && col.layout) {
      return col.layout.valuePath;
    }
    return null;
  }

  isExpansionDetailRow(i: number, row: object): boolean {
    return row.hasOwnProperty('detailRow') && !this.loading;
  }

  isTableEmpty() {
    return this._dataSource.data.length === 0;
  }

  isLoading() {
    return this._loading;
  }

  isExpanded(row: any) {
    return this.getExpansionState(row) === 'expanded';
  }

  itemSelected(item: any) {
    return (
      this.selection.selected.findIndex(
        (el) => this._config.rowCompare(el, item) || el === item,
      ) !== -1
    );
  }

  getExpansionState(element: any): 'expanded' | 'collapsed' {
    if (
      this._detailElement &&
      (this._config.rowCompare(element, this._detailElement) ||
        element === this._detailElement) &&
      this.dataToDisplay(element)
    ) {
      return 'expanded';
    }
    return 'collapsed';
  }

  getNonDisplayedColumn(element: string) {
    return this._columns.find((el) => el.internalName === element)!;
  }

  getTooltip(button: IButton, table_item: any): string {
    if (button.toolTipEval && table_item) {
      return button.toolTipEval(table_item);
    } else {
      return button.toolTip!;
    }
  }

  getHeaderTooltip(button: IButton, table_items?: any[]) {
    if (button.headerToolTipEval && table_items) {
      return button.headerToolTipEval(table_items);
    } else {
      return button.headerToolTip;
    }
  }

  removeFilter(event: MatChipEvent, oldFilter: ITableFilter) {
    this._tableFilters.splice(this._tableFilters.indexOf(oldFilter), 1);
    if (this.paginator.pageIndex !== 0) {
      this.removeFilterActionSubscription = this.action
        .pipe(first())
        .subscribe(() => {
          this.action.next({
            type: TableActionType.FILTER,
            data: this._tableFilters.map((filter) => ({
              value: filter.valuePath + filter.operator + filter.filterValue,
              filter,
            })),
          });
        });
    } else {
      this.action.next({
        type: TableActionType.FILTER,
        data: this._tableFilters.map((filter) => ({
          value: filter.valuePath + filter.operator + filter.filterValue,
          filter,
        })),
      });
    }
    if (this.pagination) {
      this.paginator.firstPage();
    }
  }

  isSimpleSearch(column: IColumn<ILayoutV2>) {
    return !(column.filter instanceof Array);
  }

  onSearchMenuClose($event: any) {
    if ($event === 'click') {
      this.chipListInput.nativeElement.focus();
    }
  }

  addFilterString(
    column: IColumn<ILayoutV2>,
    filter?: IMultiFilterConfig,
    value?: IMultiFilterValue,
  ) {
    let displayName: string;
    let operator: string;
    let usedFilter;
    let chipString: string;

    if (column) {
      if (this.searchBar && !this.disableSearchBar) {
        if (!(column.filter instanceof Array)) {
          usedFilter = column.filter;
          displayName = this.translate.instant(column.displayName);
        } else {
          usedFilter = filter;
          displayName =
            this.translate.instant(column.displayName) +
            '.' +
            this.translate.instant(filter!.displayName);
        }
        if ((usedFilter as any)['exactMatch']) {
          operator = '==';
        } else {
          operator = '=';
        }
        chipString = displayName + operator;

        if (value) {
          chipString = chipString + value.internalValue;
        }
        this.chipListControl.reset(chipString);
        this.chipListInput.nativeElement.focus();
      } else {
        this.action.next({
          type: TableActionType.FILTER_BUTTON,
          data: column,
        });
      }
    }
  }

  addFilter(event: { input: any; value: string }) {
    if (event.value) {
      const newFilter = this.chipToFilter(event.value);
      if (newFilter) {
        this._tableFilters.push(newFilter);
        this.chipListControl.reset();
        if (this.paginator.pageIndex !== 0) {
          this.addFilterActionSubscription = this.action
            .pipe(first())
            .subscribe(() => {
              this.action.next({
                type: TableActionType.FILTER,
                data: this._tableFilters.map((filter) => ({
                  value:
                    filter.valuePath + filter.operator + filter.filterValue,
                  filter,
                })),
              });
            });
        } else {
          this.action.next({
            type: TableActionType.FILTER,
            data: this._tableFilters.map((filter) => ({
              value: filter.valuePath + filter.operator + filter.filterValue,
              filter,
            })),
          });
        }
        if (this.pagination) {
          this.paginator.firstPage();
        }
      } else {
        this.chipListControl.setValue('');
        this.store.dispatch(
          new NotificationAction(
            'NOTIFICATIONS.INVALID_FILTER',
            NotificationType.SnackbarError,
          ),
        );
      }
    }
  }

  async addMultipleFilters(event: string[]) {
    await this.translate
      .get('BEWATEC_TABLE.COLUMNS.USER_MANAGEMENT.LAST_SESSION_DURATION')
      .toPromise();
    if (event.length > 0) {
      let filterAdded = false;
      event.forEach((filter) => {
        const newFilter = this.chipToFilter(filter);
        if (newFilter) {
          this._tableFilters.push(newFilter);
          filterAdded = true;
        }
      });
      if (filterAdded) {
        this.chipListControl.reset();
        if (this.paginator.pageIndex !== 0) {
          this.addMultipleFiltersActionSubscription = this.action
            .pipe(first())
            .subscribe(() => {
              this.action.next({
                type: TableActionType.FILTER,
                data: this._tableFilters.map((filter) => ({
                  value:
                    filter.valuePath + filter.operator + filter.filterValue,
                  filter,
                })),
              });
            });
        } else {
          this.action.next({
            type: TableActionType.FILTER,
            data: this._tableFilters.map((filter) => ({
              value: filter.valuePath + filter.operator + filter.filterValue,
              filter,
            })),
          });
        }
        if (this.pagination) {
          this.paginator.firstPage();
        }
      }
    }
  }

  /**
   * Converts a chip to a TableFilter to store filters
   * internally to manage display names for filters
   * @param chip string in form of 'name=123' path|operator|value
   */
  chipToFilter(chip: string): ITableFilter {
    const newFilter: ITableFilter = {
      displayName: '',
      filterValue: '',
      operator: '',
      valuePath: '',
    };
    let columnPath: string;

    /**
     * 0 = valuePath
     * 1 = operator
     * 2 = filterValue
     */
    let split: string[] = chip
      .split(new RegExp('(.+?)(==|<|>|=)(.+)'))
      .filter(Boolean);
    if (split.length !== 3) {
      // Entered filter is not complete no further search
      newFilter.operator = chip.trim();
      return newFilter;
    }
    // Remove spaces on single parts of filter
    split = split.map((part) => part.trim());

    const tmpValuePath = split[0];
    const operator = split[1];
    const parsedFilterValue = split[2];

    // check if value path has a '.' in it

    let splitValuePath: string[];
    if (tmpValuePath.includes('.')) {
      splitValuePath = tmpValuePath.split('.');
      columnPath = splitValuePath[0];
    } else {
      columnPath = tmpValuePath;
    }

    // search for column with matching translated display name, match with lower case
    let resolvedColumn = this._columns.find((column) => {
      return (
        columnPath.toLowerCase() ===
        this.translate.instant(column.displayName).toLowerCase()
      );
    });

    if (!resolvedColumn) {
      resolvedColumn = this._columns.find((column) => {
        return columnPath === column.internalName;
      });
    }

    if (resolvedColumn) {
      // set path to filter and the translated display name
      if (!resolvedColumn.filter) {
        return {} as ITableFilter;
      }

      if (typeof resolvedColumn.filter === 'string') {
        newFilter.valuePath = resolvedColumn.filter;
      } else {
        let filter: IFilterConfig;
        if (resolvedColumn.filter instanceof Array) {
          // find right filter based on second part of 'splitValuePath'
          filter = resolvedColumn.filter.find(
            (el) =>
              el.internalName === splitValuePath[1] ||
              this.translate.instant(el.displayName).toLowerCase() ===
                splitValuePath[1].toLowerCase(),
          ) as IFilterConfig;
          newFilter.displayName =
            this.translate.instant(resolvedColumn.displayName) +
            '.' +
            this.translate.instant((<IMultiFilterConfig>filter).displayName);
        } else {
          filter = resolvedColumn.filter as IFilterConfig; // Add type assertion here
        }
        newFilter.valuePath = filter.filterPath;

        if (filter.filterValues) {
          const filterValue: IFilterValue = filter.filterValues.find((value) =>
            value.usableValues.includes(parsedFilterValue),
          ) as IFilterValue;
          if (filterValue) {
            newFilter.filterValue = filterValue.internalValue.toString();
          }
        }
      }

      if (!newFilter.displayName) {
        newFilter.displayName = this.translate.instant(
          resolvedColumn.displayName,
        );
      }
      newFilter.operator = operator;

      if (!newFilter.filterValue) {
        newFilter.filterValue = parsedFilterValue;
      }

      return newFilter;
    } else {
      return {} as ITableFilter;
    }
  }

  handleColumnSelectionChange() {
    const tmpColumns: string[] = this._displayedColumns.filter((el) =>
      this._newSelectedColumns.includes(el),
    );

    this._newSelectedColumns.forEach((internalName) => {
      if (!tmpColumns.includes(internalName)) {
        tmpColumns.splice(tmpColumns.length, 0, internalName);
      }
    });

    this.displayedColumns = tmpColumns;
    this.cookieColumns = this._displayedColumns;

    if (this._config.buttons && this._config.buttons.length > 0) {
      tmpColumns.push('extraButtons');
    }
    if (this._config.selectable) {
      tmpColumns.unshift('select');
    }

    this.columnMenuTrigger.closeMenu();
  }

  onToggleSelectionGroup(displayName: string) {
    const group = this._selectableColumnGroups.find(
      (el) => el.displayName === displayName,
    );
    if (group) {
      const columns = group.columns.map((column) => column.internalName);

      const currentColumns: string[] = this._displayedColumns.filter((el) =>
        columns.includes(el),
      );

      if (currentColumns.length === columns.length) {
        this.displayedColumns = this.displayedColumns.filter(
          (el) => !columns.includes(el),
        );
      } else {
        this.displayedColumns = [
          ...new Set(this.displayedColumns.concat(columns)),
        ];
      }

      this.cookieColumns = this._displayedColumns;

      const formControls = this.selectColumnGroup.controls;
      for (const key in formControls) {
        if (formControls.hasOwnProperty(key)) {
          const control: AbstractControl = formControls[key];
          control.setValue(this.displayedColumns.includes(key));
        }
      }

      if (this._config.buttons && this._config.buttons.length > 0) {
        currentColumns.push('extraButtons');
      }
      if (this._config.selectable) {
        currentColumns.unshift('select');
      }
    }
  }

  isGroupFullySelected(displayName: string): boolean {
    const group = this._selectableColumnGroups.find(
      (el) => el.displayName === displayName,
    );

    const columns = group?.columns.map((column) => column.internalName);

    const currentColumns: string[] = this._displayedColumns.filter((el) =>
      columns?.includes(el),
    );

    return currentColumns.length === columns?.length;
  }

  resetCols() {
    this._columnSelectionControl$.unsubscribe();
    this.initDisplayedColumns(true);
  }

  sortChange(column: any) {
    switch ((<IColumn<ILayoutV2>>column).sorting?.sortDirection) {
      case SortingDirections.NONE:
        column.sorting.sortDirection = SortingDirections.ASC;
        break;
      case SortingDirections.ASC:
        column.sorting.sortDirection = SortingDirections.DESC;
        break;
      case SortingDirections.DESC:
        column.sorting.sortDirection = SortingDirections.NONE;
        break;
      default:
        column.sorting.sortDirection = SortingDirections.ASC;
        break;
    }
    this.action.next({ type: TableActionType.SORT, data: column });
  }

  masterToggle($event: any) {
    $event;
    if (!this.isAllSelected) {
      this.selection.select(
        ...this.dataSource.data.filter(
          (row) => !row.hasOwnProperty('detailRow'),
        ),
      );
    } else {
      this.selection.clear();
    }
  }

  rowSelectionChange(selection: SelectionModel<any>) {
    this.action.next({
      type: TableActionType.SELECTION,
      data: selection.selected,
    });
  }

  clickTableField(row: any, column: IColumn<ILayoutV2>) {
    this.action.next({
      type: TableActionType.FIELD_CLICK,
      data: {
        row: row,
        column: column,
      },
    });
    if (!column.clickable) {
      this.setDetailRow(row);
    }
  }

  mouseEnterField($event: any, column: any) {
    if (column.clickable) {
      $event.target.style.cursor = 'help';
      $event.target.style.background = 'rgba(0, 0, 0, 0.05)';
    }
  }

  mouseLeaveField($event: any) {
    $event.target.style.background = '';
  }

  setDetailRow(row: any) {
    if (
      this._detailElement &&
      (this._config.rowCompare(this._detailElement, row) ||
        this._detailElement === row)
    ) {
      this.removeDetailRow();
      this._detailElement = null;
    } else {
      if (this.dataToDisplay(row)) {
        this._detailElement = row;
        this.addDetailRow();
      }
    }
  }

  removeDetailRow() {
    const currentRows = Object.assign([], this.dataSource.data);
    const rowIndex = currentRows.findIndex((el) => {
      if (this._config.rowCompare && this._detailElement) {
        return this._config.rowCompare(el, this._detailElement);
      } else {
        return el === this._detailElement;
      }
    });

    if (
      rowIndex !== -1 &&
      (!currentRows[rowIndex] ||
        !(currentRows[rowIndex] as any).hasOwnProperty('detailRow'))
    ) {
      currentRows.splice(rowIndex + 1, 1);
    }

    this._dataSource.data = currentRows;
    this._dataSource._updateChangeSubscription();
  }

  addDetailRow() {
    const currentRows = Object.assign([], this.dataSource.data);
    const rowIndex = currentRows.findIndex((el) => {
      if (this._config.rowCompare && this._detailElement) {
        return this._config.rowCompare(el, this._detailElement);
      } else {
        return el === this._detailElement;
      }
    });

    if (
      rowIndex !== -1 &&
      (!currentRows[rowIndex + 1] ||
        !(currentRows[rowIndex + 1] as any).hasOwnProperty('detailRow'))
    ) {
      currentRows.splice(rowIndex + 1, 0, {
        detailRow: true,
        element: currentRows[rowIndex],
      });
    }

    this._dataSource.data = currentRows;
    this._dataSource._updateChangeSubscription();
  }

  reload() {
    this.action.next({
      type: TableActionType.RELOAD,
    });
  }

  pageChange(event: PageEvent) {
    this.cookiePageSize = event.pageSize;
    this.action.next({
      type: TableActionType.PAGE,
      data: event,
    });
  }

  buttonPress(button: any, table_item: any) {
    this.action.next({
      type: TableActionType.BUTTON,
      data: {
        button: button,
        item: table_item,
      },
    });
  }

  contextButtonPress(button: any) {
    this.action.next({
      type: TableActionType.BUTTON,
      data: {
        button: button,
        item: this._contextMenu.menuData,
      },
    });
  }

  contextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this._contextMenu.menuData = { item: item };
    if (
      (this._buttons &&
        this._buttons.length > 0 &&
        this.anyButtonDisplayed(this._buttons, item)) ||
      (this._headerButtons && this._headerButtons.length > 0)
    ) {
      this._contextMenu.openMenu();
    }
  }

  anyButtonDisplayed(buttons: IButton[], item: any): boolean {
    let buttonDisplayed = false;
    buttons.forEach((button) => {
      if (button.displayed) {
        buttonDisplayed = buttonDisplayed || button.displayed(item);
      } else {
        buttonDisplayed = true;
      }
    });
    return buttonDisplayed;
  }

  headerButtonPress(button: any) {
    this.action.next({
      type: TableActionType.HEADER_BUTTON,
      data: {
        button: button,
        item: this.selection.selected,
      },
    });
  }

  calcColumnMenuWidth() {
    if (this.utils.mediaBreakpointDown('md', window.innerWidth)) {
      return 18;
    } else {
      return this.columnGroups.length > 3
        ? 20 * 3 - 2
        : 20 * this.columnGroups.length - 2;
    }
  }

  calcColumnMenuColWidth(): number {
    if (this.utils.mediaBreakpointDown('md', window.innerWidth)) {
      return 100;
    } else {
      return this.columnGroups.length > 3 ? 33 : 100;
    }
  }

  dropListDropped(event: CdkDragDrop<any>) {
    if (
      event.item.data.column.internalName !==
      event.container.element.nativeElement.id
    ) {
      const oldIndex = this._displayedColumns.findIndex(
        (el) => el === event.item.data.column.internalName,
      );
      const newIndex = this._displayedColumns.findIndex(
        (el) => el === event.container.element.nativeElement.id,
      );

      moveItemInArray(this._displayedColumns, oldIndex, newIndex);

      this.cookieColumns = this._displayedColumns.filter(
        (el) => el !== 'select' && el !== 'extraButtons',
      );
    }
    this._dragDropEnteredContainer = undefined;
  }

  dropListEntered(event: CdkDragEnter<any>) {
    this._dragDropEnteredContainer = event.container.element.nativeElement.id;
  }

  public setTableFilter(): void {}

  // -------------ProgressBar-------------
  isProgressBarActive() {
    return (
      this._config &&
      this._config.progressBarValues &&
      this._config.progressBarValues.length > 0 &&
      !this.loading
    );
  }

  showProgressBar(i: number, row: object): boolean {
    return (
      row.hasOwnProperty('progressBarActive') &&
      (row as any)['progressBarActive']
    );
  }

  getPartPercentage(partName: string, row: any) {
    let all: number = 0;

    this.progressBarValues.forEach((value) => {
      if (row[value.value]) {
        all += row[value.value];
      }
    });

    return (row[partName] * 100) / all;
  }

  addProgressBarRow() {
    if (this.isProgressBarActive()) {
      const currentRows = Object.assign([], this.dataSource.data);
      if (currentRows && currentRows.length > 0) {
        let i = 0;

        while (i <= currentRows.length - 1) {
          if (
            !(currentRows[i] as any).progressBarActive &&
            !(currentRows[i] as any).detailRow
          ) {
            let hasAllProgressBarParameters: boolean = true;
            this._config.progressBarValues.forEach((progressBarValue) => {
              hasAllProgressBarParameters =
                hasAllProgressBarParameters &&
                (currentRows[i] as any).hasOwnProperty(progressBarValue.value);
            });

            if (hasAllProgressBarParameters) {
              currentRows.splice(i + 1, 0, {
                progressBarActive: true,
                element: currentRows[i],
              });
            }
          }
          i += 1;
        }

        this._dataSource.data = currentRows;
        this._dataSource._updateChangeSubscription();
      }
    }
  }

  get progressBarValues() {
    return this._config.progressBarValues.sort(
      (a, b) => a.position - b.position,
    );
  }
}
