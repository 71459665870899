import { Action } from '@ngrx/store';

export enum HelpActionTypes {
  RequestManual = '[Help] Request Manual',
  ShowHelpCenter = '[Help] Show Help Center',
}

export class RequestManual implements Action {
  readonly type = HelpActionTypes.RequestManual;

  constructor(public payload: Date) {}
}

export class ShowHelpCenter implements Action {
  readonly type = HelpActionTypes.ShowHelpCenter;

  constructor(public payload: Date) {}
}

export type HelpActionsUnion = RequestManual | ShowHelpCenter;
