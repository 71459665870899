import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import { IInfoCard } from '../../../interfaces/card-layout.interface';

export const objectKeys = Object.keys as <T>(
  o: T,
) => ReadonlyArray<Extract<keyof T, string>>;

@Component({
  selector: 'bewa-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
@Subscriptions()
export class InfoCardComponent implements OnInit {
  @Input()
  card: IInfoCard;
  @Unsubscribe()
  private cardUpdateSubscription: any;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: IInfoCard) => {
        objectKeys(data).forEach((key) => {
          if (data[key]) {
            (this.card.data as any)[key] = data[key];
          }
        });
        this.cdRef.detectChanges();
      },
    );
  }

  get isMultiline() {
    return this.card.data.infoText instanceof Array;
  }

  get flexBasis() {
    let basis = 100;
    if (this.card.contentCols) {
      basis = basis / this.card.contentCols;
    }
    return basis + '%';
  }

  getList(value: string | string[]) {
    return value as string[];
  }
}
