import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Logger } from '../../../../../../external-modules/utils/logger/logger.service';
import {
  CardType,
  IDashboardCard,
} from '../../interfaces/card-layout.interface';
import { AddressCardComponent } from '../card-types/address-card/address-card.component';
import { BarChartCardComponent } from '../card-types/bar-chart-card/bar-chart-card.component';
import { InfoCardComponent } from '../card-types/info-card/info-card.component';
import { PieChartCardComponent } from '../card-types/pie-chart-card/pie-chart-card.component';
import { TabTableCardComponent } from '../card-types/tab-table-card/tab-table-card.component';
import { TableCardComponent } from '../card-types/table-card/table-card.component';

@Component({
  selector: 'bewa-card-selector',
  template: '<ng-container #container></ng-container>',
})
export class CardSelectorComponent implements OnInit {
  @Input()
  card: IDashboardCard;

  private componentRef: ComponentRef<any>;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container: ViewContainerRef;

  constructor(private logger: Logger) {}

  ngOnInit(): void {
    if (this.card) {
      this.container.clear();
      const card: IDashboardCard = this.card;
      if (this.getCardLayoutClass(card.cardType) !== undefined) {
        const viewContainerRef = this.container;
        viewContainerRef.clear();
        this.componentRef = this.container.createComponent(
          this.getCardLayoutClass(card.cardType),
        );
        this.componentRef.instance['card'] = this.card;
      }
    }
  }

  getCardLayoutClass(type: CardType): any {
    switch (type) {
      case CardType.ADDRESS:
        return AddressCardComponent;
      case CardType.INFO:
        return InfoCardComponent;
      case CardType.PIE_CHART:
        return PieChartCardComponent;
      case CardType.TABLE:
        return TableCardComponent;
      case CardType.TAB_TABLE:
        return TabTableCardComponent;
      case CardType.BAR_CHART:
        return BarChartCardComponent;
      default:
        this.logger.error('Unkwown card layout');
    }
  }
}
