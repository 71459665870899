import {
  InjectFlags,
  InjectionToken,
  Injector,
  ProviderToken,
  Type,
} from '@angular/core';

export class NewsBarInjector implements Injector {
  constructor(
    private _parentInjector: Injector,
    private _additionalTokens: WeakMap<any, any>,
  ) {}

  get<T>(
    token: Type<T> | InjectionToken<T>,
    notFoundValue?: T,
    flags?: InjectFlags,
  ): T;
  get(token: any, notFoundValue?: any): any;
  get(
    token: ProviderToken<any>,
    notFoundValue?: null | undefined,
    flags?: InjectFlags,
  ): any {
    const value = this._additionalTokens.get(token);
    if (value) {
      return value;
    }
    return this._parentInjector.get<any>(token, notFoundValue);
  }
}
