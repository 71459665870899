import { LanguageActionsUnion, LanguageActionTypes } from '../actions/language';
import { ILanguage } from '../models/language';

export interface State {
  active: ILanguage;
  languages: ILanguage[];
}

export const initialState: State = {
  active: null!,
  languages: [],
};

export function reducer(
  state = initialState,
  action: LanguageActionsUnion,
): State {
  switch (action.type) {
    case LanguageActionTypes.UpdateAvailableLanguages: {
      return {
        ...state,
        languages: action.payload,
      } as State;
    }
    case LanguageActionTypes.UpdateActiveLanguage: {
      return {
        ...state,
        active: action.payload,
      } as State;
    }

    default: {
      return state;
    }
  }
}

export const getActiveLanguage = (state: State) => {
  return state?.active || initialState.active;
};

export const getLanguages = (state: State) => {
  return state?.languages || [];
};
