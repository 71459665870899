<bewa-bewatec-dialog
  [dialogIcon]="'warning'"
  [dialogTitle]="'NOTIFICATIONS.HEADER'"
  [actionButtonName]="confirmButtonName()"
  [cancelButtonName]="'NOTIFICATIONS.BUTTON_CANCEL'"
  [showCancelButton]="confirmable"
  (action)="onClose()"
>
  <div *ngIf="isArray(notificationMessage)">
    <p *ngFor="let msg of notificationMessage">{{ msg | translate }}</p>
  </div>

  <div *ngIf="!isArray(notificationMessage) && !isComplexMessage()">
    <p>{{ getString(notificationMessage) | translate }}</p>
  </div>

  <div *ngIf="isComplexMessage()">
    <p *ngIf="!getComplexMessage(notificationMessage)?.innerHtml">
      {{ getComplexMessage(notificationMessage)!.message | translate:
      getComplexMessage(notificationMessage)?.parameters }}
    </p>
    <p
      *ngIf="getComplexMessage(notificationMessage)?.innerHtml"
      [innerHTML]="getComplexMessage(notificationMessage)!.message | translate: getComplexMessage(notificationMessage)?.parameters"
    ></p>
  </div>
</bewa-bewatec-dialog>
