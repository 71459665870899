import { Injectable } from '@angular/core';
import { UtilsConfig } from '../utils.config';

@Injectable({
  providedIn: 'root',
})
export class Logger {
  private _environment: string;
  printMessage(className: string, logLevel: string, ...params: any[]): void {
    (console as any)[logLevel](new Date(), className, ...params);
  }

  log(className: string, ...params: any[]): void {
    this.printMessage(className, 'log', ...params);
  }

  debug(className: string, ...params: any[]): void {
    if (this._environment === 'local' || this._environment === 'develop') {
      this.printMessage(className, 'log', ...params);
    }
  }

  error(className: string, ...params: any[]): void {
    this.printMessage(className, 'error', ...params);
  }
}
