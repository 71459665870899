import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
  SetNavigationData = '[ Navigation ] Set Navigation Data',
  ResetNavigationData = '[ Navigation ] Reset Navigation Data',
}

export class SetNavigationData implements Action {
  readonly type = NavigationActionTypes.SetNavigationData;
  public payload: { data?: any; timestamp?: Date } = {};
  constructor(data: any) {
    this.payload.data = data;
    this.payload.timestamp = new Date();
  }
}

export class ResetNavigationData implements Action {
  readonly type = NavigationActionTypes.ResetNavigationData;
  constructor() {}
}

export type NavigationActionUnion = SetNavigationData | ResetNavigationData;
