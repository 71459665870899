import { HeaderActionsUnion, HeaderActionTypes } from '../actions/header';
import { HeaderEvent } from '../models/header-event';

export interface State {
  event: HeaderEvent;
}

export const initialState: State = {
  event: null!,
};

export function reducer(
  state = initialState,
  action: HeaderActionsUnion,
): State {
  switch (action.type) {
    case HeaderActionTypes.EmitHeaderEvent: {
      return {
        ...state,
        event: action.payload,
      } as State;
    }
    case HeaderActionTypes.ReceivedHeaderEvent: {
      return {
        ...state,
        event: null!,
      };
    }
    default: {
      return state;
    }
  }
}

export const getEvent = (state: State) => {
  return state?.event;
};
