<div class="mat-dialog-title mt-3">
  <div class="bew-dialog-container">
    <ng-container *ngIf="isIconColor">
      <mat-icon [color]="dialogIconColor">{{ dialogIcon }}</mat-icon>
    </ng-container>
    <ng-container *ngIf="!isIconColor">
      <mat-icon>{{ dialogIcon }}</mat-icon>
    </ng-container>

    <span class="bew-dialog-title text-lg">{{ dialogTitle | translate }}</span>

    <span class="bew-dialog-close-button flex items-center">
      <span class="bew-dialog-title text-lg">{{
        dialogTitleExtra | translate
      }}</span>
      <button
        mat-icon-button
        bewaTourAnchor="dialog.anchor.close"
        [disabled]="processingAction"
        (click)="dialogCancel($event)"
        [tabIndex]="-1"
      >
        <mat-icon class="mb-2">close</mat-icon>
      </button>
    </span>
  </div>
</div>

<mat-dialog-content class="bew-dialog-content">
  <ng-content></ng-content>
  <mat-hint *ngIf="showRequiredHint"
    >*= {{ "BEWATEC_DIALOG.REQUIRED_HINT" | translate }}</mat-hint
  >
</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="showButtons">
  <button
    *ngIf="showCancelButton"
    mat-raised-button
    class="col-md-auto"
    bewaTourAnchor="dialog.anchor.cancel"
    [ngClass]="{ 'cancel-button': !isSmallResolution() }"
    (click)="dialogCancel($event)"
    devId="dialogCancelButton"
    [disabled]="processingAction"
  >
    {{ getCancelButtonName() | translate }}
  </button>

  <ng-container>
    <button
      [appShowSpinner]="processingAction"
      bewaTourAnchor="dialog.anchor.action"
      mat-raised-button
      devId="dialogActionButton"
      color="primary"
      class="col-md-auto"
      [disabled]="actionButtonDisabled"
      (click)="onActionButton($event)"
    >
      {{ getActionButtonName() | translate }}
    </button>
  </ng-container>
</mat-dialog-actions>
