<div *ngIf="typeError">
  <div class="bew-snack-bar-container">
    <mat-icon color="warn">highlight_off</mat-icon>
    <span>
      {{ snackbarMessage | translate }}
    </span>
    <span class="bew-snack-bar-button">
      <button mat-button color="warn" (click)="close()">
        {{ "NOTIFICATIONS.BUTTON_CLOSE" | translate }}
      </button>
    </span>
  </div>
</div>

<div *ngIf="typeInfo">
  <div class="bew-snack-bar-container">
    <mat-icon color="accent">info</mat-icon>
    <span> {{ snackbarMessage | translate }} </span>
  </div>
</div>

<div *ngIf="typeDone">
  <div class="bew-snack-bar-container">
    <mat-icon color="primary">check_circle</mat-icon>
    <span> {{ snackbarMessage | translate }} </span>
  </div>
</div>
