import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Color, ColorHelper, ScaleType } from '@swimlane/ngx-charts';
import {
  IBarChartCard,
  IChartData,
} from '../../../interfaces/card-layout.interface';

@Component({
  selector: 'bewa-bar-chart-card',
  templateUrl: './bar-chart-card.component.html',
  styleUrls: ['./bar-chart-card.component.scss'],
})
@Subscriptions()
export class BarChartCardComponent implements AfterViewInit {
  _originalBarData: { name: string; value: number }[] = [];
  _barData: { name: string; value: number }[] = [];
  _deactivatedData: { name: string; value: number }[] = [];
  barColor: { name: string; value: string }[] = [];

  public _labelNames: string[] = [];
  public colors: ColorHelper;
  public colorScheme: Color = {
    domain: [],
    group: ScaleType.Ordinal,
    name: '',
    selectable: false,
  }; // Custom color scheme in hex

  private _ticks: number[];

  @Input()
  card: IBarChartCard;
  @Unsubscribe()
  private cardUpdateSubscription: any;

  constructor() {}

  containerWidth: number = 0;
  containerHeight: number = 0;

  @ViewChild('containerRef', { static: false }) containerRef: ElementRef;

  ngAfterViewInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: IChartData) => {
        if (data.chartData) {
          this.card.data.chartData = data.chartData;
        }
        if (data.chartTitle) {
          this.card.data.chartTitle = data.chartTitle;
        }
        if (data.chartLabels) {
          this.card.data.chartLabels = data.chartLabels;
        }
        if (data.chartBackgroundColor) {
          this.card.data.chartBackgroundColor = data.chartBackgroundColor;
        }

        if (this.card.data.chartData && this.card.data.chartLabels) {
          const dataLen = this.card.data.chartData.length;
          const barData = [];
          this.barColor = [];
          const newColors = this.getColors();
          for (let i = 0; i < dataLen; i++) {
            let label: string = this.card.data.chartLabels[i];
            if (data && this.card.maxNameLength) {
              label = label.slice(0, this.card.maxNameLength);
            }

            let value = this.card.data.chartData[i];
            if (this.card.logarithmic) {
              this.addTick(value);
              value = Math.log10(value + 1);
            }

            barData.push({
              name: label,
              value: value,
            });
            this.barColor.push({
              name: label,
              value: newColors[i],
            });
            this.colorScheme.domain.push(newColors[i]);
          }
          this._originalBarData = barData;
          this.barData = barData;

          // Get chartNames
          this.chartLabels = this.barData.map((d: any) => d.name);
          // Convert hex colors to ColorHelper for consumption by legend
          this.colors = new ColorHelper(
            this.colorScheme,
            ScaleType.Ordinal,
            this.chartLabels,
            this.colorScheme,
          );
        }
      },
    );
    setTimeout(() => {
      this.containerWidth = this.containerRef.nativeElement.offsetWidth;
      this.containerHeight = this.containerRef.nativeElement.offsetHeight;
    });
  }

  elementIsDeactivated(element: any): boolean {
    const foundElement = this.deactivatedData.find(
      (deactivatedEl) => deactivatedEl.name === element.name,
    );
    return !!foundElement;
  }

  get chartLabels(): string[] {
    return this._labelNames;
  }

  set chartLabels(labels: string[]) {
    this._labelNames = labels;
  }

  get barData(): any[] {
    return this._barData;
  }

  set barData(data: any[]) {
    this._barData = data;
  }

  get deactivatedData(): any[] {
    return this._deactivatedData;
  }

  set deactivatedData(labels: any[]) {
    this._deactivatedData = labels;
  }

  get logarithmic() {
    return this.card.logarithmic;
  }

  get ticks() {
    return this._ticks;
  }

  addTick(val: number) {
    let tick = 10;

    if (!this._ticks) {
      this._ticks = [0];
    }
    while (val > tick) {
      // add ticks between if not already in
      if (!this._ticks.includes(Math.log10(tick + 1))) {
        this._ticks.push(Math.log10(tick + 1));
      }
      tick = tick * 10;
    }
    // add max tick
    if (!this._ticks.includes(Math.log10(tick + 1))) {
      this._ticks.push(Math.log10(tick + 1));
    }
  }

  private getColors(): string[] {
    let newColors = [];
    if (
      this.card.data.calcMissingColors &&
      !(
        this.card.data.chartData.length <=
        this.card.data.chartBackgroundColor.length
      )
    ) {
      newColors = this.calcDivInColors(
        this.card.data.chartBackgroundColor[0],
        this.card.data.chartBackgroundColor[1],
        this.card.data.chartData.length,
      );
    } else {
      newColors = this.card.data.chartBackgroundColor;
    }
    return newColors;
  }

  calcDivInColors(
    startColor: string,
    endColor: string,
    dataLength: number,
  ): string[] {
    const divVec: number[] = [];
    const newColors: string[] = [];
    for (let i = 0; i < 3; i++) {
      const sub1 = startColor.substring(1 + 2 * i, 3 + 2 * i);
      const sub2 = endColor.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      const v2 = parseInt(sub2, 16);
      divVec[i] = (v2 - v1) / (dataLength - 1);
    }
    for (let k = 0; k < dataLength; k++) {
      if (k === 0) {
        newColors[k] = startColor;
      } else {
        newColors[k] = this.addDiffToColor(newColors[k - 1], divVec);
      }
    }
    return newColors;
  }

  addDiffToColor(color: string, divVev: number[]) {
    let c = '#';
    for (let i = 0; i < 3; i++) {
      const sub1 = color.substring(1 + 2 * i, 3 + 2 * i);
      const v1 = parseInt(sub1, 16);
      let v = Math.floor(v1 + divVev[i]);
      if (v > 255) {
        v = v - 255;
      } else if (v < 0) {
        v = v + 255;
      }
      const sub = v.toString(16).toUpperCase();
      const padsub = ('0' + sub).slice(-2);
      c += padsub;
    }
    return c;
  }

  getYAxisFormat() {
    if (this.logarithmic) {
      return this.getLogarithmicFormat;
    } else {
      return this.getNormalFormat;
    }
  }

  getLogarithmicFormat(val: number) {
    if (val !== 0) {
      const rtn = Math.round(Math.pow(10, val)) - 1;
      return rtn;
    } else {
      return 0;
    }
  }
  getNormalFormat(val: number) {
    return val;
  }
}
