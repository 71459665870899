import { Action } from '@ngrx/store';
import { Identity } from '../models/identity';

export enum LoginActionTypes {
  UpdateLoginStatus = '[Login] Update Login Status',
  UpdateIdentity = '[Login] Update Identity',
  UpdateRedirectUrl = '[Login] Update Return URL',
}

export class UpdateLoginStatus implements Action {
  readonly type = LoginActionTypes.UpdateLoginStatus;

  constructor(public payload: boolean) {}
}
export class UpdateIdentity implements Action {
  readonly type = LoginActionTypes.UpdateIdentity;

  constructor(public payload: Identity | null) {}
}

export class UpdateRedirectUrl implements Action {
  readonly type = LoginActionTypes.UpdateRedirectUrl;

  constructor(public payload: string) {}
}

export type LoginActionsUnion =
  | UpdateLoginStatus
  | UpdateIdentity
  | UpdateRedirectUrl;
