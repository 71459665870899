import { RolesActionsUnion, RolesActionTypes } from '../actions/auth';

export interface State {
  roles: string[];
}

export const initialState: State = {
  roles: null!,
};

export function reducer(
  state = initialState,
  action: RolesActionsUnion,
): State {
  switch (action.type) {
    case RolesActionTypes.UpdateUserRoles: {
      return {
        ...state,
        roles: action.payload,
      } as State;
    }
    default: {
      return state;
    }
  }
}

export const getUserRoles = (state: State) => {
  return state?.roles;
};
