import { SelectionActionsUnion, SelectionActionTypes } from '../actions/select';

export interface State {
  selectedCustomer: any;
}

export const initialState: State = {
  selectedCustomer: null,
};

export function reducer(
  state = initialState,
  action: SelectionActionsUnion,
): State {
  switch (action.type) {
    case SelectionActionTypes.UpdateCustomer: {
      return {
        ...state,
        selectedCustomer: action.newCustomer,
      } as State;
    }
    default: {
      return state;
    }
  }
}

export const getSelectedCustomer = (state: State) => {
  if (state) {
    return state.selectedCustomer;
  }
  return null;
};
