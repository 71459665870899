<div class="content-wrapper-wrapper">
  <div class="content-wrapper flex justify-center">
    <div id="header" class="header-wrapper flex">
      <div class="flex w-full">
        <mat-form-field
          class="w-full"
          subscriptSizing="dynamic"
          *ngIf="searchBar"
        >
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let filter of tableFilters"
              [removable]="true"
              (removed)="removeFilter($event, filter)"
            >
              {{ filter.displayName + filter.operator + filter.filterValue }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              bewaTourAnchor="help.anchor.table.searchbar"
              trim="blur"
              #chipListInput
              [formControl]="chipListControl"
              [placeholder]="'BEWATEC_TABLE.FILTER_PLACEHOLDER' | translate"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addFilter($event)"
            />
          </mat-chip-grid>
          <button
            matSuffix
            mat-icon-button
            aria-label="search"
            style="padding: 0px !important; height: 20px !important"
          >
            <mat-icon style="transform: scale(0.75)">search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="column-selection-wrapper col-auto" *ngIf="selectableColumns">
        <button
          #columnMenuTrigger
          mat-raised-button
          bewaTourAnchor="help.anchor.table.columnselectbutton"
          [matMenuTriggerFor]="tableColumns"
          (menuOpened)="columnMenuOpened()"
          (menuClosed)="columnMenuClosed()"
        >
          {{ "BUTTONS.COLUMN_SELECT" | translate }}
        </button>
        <mat-menu
          #tableColumns="matMenu"
          class="table-columns"
          yPosition="below"
          xPosition="before"
        >
          <ng-template matMenuContent>
            <form
              class="column-row table-columns-form"
              [style.width.rem]="calcColumnMenuWidth()"
              [formGroup]="selectColumnGroup"
              (click)="$event.stopPropagation()"
              bewaTourAnchor="help.anchor.table.columnselect"
            >
              <ngx-masonry
                *ngIf="columnMenuOpen"
                class="grid"
                [options]="{ gutter: 5, fitWidth: true, resize: true }"
                [updateLayout]="true"
              >
                <!-- Masonry Grid Item -->
                <div
                  ngxMasonryItem
                  id="{{ 'masonry-item-' + i }}"
                  *ngFor="let group of selectableColumnGroups; let i = index"
                  [style.width.%]="calcColumnMenuColWidth()"
                  (click)="$event.stopPropagation()"
                >
                  <div
                    class="flex !cursor-pointer items-center"
                    (click)="onToggleSelectionGroup(group.displayName)"
                  >
                    <mat-checkbox
                      disabled
                      [checked]="isGroupFullySelected(group.displayName)"
                    >
                    </mat-checkbox>
                    <label class="!m-0 pl-2" style="font-weight: bold">
                      {{ group.displayName | translate }}
                    </label>
                  </div>
                  <div
                    *ngFor="let col of group.columns"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-checkbox
                      [formControlName]="col.internalName"
                      (click)="$event.stopPropagation()"
                    >
                      {{ col.displayName | translate }}
                    </mat-checkbox>
                  </div>
                </div>
              </ngx-masonry>

              <div class="table-columns-save-button-wrapper">
                <button
                  *ngIf="columnSelectionChanged"
                  mat-raised-button
                  color="primary"
                  (click)="
                    handleColumnSelectionChange(); $event.stopPropagation()
                  "
                >
                  {{ "BUTTONS.SAVE" | translate }}
                </button>
              </div>
            </form>
          </ng-template>
        </mat-menu>

        <div class="reset-cols">
          <button
            bewaTourAnchor="help.anchor.table.columnreset"
            [disableRipple]="true"
            mat-icon-button
            [matTooltip]="'BEWATEC_TABLE.COLUMNS_RESET' | translate"
            (click)="resetCols()"
            [disabled]="isDefaultColumns"
          >
            <mat-icon>settings_backup_restore</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div id="table" class="table-wrapper">
      <table
        mat-table
        #table
        *ngIf="dataSource"
        [dataSource]="dataSource"
        (matSortChange)="sortChange($event)"
        cdkDropListGroup
        matSort
      >
        <!-- Checkbox Column -->
        <ng-container *ngIf="selectable" matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              bewaTourAnchor="help.anchor.table.rowselection"
              (change)="
                $event ? masterToggle($event) : null;
                rowSelectionChange(selection)
              "
              [checked]="selection.hasValue() && isAllSelected"
              [indeterminate]="selection.hasValue() && !isAllSelected"
            >
            </mat-checkbox>
          </th>

          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event ? selection.toggle(row) : null;
                rowSelectionChange(selection)
              "
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <div *ngFor="let table_col of reducedColumns; let i = index">
          <ng-container [matColumnDef]="table_col.internalName">
            <th
              mat-header-cell
              *matHeaderCellDef
              cdkDropList
              cdkDropListLockAxis="x"
              cdkDropListOrientation="horizontal"
              (cdkDropListDropped)="dropListDropped($event)"
              (cdkDropListEntered)="dropListEntered($event)"
              [cdkDropListDisabled]="columnSortDisabled"
              [id]="table_col.internalName"
              bewaTourAnchor="help.anchor.table.columnhead"
              class="header-cell"
            >
              <div
                cdkDrag
                [cdkDragData]="{ column: table_col, index: i }"
                [cdkDragDisabled]="columnSortDisabled"
                [id]="table_col.internalName"
                class="d-flex align-items-center"
              >
                <ng-container
                  *ngIf="table_col.internalName !== dragDropEnteredContainer"
                >
                  <span
                    id="drag-handle"
                    [ngClass]="{ 'column-drag-handle': !columnSortDisabled }"
                    cdkDragHandle
                  >
                    {{ table_col.displayName | translate }}
                  </span>
                  <ng-container *ngIf="table_col.sorting">
                    <button
                      [disableRipple]="true"
                      class="custom-icon-button"
                      mat-icon-button
                      (click)="sortChange(table_col)"
                      bewaTourAnchor="help.anchor.table.column.sortbutton"
                    >
                      <mat-icon class="sort-icon">
                        {{ table_col.sorting.sortIcon }}
                      </mat-icon>
                    </button>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      searchBar &&
                      table_col.filter !== undefined &&
                      table_col.filter !== null
                    "
                  >
                    <ng-container
                      *ngIf="
                        isSimpleSearch(table_col);
                        then simpleSearch;
                        else complexSearch
                      "
                    ></ng-container>
                    <ng-template #simpleSearch>
                      <button
                        mat-icon-button
                        class="header-dropdown-button"
                        (click)="addFilterString(table_col)"
                        bewaTourAnchor="help.anchor.table.column.searchbutton"
                      >
                        <mat-icon> search </mat-icon>
                      </button>
                    </ng-template>
                    <ng-template #complexSearch>
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="searchMenu"
                        [matMenuTriggerRestoreFocus]="false"
                        bewaTourAnchor="help.anchor.table.column.searchbutton"
                      >
                        <mat-icon> search </mat-icon>
                      </button>
                      <mat-menu
                        #searchMenu="matMenu"
                        class="table-menu"
                        (closed)="onSearchMenuClose($event)"
                      >
                        <ng-template matMenuContent>
                          <ng-container
                            *ngFor="
                              let filter of columnFilters(table_col.filter)
                            "
                          >
                            <button
                              mat-menu-item
                              [matMenuTriggerFor]="valueMenu"
                              [matMenuTriggerRestoreFocus]="false"
                            >
                              {{ filter.displayName | translate }}
                            </button>
                            <mat-menu
                              #valueMenu="matMenu"
                              class="table-menu"
                              (closed)="onSearchMenuClose($event)"
                            >
                              <ng-template matMenuContent>
                                <ng-container
                                  *ngFor="let value of filter.filterValues"
                                >
                                  <button
                                    mat-menu-item
                                    (click)="
                                      addFilterString(table_col, filter, value)
                                    "
                                  >
                                    {{ value.displayName | translate }}
                                  </button>
                                </ng-container>
                              </ng-template>
                            </mat-menu>
                          </ng-container>
                        </ng-template>
                      </mat-menu>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
            </th>

            <td
              mat-cell
              class="table-cell"
              *matCellDef="let row; let table_item"
              (click)="clickTableField(row, table_col)"
              (mouseenter)="mouseEnterField($event, table_col)"
              (mouseleave)="mouseLeaveField($event)"
              bewaTourAnchor="help.anchor.table.row"
              [devId]="table_col.internalName"
            >
              <bewa-column-selector
                [layout]="table_col.layout"
                [table_item]="table_item"
              ></bewa-column-selector>
            </td>
          </ng-container>
        </div>

        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            [attr.colspan]="displayedColumns.length - 1"
            *matCellDef="let detail"
            class="detail-row-wrapper"
          >
            <div class="row">
              <ng-container *ngFor="let element of nonDisplayedColumns">
                <ng-container *ngIf="hasDataToDisplay(element, detail.element)">
                  <div class="col-auto">
                    <div class="row">
                      <span class="col-auto">
                        <b>{{
                          getNonDisplayedColumn(element).displayName | translate
                        }}</b>
                      </span>
                      <bewa-column-selector
                        class="col-auto"
                        [layout]="getNonDisplayedColumn(element).layout"
                        [table_item]="detail.element"
                      ></bewa-column-selector>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="progressBar">
          <td
            mat-cell
            [attr.colspan]="displayedColumns.length"
            *matCellDef="let progress"
          >
            <div class="progress-bar-container">
              <ng-container *ngFor="let progressBarValue of progressBarValues">
                <div
                  class="progress-bar"
                  [matTooltip]="
                    (progressBarValue.name | translate) +
                    ' ' +
                    progress.element[progressBarValue.value]
                  "
                  [style.width.%]="
                    getPartPercentage(progressBarValue.value, progress.element)
                  "
                  [style.background-color]="progressBarValue.color"
                ></div>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="extraButtons">
          <th mat-header-cell class="extra-buttons-header" *matHeaderCellDef>
            <div
              class="extra-button-wrapper-header"
              #extraButtonsHead
              [style.width.px]="buttonsWidth"
            >
              <ng-container *ngIf="anyHeaderButtons && isMultipleSelected">
                <ng-container
                  *ngIf="headerButtons.length <= 1; else buttonMenu"
                >
                  <ng-container *ngFor="let button of headerButtons">
                    <button
                      *hasRole="button.roles"
                      [class]="button?.buttonStyle || 'mat-icon-button'"
                      (click)="headerButtonPress(button)"
                      [devId]="button.internalName"
                    >
                      <bewa-button-content
                        [button]="button"
                        [evalItem]="selection.selected"
                        type="header"
                        [displayText]="false"
                      ></bewa-button-content>
                    </button>
                  </ng-container>
                </ng-container>
                <ng-template #buttonMenu>
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <span class="iconButton">
                      <mat-icon> more_vert </mat-icon>
                    </span>
                  </button>
                  <mat-menu #menu="matMenu" class="table-menu">
                    <ng-template matMenuContent>
                      <ng-container *ngFor="let button of headerButtons">
                        <button
                          mat-menu-item
                          *hasRole="button.roles"
                          (click)="headerButtonPress(button)"
                          [devId]="button.internalName"
                        >
                          <bewa-button-content
                            [button]="button"
                            [evalItem]="selection.selected"
                            type="header"
                          ></bewa-button-content>
                        </button>
                      </ng-container>
                    </ng-template>
                  </mat-menu>
                </ng-template>
              </ng-container>
            </div>
          </th>
          <td
            mat-cell
            #extraButtonsCol
            class="extra-buttons"
            *matCellDef="let table_item"
          >
            <div class="extra-button-wrapper">
              <ng-container *ngIf="buttons">
                <ng-container *ngIf="buttons.length <= 1; else buttonMenu">
                  <ng-container *ngFor="let button of buttons">
                    <ng-container
                      *ngIf="
                        !button.displayed ||
                        (button?.displayed && button.displayed(table_item))
                      "
                    >
                      <button
                        *hasRole="button.roles"
                        [class]="
                          button?.buttonStyle ||
                          'mat-icon-button' + ' !border-0 !bg-white'
                        "
                        (click)="buttonPress(button, table_item)"
                        [matTooltip]="
                          getTooltip(button, table_item) | translate
                        "
                        [devId]="button.internalName"
                        bewaTourAnchor="{{ button.helpAnchor }}"
                      >
                        <bewa-button-content
                          [button]="button"
                          [evalItem]="table_item"
                          [displayText]="false"
                        ></bewa-button-content>
                      </button>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #buttonMenu>
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu.childMenu"
                    bewaTourAnchor="help.anchor.table.rowactions"
                  >
                    <mat-icon> more_vert </mat-icon>
                  </button>
                  <bewa-menu
                    #menu
                    [buttons]="buttons"
                    [tableItem]="table_item"
                    (action)="buttonPress($event, table_item)"
                  ></bewa-menu>
                </ng-template>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          matRipple
          class="element-row"
          [class.expanded]="isExpanded(row)"
          (contextmenu)="contextMenu($event, row)"
          devId="bewatecTableRow"
        ></tr>
        <tr
          mat-row
          class="progress-bar-row"
          bewaTourAnchor="help.anchor.table.progressbar"
          *matRowDef="let row; columns: ['progressBar']; when: showProgressBar"
        ></tr>
        <tr
          mat-row
          class="element-detail-row"
          bewaTourAnchor="help.anchor.table.detailrow"
          *matRowDef="
            let row;
            columns: ['expandedDetail'];
            when: isExpansionDetailRow
          "
        ></tr>
      </table>

      <div class="no-data-wrapper" *ngIf="isTableEmpty() && !loading">
        <div class="no-data-content">
          <div class="no-data-text">
            <b>{{ "BEWATEC_TABLE.NO_DATA" | translate }}</b>
          </div>
          <div>
            <button mat-button (click)="reload()">
              <mat-icon>refresh</mat-icon>
              {{ "BEWATEC_TABLE.RELOAD" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="loading-wrapper" *ngIf="loading">
        <div class="loading-content">
          <div>
            <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
          </div>
          <div class="loading-text">
            <b>{{ "BEWATEC_TABLE.LOADING_DATA" | translate }}</b>
          </div>
        </div>
      </div>
    </div>
    <div id="paginator" class="row paginatior">
      <div *ngIf="reloadButton" class="button-wrapper col">
        <a mat-icon-button (click)="reload()">
          <mat-icon>cached</mat-icon>
        </a>
      </div>
      <mat-paginator
        *ngIf="pagination"
        #paginator
        [length]="totalElements"
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        class="col"
        (page)="pageChange($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="tableContextMenu"
></div>

<mat-menu #tableContextMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-item="item">
    <ng-container
      *ngIf="
        !isMultipleSelected || !itemSelected(item) || !anyHeaderButtons;
        else multiSelectionMenu
      "
    >
      <ng-container *ngFor="let button of buttons">
        <ng-container
          *ngIf="
            !button.displayed || (button?.displayed && button.displayed(item))
          "
        >
          <bewa-menu-item
            [button]="button"
            [tableItem]="item"
            (action)="buttonPress($event, item)"
          ></bewa-menu-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template #multiSelectionMenu matMenuContent let-item="item">
    <ng-container *ngIf="anyHeaderButtons">
      <ng-container *ngFor="let button of headerButtons">
        <bewa-menu-item
          [button]="button"
          [tableItem]="item"
          (action)="headerButtonPress(button)"
        ></bewa-menu-item>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>
