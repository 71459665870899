import { Action } from '@ngrx/store';

export enum LicenseActionTypes {
  UpdateLicense = '[ License ] Update License',
}

export class UpdateLicense implements Action {
  readonly type = LicenseActionTypes.UpdateLicense;

  constructor(public licenses: number[]) {}
}

export type LicenseActionUnion = UpdateLicense;
