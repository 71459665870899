import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  IAddressCard,
  IAddressData,
} from '../../../interfaces/card-layout.interface';
import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';

export const objectKeys = Object.keys as <T>(
  o: T,
) => ReadonlyArray<Extract<keyof T, string>>;

@Component({
  selector: 'bewa-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
@Subscriptions()
export class AddressCardComponent implements OnInit {
  @Input()
  card: IAddressCard;

  @Unsubscribe()
  private cardUpdateSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: IAddressData) => {
        objectKeys(data).forEach((key) => {
          if (data[key]) {
            (this.card.data as any)[key] = data[key];
          }
        });
      },
    );
  }
}
