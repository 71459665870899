import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HELP_TOUR } from '../../mockdata/mock.data';
import { AbstractService } from '../abstract/abstract.service';

@Injectable()
export class HelpService extends AbstractService {
  getHelpTours(location: string): Observable<any> {
    return of(
      HELP_TOUR.filter((tour) => tour.locations.includes(location) === true),
    ).pipe(delay(1000));
  }
}
