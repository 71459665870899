import { Action } from '@ngrx/store';

export enum TranslateActionTypes {
  TranslateReady = '[ Translate ] Translate ready',
}

export class TranslateReady implements Action {
  readonly type = TranslateActionTypes.TranslateReady;

  constructor(public payload?: boolean) {}
}

export type TranslateActionUnion = TranslateReady;
