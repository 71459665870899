import { DatePipe } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';

@Component({
  selector: 'bewa-date-column-type',
  templateUrl: './date-column-type.component.html',
  styleUrls: ['./date-column-type.component.scss'],
  providers: [DatePipe],
})
export class DateColumnTypeComponent
  extends ColumnTypeAbstractComponent
  implements OnInit
{
  @HostBinding('id') get id() {
    return this.layout.id;
  }
  @Input()
  override set value(value) {
    this._value = value;
  }

  constructor(private datePipe: DatePipe) {
    super();
  }

  ngOnInit() {
    if (!this.layout.formatter) {
      this.layout.formatter = (value) => {
        return value.datePipe.transform(value.value, 'dd.MM.yyyy HH:mm');
      };
    }
  }

  override get value() {
    if (this.layout.formatter) {
      return this.layout.formatter({
        value: this._value,
        datePipe: this.datePipe,
      });
    } else {
      return this._value;
    }
  }
}
