/* eslint-disable @angular-eslint/no-host-metadata-property */
import { IButton } from '@/app/interfaces/button.interface';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'bewa-button-content',
  templateUrl: './button-content.component.html',
  styleUrls: ['./button-content.component.scss'],
  host: {
    class: 'flex items-center',
  },
})
export class ButtonContentComponent {
  @Input()
  set button(button: IButton) {
    this._button = button;
  }

  @Input()
  set evalItem(evalItem) {
    this._evalItem = evalItem;
  }

  @Input()
  set type(type: string) {
    this._type = type;
  }

  @Input()
  set displayText(displayText: boolean) {
    this._displayText = displayText;
  }

  private _button: IButton;
  private _evalItem: any;
  private _type: string;
  private _displayText = true;

  constructor() {}

  get button() {
    return this._button;
  }

  get displayText() {
    return this._displayText;
  }

  get type() {
    return this._type;
  }

  get evalItem() {
    return this._evalItem;
  }

  evalIcon() {
    if (this._type === 'header') {
      return this.button.headerIconEval!(this._evalItem);
    } else {
      return this.button.iconEval!(this._evalItem);
    }
  }

  getIconString(): string {
    if (typeof this.button.icon === 'string') {
      return this.button.icon;
    }
    return undefined!;
  }
}
