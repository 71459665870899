import { Location } from '@angular/common';
import {
  Component,
  HostListener,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateLoadingBaseComponent } from '../../../../components/translate-loading-base/translate-loading-base.component';
import { ICardData, IDashboardCard } from './interfaces/card-layout.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bewatec-dashboard',
  templateUrl: './bewatec-dashboard.component.html',
  styleUrls: ['./bewatec-dashboard.component.scss'],
})
export class BewatecDashboardComponent
  extends TranslateLoadingBaseComponent
  implements OnInit
{
  @Input()
  set dashboardCards(cards: IDashboardCard[]) {
    cards.forEach((card) => {
      const newBehavior = new BehaviorSubject<ICardData>(card.data);
      this._cardBehaviorSubjects.push({
        cardId: card.cardId,
        behaviour: newBehavior,
      });
      card.updates = newBehavior.asObservable();
    });
    this._dashboardCards = cards;
  }

  private _dashboardCards: IDashboardCard[] = [];
  private _cardBehaviorSubjects: {
    cardId: string;
    behaviour: BehaviorSubject<any>;
  }[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event;
    this.orderCards();
  }

  get dashboardCards() {
    return this._dashboardCards;
  }

  constructor(
    private location: Location,
    private injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.orderCards();
  }

  orderCards() {
    this._dashboardCards.sort((a, b) => {
      const orderA = typeof a.order === 'function' ? a.order() : a.order;
      const orderB = typeof b.order === 'function' ? b.order() : b.order;
      return orderA - orderB;
    });
  }

  goBack() {
    this.location.back();
  }

  public update(cardId: string, data: ICardData): void;
  public update(cardId: string, internalName: string, data: ICardData): void;
  public update(...args: any): void {
    if (args.length === 2) {
      this._cardBehaviorSubjects
        .find((subject) => subject.cardId === args[0])!
        .behaviour.next(args[1]);
    } else if (args.length === 3) {
      this._cardBehaviorSubjects
        .find((subject) => subject.cardId === args[0])!
        .behaviour.next({ internalName: args[1], data: args[2] });
    }
  }

  getCols(card: IDashboardCard) {
    return typeof card.cols === 'function' ? card.cols() : card.cols;
  }

  getRows(card: IDashboardCard) {
    return typeof card.rows === 'function' ? card.rows() : card.rows;
  }
}
