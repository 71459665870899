<ng-container *hasRole="layout.roles">
  <bewa-column-selector
    [layout]="layout.layout"
    [table_item]="value"
    style="display: flex"
  ></bewa-column-selector>
</ng-container>

<!-- <p style="margin: 0;" *ngFor="let element of value">
  {{ element }}
</p> -->
