<div
  [@newsBarExpand]="newsBarOpen ? 'expanded' : 'collapsed'"
  class="news-bar-content"
>
  <div class="row news-bar-row">
    <mat-icon class="info-icon col-auto" *ngIf="infoIcon">
      {{ infoIcon }}
    </mat-icon>

    <div class="col info-text">
      <b>{{ infoTitle | translate }}:</b>
      {{ infoText | translate }}
    </div>
    <div class="col-auto">
      <button mat-raised-button (click)="buttonClick()">
        {{ buttonText | translate }}
        <mat-icon *ngIf="buttonIcon">
          {{ buttonIcon }}
        </mat-icon>
      </button>
    </div>

    <div class="other-notifications col-auto" *ngIf="otherNotifications > 0">
      {{
        "NOTIFICATIONS.NEWS_BAR.MORE_NOTIFICATIONS"
          | translate: { number: otherNotifications }
      }}
    </div>
  </div>
</div>
