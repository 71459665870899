import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPopupNotificationMessage } from '../../interfaces/notification.interface';

@Component({
  selector: 'bewa-notification-dialog',
  templateUrl: './notification.dialog.html',
  styleUrls: ['./notification.dialog.scss'],
})
export class NotificationDialogComponent {
  private _notificationMessage: string | string[] | IPopupNotificationMessage;
  confirmable: boolean;
  simple: boolean;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this._notificationMessage = data.notificationMessage;
    this.confirmable = data.confirmable;
  }

  onClose() {
    if (this.confirmable) {
      this.dialogRef.close(this.confirmable);
    } else {
      this.dialogRef.close();
    }
  }

  isArray(value: any): boolean {
    return value instanceof Array;
  }

  confirmButtonName(): string {
    return this.confirmable
      ? 'NOTIFICATIONS.BUTTON_CONFIRM'
      : 'NOTIFICATIONS.BUTTON_OK';
  }

  get notificationMessage() {
    return this._notificationMessage as string[];
  }

  getString(value: any): string {
    if (typeof value === 'string') {
      return value;
    }
    return undefined!;
  }

  getComplexMessage(
    value: string | string[] | IPopupNotificationMessage,
  ): IPopupNotificationMessage | undefined {
    if (typeof value !== 'string' && !(value instanceof Array)) {
      return value;
    }
    return undefined;
  }

  isComplexMessage() {
    return (
      this._notificationMessage &&
      typeof this._notificationMessage === 'object' &&
      !(this._notificationMessage instanceof Array)
    );
  }
}
