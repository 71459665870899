import { LoginActionsUnion, LoginActionTypes } from '../actions/login';
import { Identity } from '../models/identity';

export interface State {
  loggedIn: boolean;
  identity: Identity;
  returnUrl: string;
}

export const initialState: State = {
  loggedIn: false,
  identity: null!,
  returnUrl: null!,
};

export function reducer(
  state = initialState,
  action: LoginActionsUnion,
): State {
  switch (action.type) {
    case LoginActionTypes.UpdateLoginStatus: {
      return {
        ...state,
        loggedIn: action.payload,
      } as State;
    }
    case LoginActionTypes.UpdateIdentity: {
      return {
        ...state,
        identity: action.payload,
      } as State;
    }
    case LoginActionTypes.UpdateRedirectUrl: {
      return {
        ...state,
        returnUrl: action.payload,
      } as State;
    }
    default: {
      return state;
    }
  }
}

export const getLoggedIn = (state: State) => {
  return !!state?.loggedIn;
};

export const getIdentity = (state: State) => {
  return state?.identity;
};

export const getRedirectUrl = (state: State) => {
  return state?.returnUrl;
};
