import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ITableCard,
  ITableData,
} from '../../../interfaces/card-layout.interface';
import { Subscriptions } from '@/app/external-modules/utils/decorators/subscriptions/subscriptions.decorator';
import { Unsubscribe } from '@/app/external-modules/utils/decorators/unsubscribe/unsubscribe.decorator';

@Component({
  selector: 'bewa-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
})
@Subscriptions()
export class TableCardComponent implements OnInit {
  @Input()
  card: ITableCard;
  @Unsubscribe()
  private cardUpdateSubscription: Subscription;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.cardUpdateSubscription = this.card.updates!.subscribe(
      (data: ITableData) => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            (this.card.data as any)[key] = (data as any)[key];
            this.cdRef.detectChanges();
          }
        }
      },
    );
  }

  onTableAction($event: any) {
    $event;
    // Implement if needed
  }
}
