import {
  NavigationActionTypes,
  NavigationActionUnion,
} from '../actions/navigation.action';

export interface State {
  navigationData: any;
}

export const initialState: State = {
  navigationData: null,
};

export function reducer(
  state = initialState,
  action: NavigationActionUnion,
): State {
  switch (action.type) {
    case NavigationActionTypes.SetNavigationData: {
      return {
        ...state,
        navigationData: action.payload,
      };
    }
    case NavigationActionTypes.ResetNavigationData: {
      return {
        ...state,
        navigationData: null,
      };
    }

    default: {
      return state;
    }
  }
}
export const getNavigationData = (state: State) => {
  return state?.navigationData;
};
