import { BASE_PATH } from '@/openapi';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeContentCardComponent } from './components/home-content-card/home-content-card.component';
import { HomeComponent } from './components/home/home.component';
import { StatisticsService } from './components/home/statistics.service';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { TranslateLoadingBaseComponent } from './components/translate-loading-base/translate-loading-base.component';
import { BewatecDialogModule } from './external-modules/dialog/bewatec-dialog.module';
import { HeaderModule } from './external-modules/header/header.module';
import { HeaderModuleConfig } from './external-modules/header/header.module.config';
import { NotificationsModule } from './external-modules/notifications/notifications.module';
import { TourMatMenuModule } from './external-modules/tour-md-menu/md-menu.module';
import { UtilsModule } from './external-modules/utils/utils.module';
import { CustomerDistributorManagementService } from './modules/device-management/services/customer-distributor-management.service';
import { DeviceUpdateService } from './modules/device-update/services/device-update.service';
import { BewatecLibraryModule } from './modules/library/bewatec-library.module';
import { BewatecDashboardModule } from './modules/library/components/bewatec-dashboard/bewatec-dashboard.module';
import { ProfilesModule } from './modules/profiles/profiles.module';
import { reducers as RootReducers, RootState } from './reducer/root.reducer';
import { AuthGuardService } from './services/authGuard/authGuard.service';
import { CustomerManagementService } from './services/customers/customer-management.service';
import { FileSchemaService } from './services/file-schema/file-schema.service';
import { RequestInterceptor } from './services/httpinterceptor/request.interceptor';
import { LicenseService } from './services/license.service';
import { LoginService } from './services/login/login.service';
import { MenuService } from './services/menu/menu.service';
import { NavigationService } from './services/navigation/navigation.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { BewatecOAuthService } from './services/o-auth/bewatec-oauth.service';
import { ProfileService } from './services/profile/profile.service';
import { VersionCheckService } from './services/versionChecker/version-check.service';
import { WebSocketAPIService } from './services/websocket/WebSocketAPI.service';
import { Utils } from './utils';

export const ROOT_REDUCER_TOKEN: InjectionToken<ActionReducerMap<RootState>> =
  new InjectionToken<ActionReducerMap<RootState>>('Root Reducers');

export function getReducers(): ActionReducerMap<RootState> {
  const reducers: ActionReducerMap<RootState> = RootReducers;
  return reducers;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.langEndpoint, '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: document.location.hostname,
    // or 'your.domain.com'
    // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#FFF',
    },
    button: {
      background: '#303f9f',
    },
  },
  theme: 'classic',
  type: 'info',
  showLink: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainNavigationComponent,
    MenuItemComponent,
    HomeContentCardComponent,
    TranslateLoadingBaseComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BewatecLibraryModule,
    BrowserModule,
    TourMatMenuModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatGridListModule,
    NgxTrimDirectiveModule,
    StoreModule.forRoot(getReducers()),
    StoreDevtoolsModule.instrument({}),
    NotificationsModule.forRoot({ langEndpoint: environment.langEndpoint }),
    BewatecDialogModule.forRoot({ langEndpoint: environment.langEndpoint }),
    HeaderModule.forRoot(
      new HeaderModuleConfig({
        langEndpoint: environment.langEndpoint,
        showRoleView: true,
        enableNotifications: true,
        showHelp: true,
        useCustomHeading: false,
      }),
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    OAuthModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    BewatecDashboardModule,
    AngularSvgIconModule.forRoot(),
    ProfilesModule,
    UtilsModule.forRoot({ environment: 'productive' }),
  ],
  providers: [
    CustomerDistributorManagementService,
    StatisticsService,
    DeviceUpdateService,
    WebSocketAPIService,
    MenuService,
    Utils,
    LoginService,
    AuthGuardService,
    ProfileService,
    NotificationsService,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: BASE_PATH, useValue: environment.apiUrl },
    CustomerManagementService,
    {
      provide: OAuthService,
      useClass: BewatecOAuthService,
    },
    CookieService,
    VersionCheckService,
    FileSchemaService,
    LicenseService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
