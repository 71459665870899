/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'tourAnchorOpener',
  styles: [
    `
      :host {
        display: none;
      }
    `,
  ],
  template: `
    <span [matMenuTriggerFor]="menu" #trigger="matMenuTrigger"></span>
  `,
})
export class TourAnchorOpenerComponent {
  @Input() menu: MatMenu = new MatMenu(undefined!, undefined!, {
    xPosition: 'after',
    yPosition: 'below',
    overlapTrigger: true,
    backdropClass: '',
  });

  @ViewChild(MatMenuTrigger) public trigger: MatMenuTrigger;
}
