import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';
import { HeaderEvent } from '../models/header-event';
import { Identity } from '../models/identity';
import { ILanguage } from '../models/language';
import * as fromAuth from './auth';
import * as fromHeader from './header';
import * as fromHelp from './help';
import * as fromLanguage from './language';
import * as fromLogin from './login';
import * as fromSelection from './select';

export const featureName = 'headerModule';

export interface HeaderModuleState {
  login: fromLogin.State;
  header: fromHeader.State;
  language: fromLanguage.State;
  selection: fromSelection.State;
  auth: fromAuth.State;
  help: fromHelp.State;
}

export const reducers: ActionReducerMap<HeaderModuleState> = {
  login: fromLogin?.reducer as any,
  header: fromHeader?.reducer as any,
  language: fromLanguage?.reducer as any,
  selection: fromSelection?.reducer as any,
  auth: fromAuth?.reducer as any,
  help: fromHelp?.reducer as any,
};

export const getHeaderModuleState: MemoizedSelector<any, HeaderModuleState> =
  createFeatureSelector<HeaderModuleState>(featureName);

export const getLogin: MemoizedSelector<any, fromLogin.State> = createSelector(
  getHeaderModuleState,
  (state: HeaderModuleState) => state?.login,
);

export const getHeader: MemoizedSelector<any, fromHeader.State> =
  createSelector(
    getHeaderModuleState,
    (state: HeaderModuleState) => state?.header,
  );

export const getLanguage: MemoizedSelector<any, fromLanguage.State> =
  createSelector(
    getHeaderModuleState,
    (state: HeaderModuleState) => state?.language,
  );

export const getSelection: MemoizedSelector<any, fromSelection.State> =
  createSelector(
    getHeaderModuleState,
    (state: HeaderModuleState) => state?.selection,
  );

export const getAuth: MemoizedSelector<any, fromAuth.State> = createSelector(
  getHeaderModuleState,
  (state: HeaderModuleState) => state?.auth,
);

export const getHelp: MemoizedSelector<any, fromHelp.State> = createSelector(
  getHeaderModuleState,
  (state: HeaderModuleState) => state?.help,
);

export const getLoggedInSelect: MemoizedSelector<any, boolean> = createSelector(
  getLogin,
  fromLogin.getLoggedIn,
);
export const getIdentitySelect: MemoizedSelector<any, Identity> =
  createSelector(getLogin, fromLogin.getIdentity);
export const getRedirectUrlSelect: MemoizedSelector<any, string> =
  createSelector(getLogin, fromLogin.getRedirectUrl);

export const getHeaderEventSelect: MemoizedSelector<any, HeaderEvent> =
  createSelector(getHeader, (state: fromHeader.State) => state?.event || {});

export const getLanguagesSelect: MemoizedSelector<any, ILanguage[]> =
  createSelector(getLanguage, fromLanguage.getLanguages);
export const getActiveLanguageSelect: MemoizedSelector<any, ILanguage> =
  createSelector(getLanguage, fromLanguage.getActiveLanguage);

export const getSelectedCustomerSelect: MemoizedSelector<any, any> =
  createSelector(getSelection, fromSelection.getSelectedCustomer);

export const getUserRolesSelect: MemoizedSelector<any, string[]> =
  createSelector(getAuth, fromAuth.getUserRoles);

export const getManualRequestSelect: MemoizedSelector<any, any> =
  createSelector(getHelp, fromHelp.getManualRequest);
export const getOpenHelpCenterRequest: MemoizedSelector<any, any> =
  createSelector(getHelp, fromHelp.getOpenHelpCenterRequest);
