<mat-card class="dashboard-card scrollable">
  <!--<mat-card-header>
    <mat-card-title class="card-title">
      {{card.cardTitle | translate}}
    </mat-card-title>
  </mat-card-header>-->
  <mat-card-content class="dashboard-card-content scrollable">
    <h4 class="info-header">
      <b>{{ card.data.infoTitle | translate }}</b>
    </h4>

    <ng-container
      *ngIf="isMultiline; then multiLine; else singleLine"
    ></ng-container>

    <ng-template #singleLine>
      <p>
        {{ card.data.infoText.toString() | translate }}
        <mat-icon>
          {{ card.data.infoIcon }}
        </mat-icon>
      </p>
    </ng-template>
    <ng-template #multiLine>
      <div class="multi-line-wrapper">
        <p
          class="info-text line"
          [ngStyle]="{ 'flex-basis': flexBasis }"
          *ngFor="let text of getList(card.data.infoText); let i = index"
        >
          {{ text | translate }}
          <mat-icon>
            {{ card.data.infoIcon[i] }}
          </mat-icon>
        </p>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
