<ng-container *hasRole="layout.roles">
  <ng-container *ngIf="value">
    <ng-container *ngIf="value.link; then link; else other"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #link>
  <ng-container
    *ngIf="layout.translate; then translateLink; else noTranslateLink"
  >
  </ng-container>
  <ng-template #translateLink>
    <a [routerLink]="value.link" (click)="$event.stopPropagation()">{{
      value.name | translate
    }}</a>
  </ng-template>
  <ng-template #noTranslateLink>
    <a [routerLink]="value.link" (click)="$event.stopPropagation()">{{
      value.name
    }}</a>
  </ng-template>
</ng-template>

<ng-template #other>
  <ng-container *ngIf="value.callback; then callback; else noLink">
  </ng-container>
</ng-template>

<ng-template #callback>
  <ng-container
    *ngIf="layout.translate; then translateLink; else noTranslateLink"
  >
  </ng-container>
  <ng-template #translateLink>
    <a (click)="value.callback(value); $event.stopPropagation()" class="link">{{
      value.name | translate
    }}</a>
  </ng-template>
  <ng-template #noTranslateLink>
    <a (click)="value.callback(value); $event.stopPropagation()" class="link">{{
      value.name
    }}</a>
  </ng-template>
</ng-template>

<ng-template #noLink>
  <ng-container
    *ngIf="layout.translate; then translateNoLink; else noTranslateNoLink"
  >
  </ng-container>
  <ng-template #translateNoLink>
    <p>{{ value | translate }}</p>
  </ng-template>
  <ng-template #noTranslateNoLink>
    <p>{{ value }}</p>
  </ng-template>
</ng-template>
