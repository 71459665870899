/* eslint-disable @angular-eslint/component-selector */
import { Component, ViewChild } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as header from '../../actions/header';
import { EventType, HeaderEvent } from '../../models/header-event';
import * as fromModule from '../../reducers/module';

@Component({
  selector: 'selection-component',
  templateUrl: 'selection.component.html',
  styleUrls: ['selection.component.scss'],
})
export class SelectionComponent {
  selectionObjects$: Observable<object>;
  selectedCustomer: any;
  deselectObjects$: Observable<HeaderEvent>;

  objectSelected = false;
  @ViewChild(MatAutocomplete, { static: true }) autoGroup: MatAutocomplete;

  constructor(private store: Store<any>) {
    this.selectionObjects$ = this.store.select(
      fromModule.getSelectedCustomerSelect,
    );
    if (this.selectionObjects$ !== undefined) {
      this.selectionObjects$.subscribe((result) => {
        if (result) {
          this.selectedCustomer = result;
          this.objectSelected = true;
        }
      });
    }
    this.deselectObjects$ = this.store.select(fromModule.getHeaderEventSelect);
    if (this.deselectObjects$ !== undefined) {
      this.deselectObjects$.subscribe((headerEvent) => {
        if (headerEvent) {
          if (headerEvent.eventType === EventType.DeselectCustomer) {
            this.selectedCustomer = undefined!;
            this.objectSelected = false;
          }
        }
      });
    }
  }

  onRemoveSelection() {
    this.store.dispatch(
      new header.EmitHeaderEvent({ eventType: EventType.DeselectCustomer }),
    );
    this.objectSelected = false;
  }

  customerSelect() {
    this.store.dispatch(
      new header.EmitHeaderEvent({ eventType: EventType.SelectCustomer }),
    );
  }
}
