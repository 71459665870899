/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { CanColor, mixinColor, ThemePalette } from '@angular/material/core';
import { NewsBarConfig } from '../news-bar.config';
import { NewsBarRef } from '../news-bar.ref';

class BewaNavBarBase {
  constructor(public _elementRef: ElementRef) {}
}

const _BewaNavBarMixinBase: typeof BewaNavBarBase = mixinColor(BewaNavBarBase);

@Component({
  selector: 'bewa-news-bar',
  styleUrls: ['./news-bar.component.scss'],
  templateUrl: './news-bar.component.html',
  animations: [
    trigger('newsBarExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none', opacity: 0 }),
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('300ms cubic-bezier(0.5, 0.0, 0.1, 1)'),
      ),
    ]),
  ],
  inputs: ['color'],
})
export class NewsBarComponent
  extends _BewaNavBarMixinBase
  implements AfterContentInit, CanColor
{
  newsBarOpen = false;
  @Input()
  otherNotifications: number;
  private _sticky: boolean;
  private _position: number;

  constructor(
    elementRef: ElementRef,
    private config: NewsBarConfig,
    private newsBarRef: NewsBarRef,
  ) {
    super(elementRef);
  }
  color: ThemePalette;
  defaultColor: ThemePalette;

  buttonClick() {
    this.newsBarOpen = true;
    this.newsBarRef.close(true);
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.newsBarOpen = true;
    }, 1000);
  }

  get infoText() {
    return this.config.data.infoText;
  }
  get buttonText() {
    return this.config.data.buttonText;
  }
  get buttonIcon() {
    return this.config.data.buttonIcon;
  }

  get infoIcon() {
    return this.config.data.infoIcon;
  }

  get infoTitle() {
    return this.config.data.infoTitle;
  }

  get sticky() {
    return this._sticky;
  }
}
