export class HeaderModuleConfig {
  langEndpoint: string;
  useAuth = true;
  useCustomHeading = false;
  showBreadcrumbsAlways = false;
  showBottomRow = true;
  showHelp = false;
  enableNotifications = false;
  showRoleView = false;

  constructor(config: {
    langEndpoint: string;
    useAuth?: boolean;
    useCustomHeading?: boolean;
    showBreadcrumbsAlways?: boolean;
    showHelp?: boolean;
    showBottomRow?: boolean;
    enableNotifications?: boolean;
    showRoleView?: boolean;
  }) {
    this.langEndpoint = config.langEndpoint;
    if (config.useAuth) {
      this.useAuth = config.useAuth;
    }
    if (config.useCustomHeading) {
      this.useCustomHeading = config.useCustomHeading;
    }
    if (config.showBreadcrumbsAlways) {
      this.showBreadcrumbsAlways = config.showBreadcrumbsAlways;
    }
    if (config.showBottomRow) {
      this.showBottomRow = config.showBottomRow;
    }
    if (config.enableNotifications) {
      this.enableNotifications = config.enableNotifications;
    }
    if (config.showRoleView) {
      this.showRoleView = config.showRoleView;
    }
    if (config.showHelp) {
      this.showHelp = config.showHelp;
    }
  }
}
