import { Component, HostBinding } from '@angular/core';
import { ColumnTypeAbstractComponent } from '../../../abstract/column-type-abstract/column-type.abstract.component';
import { IObjectLayout } from './../../../../table/interfaces/layout.interface';

@Component({
  selector: 'bewa-object-column-type',
  templateUrl: './object-column-type.component.html',
  styleUrls: ['./object-column-type.component.scss'],
})
export class ObjectColumnTypeComponent extends ColumnTypeAbstractComponent<IObjectLayout> {
  @HostBinding('id') get id() {
    return this.layout.id;
  }
  constructor() {
    super();
  }
}
