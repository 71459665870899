/* eslint-disable @angular-eslint/component-selector */
import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { TourHotkeyListenerComponent } from '../tour-core/tour-hotkey-listener.component';
import { IStepOption } from '../tour-core/tour.service';
import { NgxmTourService } from './ngx-md-menu-tour.service';
import { TourStepTemplateService } from './tour-step-template.service';

@Component({
  selector: 'tour-step-template',
  styles: [
    `
      ::ng-deep .tour-step .mat-menu-content {
        padding: 0 !important;
      }

      ::ng-deep .mat-menu-panel {
        max-width: 400px !important;
      }
    `,
  ],
  template: `
    <mat-menu [overlapTrigger]="false" class="tour-step">
      <ng-container
        *ngTemplateOutlet="
          stepTemplate || stepTemplateContent || defaultTemplate;
          context: { step: step }
        "
      ></ng-container>
    </mat-menu>
    <ng-template #defaultTemplate let-step="step">
      <mat-card (click)="$event.stopPropagation()">
        <mat-card-title>
          {{ step?.title | translate }}
        </mat-card-title>
        <mat-card-content>
          {{ step?.content | translate }}
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-icon-button
            [disabled]="!tourService.hasPrev(step)"
            (click)="tourService.prev()"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="
              !tourService.hasNext(step) ||
              tourService.nextIsLastAndHidden(step)
            "
            (click)="tourService.next()"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
          <button mat-button (click)="tourService.end()">
            {{ step?.endBtnTitle | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-template>
  `,
})
export class TourStepTemplateComponent
  extends TourHotkeyListenerComponent
  implements AfterViewInit
{
  @ViewChild(MatMenu) public tourStep: MatMenu;

  @Input()
  public stepTemplate: TemplateRef<{ step: IStepOption }>;

  @ContentChild(TemplateRef)
  public stepTemplateContent: TemplateRef<{ step: IStepOption }>;

  public step: IStepOption = {};

  constructor(
    private tourStepTemplateService: TourStepTemplateService,
    public override tourService: NgxmTourService,
  ) {
    super(tourService);
  }

  public ngAfterViewInit(): void {
    this.tourStepTemplateService.templateComponent = this;
  }
}
