import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ICustomer } from '../../../interfaces/customer.interface';
import { AbstractService } from '../../../services/abstract/abstract.service';

@Injectable()
export class CustomerDistributorManagementService extends AbstractService {
  // *************** DISTRIBUTORS SECTION *************************

  getDistributors(): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.customer_data_service.root +
          environment.customer_data_service.distributors,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  getCustomersOfDistributor(distributorId: string): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.customer_data_service.root +
          environment.customer_data_service.distributors +
          '/' +
          distributorId +
          environment.customer_data_service.customers,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  getDistributorById(distributorId: number): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.customer_data_service.root +
          environment.customer_data_service.distributors +
          '/' +
          distributorId,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  // **************** CUSTOMERS SECTION ***************************

  getCustomers(): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.customer_data_service.root +
          environment.customer_data_service.customers,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  getCustomerById(customerId: number): Observable<any> {
    const tokenHeader = this.oauthService.authorizationHeader();
    const options: any = {};
    options.headers = new HttpHeaders().set('Authorization', tokenHeader);

    return this.httpClient
      .get(
        environment.customer_data_service.root +
          environment.customer_data_service.customers +
          '/' +
          customerId,
        options,
      )
      .pipe(catchError(this.handleError));
  }

  saveCustomer(newCustomer: ICustomer) {
    newCustomer;
    // TODO: implement me
  }

  deleteCustomer(id: number): Observable<any> {
    id;
    // TODO: implement me
    return of({});
  }
}
