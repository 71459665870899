import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SignalInputDirective } from 'ng-signal-forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { environment } from '../../../environments/environment';
import { BewatecDialogModule } from '../../external-modules/dialog/bewatec-dialog.module';
import { HelpService } from '../../services/help/help.service';
import { ButtonComponent } from './components/button/button.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { ChooseCustomerDialogComponent } from './components/dialogs/choose-customer/choose-customer-dialog.component';
import { CustomerService } from './components/dialogs/choose-customer/customer.service';
import { DetailDialogComponent } from './components/dialogs/detail-dialog/detail-dialog.component';
import { FileImportDialogComponent } from './components/dialogs/file-import/file-import-dialog.component';
import { FileUploadComponent } from './components/fileupload/file-upload.component';
import { HelpComponent } from './components/help-component/help.component';
import { HistoryDetailComponent } from './components/history-detail/history-detail.component';
import { LoadingOverlay } from './components/loading-overlay/loading-overlay.component';
import { LoadingComponent } from './components/loading-overlay/loading/loading.component';
import { SaveWarningDialogComponent } from './components/save-warning-dialog/save-warning-dialog.component';
import { TableContainerComponent } from './components/table-container/table-container.component';
import { BewatecTableV2Module } from './components/table-v2/table-v2.module';
import { MatPaginatorIntlService } from './components/table/MatPaginatorIntlService.service';
import { GetAttributePipe } from './components/table/utils/getattribute.pipe.component';
import { DevIdDirective } from './directives/devId.directive';
import { HasRoleDirective } from './directives/hasRole.directive';
import { BewaSortPipe } from './directives/sort.directive';
import { ShareModule } from './share.module';

export function createMultiTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: environment.langEndpoint + 'bewatec-library/', suffix: '.json' },
    { prefix: environment.langEndpoint, suffix: '.json' },
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    BewatecDialogModule,
    MatTooltipModule,
    MatInputModule,
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatExpansionModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    FormsModule,
    SignalInputDirective,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatSortModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    RouterModule,
    AngularSvgIconModule,
    NgxTrimDirectiveModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createMultiTranslateLoader,
        deps: [HttpBackend],
      },
      isolate: true,
    }),
    MatGridListModule,
    LayoutModule,
    BewatecTableV2Module,
    ShareModule,
    OverlayModule,
  ],
  declarations: [
    FileImportDialogComponent,
    ChooseCustomerDialogComponent,
    DetailDialogComponent,
    FileUploadComponent,
    HistoryDetailComponent,
    ContentCardComponent,
    SaveWarningDialogComponent,
    ButtonComponent,
    HelpComponent,
    TableContainerComponent,
    LoadingComponent,
  ],
  exports: [
    GetAttributePipe,
    FileUploadComponent,
    BewatecTableV2Module,
    ContentCardComponent,
    HistoryDetailComponent,
    ButtonComponent,
    HasRoleDirective,
    DevIdDirective,
    HelpComponent,
    TableContainerComponent,
    LoadingComponent,
    BewaSortPipe,
    SignalInputDirective,
  ],
  providers: [
    CustomerService,
    {
      provide: MatPaginatorIntl,
      useFactory: (translate: TranslateService) => {
        const service = new MatPaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService],
    },
    HelpService,
    LoadingOverlay,
  ],
})
export class BewatecLibraryModule {}
