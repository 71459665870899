import {
  BackgroundActionTypes,
  BackgroundActionUnion,
} from '../actions/background.action';

export interface State {
  backgroundUrl?: string;
}

export const initialState: State = {
  backgroundUrl: undefined,
};

export function reducer(
  state = initialState,
  action: BackgroundActionUnion,
): State {
  switch (action.type) {
    case BackgroundActionTypes.UpdateBackground: {
      return {
        ...state,
        backgroundUrl: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
export const getBackground = (state: State) => {
  return state.backgroundUrl;
};
