import { Subscription } from 'rxjs';

export function Subscriptions() {
  return (constructor: any) => {
    constructor.prototype.subscriptions = [];
    if (constructor.prototype.ngOnDestroy) {
      constructor.prototype.oldNgOnDestroy = constructor.prototype.ngOnDestroy;
    }
    // tslint:disable-next-line: only-arrow-functions
    constructor.prototype.ngOnDestroy = function () {
      if (constructor.prototype.oldNgOnDestroy) {
        constructor.prototype.oldNgOnDestroy.apply(this);
        // constructor.prototype.oldNgOnDestroy();
      }
      if (constructor.prototype.subscriptions) {
        for (const subscription of constructor.prototype.subscriptions) {
          if (subscription instanceof Subscription) {
            if (!subscription.closed) {
              subscription.unsubscribe();
            }
          }
        }
        constructor.prototype.subscriptions = (
          constructor.prototype.subscriptions as Array<Subscription>
        ).filter((subscription) => !subscription.closed);
      }
    };
  };
}
